import { toast } from "react-toastify";
import {
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateProfile,
} from "firebase/auth";
import {
  getDoc,
  doc,
  deleteDoc,
  collection,
  updateDoc,
  setDoc,
  Timestamp,
  getDocs,
  query,
  where,
  addDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, db, functions_base_url, storage } from "./init";
import axios from "axios";

// ############################## ACCOUNT HANDLING ##############################

export function logout() {
  if (auth.currentUser) {
    signOut(auth);
    window.location.href = "/";
  } else {
    // Return a resolved promise
    return Promise.resolve();
  }
}

export const readUser = async (id) => {
  const userRef = doc(db, "users", id);
  const userSnap = await getDoc(userRef);

  return userSnap.data();
};

export const loginUser = async (email, password) => {
  try {
    let authUser = await signInWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        return userCredential.user;
      }
    );

    // Check if user has verified his email
    if (!auth.currentUser.emailVerified) {
      throw new Error("Bitte verifizieren Sie Ihre E-Mail-Adresse");
    }

    const fireStoreuser = await readUser(authUser.uid).then((user) => {
      return user;
    });

    const user = {
      ...authUser,
      ...fireStoreuser,
    };

    toast.success("Erfolgreich eingeloggt!");
    return {
      success: true,
      user: user,
    };
  } catch (error) {
    var message = error.message;
    if (error.code === "auth/user-not-found") {
      message = "Kein Benutzer mit dieser E-Mail-Adresse gefunden";
    } else if (error.code === "auth/wrong-password") {
      message = "Falsches Passwort";
    }
    toast.error(message);
    return {
      success: false,
      user: null,
    };
  }
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    toast.success("E-Mail zum Zurücksetzen des Passworts gesendet");
    return true;
  } catch (error) {
    toast.error(error.message);
    return false;
  }
};

export const deleteKey = async (key) => {
  try {
    const apiKeyRef = doc(db, "api_keys", key);
    await deleteDoc(apiKeyRef);
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateKey = async (key, updatedKey) => {
  try {
    const apiKeyRef = doc(db, "api_keys", key);
    await updateDoc(apiKeyRef, updatedKey);
    toast.success("Key aktualisiert");
  } catch (error) {
    toast.error(error.message);
  }
};

export const generateRegisterLink = async (type) => {
  let keyData = {
    type: type,
  };

  if (type === "Player") {
    keyData = {
      ...keyData,
      usage: 0,
    };
  }

  const key = await addDoc(collection(db, "api_keys"), keyData).then(
    (docRef) => {
      return docRef.id;
    }
  );

  return key;
};

// Register a new user
export const registerUser = async (
  user,
  setLoading = () => { },
  setRegistered = () => { },
  multi = false,
  key,
  trainingParam
) => {
  try {
    console.log("User: ", user);
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      user.email,
      user.password
    );

    // Remove the auth fields from the user object
    delete user.password;
    delete user.repeatPassword;
    delete user.email;

    // Upload the profile picture if user provided one
    if (user.profile_picture === undefined && !multi) {
      user.profile_picture =
        "https://firebasestorage.googleapis.com/v0/b/simulearn-801f6.appspot.com/o/user_photos%2Fdummy-profile-pic.png?alt=media&token=bec28754-ae13-4190-acfc-cb292065db91&_gl=1*18r1i5v*_ga*MTIxMjk3NDA1Ny4xNjg5Nzg0Mzc1*_ga_CW55HF8NVT*MTY5NzcxNDMyMy4xMjcuMS4xNjk3NzIyMDYxLjcuMC4w";
      await updateProfile(userCredential.user, {
        displayName: user.first_name + " " + user.last_name,
        photoURL: user.profile_picture,
      });
    } else if (user.profile_picture !== undefined && !multi) {
      const profile_picture_ref = ref(
        storage,
        `user_photos/${userCredential.user.uid}`
      );
      await uploadBytes(profile_picture_ref, user.profile_picture);
      const profile_picture_url = await getDownloadURL(profile_picture_ref);
      user.profile_picture = profile_picture_url;
      await updateProfile(userCredential.user, {
        displayName: user.first_name + " " + user.last_name,
        photoURL: profile_picture_url,
      });
    }

    // Add the arrays for simulations and scenarios
    user.simulations = [];
    user.scenarios = [
      doc(db, "scenarios", "cobCcxntFBxwItOW3KoG"),
      doc(db, "scenarios", "s1ooHqL1yd0reGQblE8v"),
    ]; // Add the default scenario

    // Add the user to the database
    console.log("User to add to database: ", user);
    const userRef = doc(db, "users", userCredential.user.uid);
    await setDoc(userRef, user);

    // Send verification email
    await sendEmailVerification(auth.currentUser);

    // Count the usage of the key up
    if (key !== undefined && user.type === "Player") {
      const keyRef = doc(db, "api_keys", key);
      const keySnap = await getDoc(keyRef);
      const keyData = keySnap.data();
      await updateDoc(keyRef, {
        usage: keyData.usage + 1,
      });
    } else if (key !== undefined && user.type === "Trainer") {
      deleteKey(key);
    }

    // If the user is a player, register him for the training
    if (user.type === "Player" && trainingParam !== undefined) {
      await handleSignIn(trainingParam, userRef);
    }

    setLoading(false);
    setRegistered(true);

    toast.success(
      "Erfolgreich registriert! Bitte bestätigen Sie Ihre E-Mail-Adresse"
    );
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateUser = async (id, updated_user) => {
  try {
    // Only overwrite the fields that are provided, keep the rest
    const userRef = doc(db, "users", id);
    await updateDoc(userRef, updated_user);
    toast.success("Profil aktualisiert");
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteUser = async (id) => {
  try {
    const data = {
      id: id,
    };
    let url = `${functions_base_url}/deleteUserFromFirestore`;
    let response = await axios.post(url, data);
    console.log(response);

    url = `${functions_base_url}/deleteUserFromAuth`;
    response = await axios.post(url, data);
    console.log(response);

    await logout();
    toast.success("Benutzer gelöscht");

    window.location.href = "/";
  } catch (error) {
    toast.error(error.message);
  }
};

export const readOrganization = async (id) => {
  try {
    const organizationRef = doc(db, "organizations", id);
    const organizationData = await getDoc(organizationRef)


    return {
      _id: organizationData.id,
      ...organizationData.data(),
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const readOrganizations = async () => {
  try {
    // Return list of organizations
    const organizationsRef = collection(db, "organizations");

    const organizationsSnap = await getDocs(organizationsRef);
    const organizationsData = organizationsSnap.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
    return organizationsData;
  } catch (error) {
    toast.error(error.message);
  }
};

// ############################## SIMULATIONS ##############################

export const readSimulationByKey = async (key) => {
  try {
    // Filter for public simulations
    const simulationsCollection = collection(db, "simulations");
    const simulationQuery = query(
      simulationsCollection,
      where("register_key", "==", key)
    );

    const simulationSnap = await getDocs(simulationQuery);
    const simulationData = simulationSnap.docs.map((doc) => {
      return {
        _id: doc.id,
        ...doc.data(),
      };
    });

    return simulationData[0];
  } catch (error) {
    toast.error(error.message);
  }
};

export const readSimulationById = async (id) => {
  try {
    const simulationRef = doc(db, "simulations", id);
    const simulationSnap = await getDoc(simulationRef);
    const simulationData = simulationSnap.data();

    return {
      _id: simulationSnap.id,
      ...simulationData,
    };
  } catch (error) {
    toast.error(error.message);
  }
};

export const startSimulation = async (simulation) => {
  try {
    const simulationRef = doc(db, "simulations", simulation._id);

    console.log("Simulation to start: ", simulation);

    const updatedSimulation = {
      ...simulation,
      end_at: Date.now() + simulation.duration * 60 * 1000,
      started: true,
    };

    updateDoc(simulationRef, updatedSimulation);

    toast.success("Training gestartet");
  } catch (error) {
    toast.error(error.message);
  }
};

export const readSimulationsByTrainerId = async (id, limit = null) => {
  try {
    const trainerRef = doc(db, "users", id);
    const trainerSnap = await getDoc(trainerRef);
    const trainerData = trainerSnap.data();

    // Get the array of simulation references
    const simulationsRefs = trainerData.simulations;

    // Fetch the simulation data for each reference
    const simulationsData = await Promise.all(
      simulationsRefs.map(async (simRef) => {
        try {
          const simulationSnap = await getDoc(simRef);
          if (!simulationSnap.exists()) {
            return null;
          }
          return simulationSnap.data();
        } catch (error) {
          return null;
        }
      })
    );

    if (limit === null) {
      return simulationsData;
    }
    // Limit the number of simulations
    const sortedSimulationsData = simulationsData
      .filter((simulation) => simulation !== null)
      .sort((a, b) => b.created_at - a.created_at)
      .slice(0, limit);

    return sortedSimulationsData;
  } catch (error) {
    toast.error(error.message);
  }
};

export const readPublicSimulations = async () => {
  try {
    // Filter for public simulations
    const simulationsCollection = collection(db, "simulations");
    const publicSimulationsQuery = query(
      simulationsCollection,
      where("public_open", "==", true)
    );

    const publicSimulationsSnap = await getDocs(publicSimulationsQuery);
    const publicSimulationsData = publicSimulationsSnap.docs.map((doc) => {
      return {
        _id: doc.id,
        ...doc.data(),
      };
    });

    // Add the trainer name and profile picture to each simulation
    const publicSimulationsDataWithTrainer = await Promise.all(
      publicSimulationsData.map(async (simulation) => {
        try {
          const trainerRef = simulation.trainer;
          const trainerSnap = await getDoc(trainerRef);
          const trainerData = trainerSnap.data();
          return {
            ...simulation,
            trainer_name:
              trainerData.first_name +
              " " +
              trainerData.last_name +
              " (" +
              trainerData.organization +
              ")",
            trainer_profile_picture: trainerData.profile_picture,
          };
        } catch (error) {
          console.log(
            `Failed to get doc for ${simulation.trainer.id}: ${error}`
          );
          return null;
        }
      })
    );

    // Fetch the scenario photo_url for each simulation
    const publicSimulationsDataWithScenario = await Promise.all(
      publicSimulationsDataWithTrainer.map(async (simulation) => {
        try {
          // simulation.scenario is only a string id
          const scenarioRef = doc(db, "scenarios", simulation.scenario);
          const scenarioSnap = await getDoc(scenarioRef);
          const scenarioData = scenarioSnap.data();
          return {
            ...simulation,
            scenario_photo_url: scenarioData.photo_url,
          };

          // Return id and data
        } catch (error) {
          console.log(
            `Failed to get doc for ${simulation.scenario.id}: ${error}`
          );
          return null;
        }
      })
    );

    return publicSimulationsDataWithScenario;
  } catch (error) {
    toast.error(error.message);
  }
};

export const readSimulationsByPlayerRef = (playerRef, observer) => {
  // Filter for simulations that have the player's uid in the registered_players array
  const simulationsCollection = collection(db, "simulations");
  const playerSimulationsQuery = query(
    simulationsCollection,
    where("registered_players", "array-contains", playerRef)
  );

  const unsubscribe = onSnapshot(playerSimulationsQuery, observer);

  return unsubscribe;
};

export const updateSimulation = async (id, updatedSimulation, action = "") => {
  const simulationRef = doc(db, "simulations", id);

  await updateDoc(simulationRef, updatedSimulation);
  if (action === "") {
    toast.success("Training aktualisiert");
  }
};

export const createSimulation = async (trainingData, trainer_uid) => {
  console.log("Training data: ", trainingData);
  console.log("Trainer uid: ", trainer_uid);

  const trainingRef = collection(db, "simulations");
  const trainerRef = doc(db, "users", trainer_uid);

  trainingData.created_at = Timestamp.fromDate(new Date());
  trainingData.trainer = trainerRef;

  // This will return a document reference with the id that Firestore generated
  const docRef = await addDoc(trainingRef, trainingData);

  await updateDoc(trainerRef, {
    simulations: arrayUnion(docRef),
  });

  return { ...trainingData, _id: docRef.id }; // return the training data with id
};

export const deleteSimulation = async (
  simulationId,
  trainer_uid,
  user,
  setUser
) => {
  try {
    const simulationRef = doc(db, "simulations", simulationId);

    // Remove the simulation from the trainer
    const trainerRef = doc(db, "users", trainer_uid);
    const trainerSnap = await getDoc(trainerRef);
    const trainerData = trainerSnap.data();

    const updatedSimulations = trainerData.simulations.filter(
      (simRef) =>
        simRef._key.path.segments[simRef._key.path.segments.length - 1] !==
        simulationId
    );

    await updateDoc(trainerRef, {
      simulations: updatedSimulations,
    });

    setUser({
      ...user,
      simulations: updatedSimulations,
    });

    // Delete the teams referenced in the "teams" array
    const simulationSnap = await getDoc(simulationRef);

    const simulationData = simulationSnap.data();
    if (simulationData.teams !== undefined) {
      for (const teamRef of simulationData.teams) {
        console.log("Deleting team: ", teamRef.id);
        await deleteTeam(teamRef, simulationId);
      }
    }

    // Delete the simulation
    await deleteDoc(simulationRef);
    toast.success("Training gelöscht");
  } catch (error) {
    toast.error(error.message);
  }
};

// ############################## SCENARIOS ##############################

export const createScenario = async (scenarioData) => {
  try {
    // Create scenario
    const scenarioRef = await addDoc(collection(db, "scenarios"), {
      title: scenarioData.title,
      description: scenarioData.description,
      views: [], // Initialize views array
    });

    // Temporary array to store view refs
    const viewRefs = [];

    // Create views and store their references in the temporary array
    for (const viewData of scenarioData.views) {
      const viewRef = await addDoc(collection(db, "views"), {
        title: viewData.title,
        description: viewData.description,
        photo_url: viewData.photo_url,
        items: [], // Initialize items array
        switches: [], // Initialize switches array
      });

      // Add view reference to scenario
      await updateDoc(scenarioRef, {
        views: arrayUnion(viewRef),
      });

      // Store view reference
      viewRefs.push(viewRef);
    }

    // Create switches and items
    for (let i = 0; i < scenarioData.views.length; i++) {
      const viewData = scenarioData.views[i];
      const viewRef = viewRefs[i];

      // Create items for this view
      for (const itemData of viewData.items) {
        const itemRef = await addDoc(collection(db, "items"), {
          title: itemData.title,
          solution: itemData.solution,
          photo_url: itemData.photo_url,
          position: itemData.position,
          rotation: itemData.rotation,
          isDanger: itemData.isDanger,
        });

        // Add item reference to view
        await updateDoc(viewRef, {
          items: arrayUnion(itemRef),
        });
      }

      // Create switches for this view
      for (const switchData of viewData.switches) {
        const switchRef = await addDoc(collection(db, "switches"), {
          linked_view: doc(
            db,
            "views",
            viewRefs[switchData.linked_view - 1].id
          ),
          position: switchData.position,
          rotation: switchData.rotation,
        });

        // Add switch reference to view
        await updateDoc(viewRef, {
          switches: arrayUnion(switchRef),
        });
      }
    }
  } catch (error) {
    console.error(error.message);
  }
};

export const readScenario = async (id) => {
  try {
    if (id === undefined || id === null || typeof id !== "string") {
      return null;
    }
    const scenarioRef = doc(db, "scenarios", id);
    const scenarioSnap = await getDoc(scenarioRef);
    const scenarioData = scenarioSnap.data();

    // Get the array of view references
    const viewsRefs = scenarioData.views;

    // Fetch the view data for each reference
    const viewsData = await Promise.all(
      viewsRefs.map(async (viewRef) => {
        try {
          const viewSnap = await getDoc(viewRef);
          if (!viewSnap.exists()) {
            return null;
          }
          // Return id and data
          return {
            _id: viewSnap.id,
            ...viewSnap.data(),
          };
        } catch (error) {
          return null;
        }
      })
    );

    // Add the view data to the scenario data
    scenarioData.views = viewsData;

    // Fetch the item data for each view
    const scenarioDataWithItems = await Promise.all(
      scenarioData.views.map(async (view) => {
        if (view.items === undefined || view.items.length === 0) {
          return view;
        }
        // Get the array of item references
        const itemsRefs = view.items;

        if (itemsRefs === undefined || itemsRefs.length === 0) {
          return view;
        }

        // Fetch the item data for each reference
        const itemsData = await Promise.all(
          itemsRefs.map(async (itemRef) => {
            try {
              const itemSnap = await getDoc(itemRef.path);
              if (!itemSnap.exists()) {
                return null;
              }
              return {
                _id: itemSnap.id,
                ...itemSnap.data(),
                position: itemRef.position,
                rotation: itemRef.rotation,
                scale: itemRef.scale,
              };
            } catch (error) {
              return null;
            }
          })
        );

        // Add the item data to the view data
        view.items = itemsData;

        return view;
      })
    );

    // Fetch the switch data for each view
    const scenarioDataWithSwitches = await Promise.all(
      scenarioDataWithItems.map(async (view) => {
        // Get the array of switch references
        const switchesRefs = view.switches;

        if (switchesRefs === undefined || switchesRefs.length === 0) {
          return view;
        }

        // Fetch the switch data for each reference
        const switchesData = await Promise.all(
          switchesRefs.map(async (switchRef) => {
            try {
              const switchSnap = await getDoc(switchRef);
              if (!switchSnap.exists()) {
                return null;
              }
              // Return id and data
              return {
                _id: switchSnap.id,
                ...switchSnap.data(),
              };
            } catch (error) {
              return null;
            }
          })
        );

        // Add the switch data to the view data
        view.switches = switchesData;

        return view;
      })
    );

    // Add the view data to the scenario data
    scenarioData.views = scenarioDataWithSwitches;

    return {
      _id: scenarioSnap.id,
      ...scenarioData,
    };
  } catch (error) {
    toast.error(error.message);
  }
};

export const readScenarios = async (scenariosRefs) => {
  try {
    const scenarios = await Promise.all(
      scenariosRefs.map(async (ref) => {
        const s = await readScenario(ref.id);
        return s;
      })
    );

    return scenarios;
  } catch (error) {
    console.error("Error reading scenarios: ", error);

    throw error;
  }
};

// ############################## TEAMS ##############################

export const readTeam = async (teamRef) => {
  try {
    const teamSnap = await getDoc(teamRef);
    const teamData = teamSnap.data();

    // Get the array of user references
    const usersRefs = teamData.players;

    // Fetch the user data for each reference
    const usersData = await Promise.all(
      usersRefs.map(async (userRef) => {
        try {
          const userSnap = await getDoc(userRef);
          if (!userSnap.exists()) {
            return null;
          }
          const organizationData = await readOrganization(
            userSnap.data().organization
          );
          // Return id and data
          return {
            _id: userSnap.id,
            ...userSnap.data(),
            organizationName: organizationData.name,
          };
        } catch (error) {
          return null;
        }
      })
    );

    teamData.players = usersData;

    return {
      _id: teamSnap.id,
      ...teamData,
    };
  } catch (error) {
    console.log(error.message);
  }
};

// newTeam has a name, an array of user refs and  empty objects named "notes"
export const createTeam = async (simId, newTeam) => {
  try {
    const teamRef = doc(collection(db, "teams"));

    // Set Doc but retrieve id first
    await setDoc(teamRef, newTeam);

    // Add ref to team to simulation doc
    const simulationRef = doc(db, "simulations", simId);
    const simulationSnap = await getDoc(simulationRef);
    const simulationData = simulationSnap.data();
    if (simulationData.teams === undefined) {
      simulationData.teams = [];
    }
    await updateDoc(simulationRef, {
      teams: [...simulationData.teams, teamRef],
    });

    // Add ref to simulation to team doc
    await updateDoc(teamRef, {
      simulation: simulationRef,
    });

    return {
      success: true,
      team: newTeam,
    };
  } catch (error) {
    toast.error(error.message);
  }
};

export const updateTeam = async (teamId, updatedTeam, action = "") => {
  try {
    const teamRef = doc(db, "teams", teamId);

    await updateDoc(teamRef, updatedTeam);

    if (action === "") {
      toast.success("Team aktualisiert");
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const deleteTeam = async (teamToDeleteRef, simId) => {
  try {
    // Delete the team from the simulation
    try {
      const simulationRef = doc(db, "simulations", simId);
      const simulationSnap = await getDoc(simulationRef);
      const simulationData = simulationSnap.data();

      console.log("DELETE TEAM got triggered for team: ", teamToDeleteRef.id);

      simulationData.teams.forEach((team) => {
        console.log(
          team.id,
          teamToDeleteRef.id,
          team.id === teamToDeleteRef.id
        );
      });

      console.log("Not updated teams: ", simulationData.teams);

      const updatedTeams = simulationData.teams.filter(
        // eslint-disable-next-line
        (teamRef) => teamRef.id !== teamToDeleteRef.id
      );

      console.log("Updated teams: ", updatedTeams);

      await updateDoc(simulationRef, {
        teams: updatedTeams,
      });

      // Delete the team
      await deleteDoc(teamToDeleteRef);
    } catch (error) { }
  } catch (error) {
    console.log(error.message + " " + error.code);
  }
};

export const readTeamsBySimulation = async (simulationId) => { };

export const handleSignIn = async (key, playerRef) => {
  console.log(key);
  const data = await readSimulationByKey(key);
  console.log(data);
  const alreadyRegistered = data?.registered_players?.find(
    (player) => player.id === playerRef.id
  );
  if (data && !alreadyRegistered) {
    await updateSimulation(
      data._id,
      {
        registered_players: [...data.registered_players, playerRef],
      },
      "signIn"
    );

    window.history.replaceState({}, document.title, "/registrations");
    toast.success("Sie haben sich erfolgreich angemeldet.");
  } else if (alreadyRegistered) {
    toast.info("Sie haben sich bereits für dieses Training angemeldet.");
  }
};
