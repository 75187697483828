import {
  Button,
  Col,
  Container,
  Row,
  Image,
  Stack,
  Accordion,
  Card,
} from "react-bootstrap";
import Contact from "./Contact";
import {
  Icon360,
  IconMobile,
  IconTeaching,
  IconTeam,
} from "../../constants/Icons";
import vortrag from "../../media/about/duo_vortrag.jpg";
import preisüberleihung from "../../media/about/preis.jpg";
import game_general from "../../media/about/game_general.png";
import game_modal from "../../media/about/game_modal_open.png";
import game_warning from "../../media/about/game_warning.png";
import plakat from "../../media/about/plakat.jpg";
import React from "react";
import {
  ABOUT_PAGE_TEXT_360,
  ABOUT_PAGE_TEXT_DEBRIEFING,
  ABOUT_PAGE_TEXT_FLEXIBILITY,
  ABOUT_PAGE_TEXT_INTERPROFESSIONAL,
  MORE_INFOS_SIMULERN,
  SIMULERN_MOTTO,
} from "../../constants/Texts";

const images = [
  { src: vortrag, alt: "vortrag" },
  { src: game_general, alt: "game_general" },
  { src: preisüberleihung, alt: "preisüberleihung" },
  { src: game_modal, alt: "game_modal" },
  { src: game_warning, alt: "game_warning" },
];

function ImageRow() {
  return (
    <Stack
      direction="horizontal"
      gap={3}
      style={{
        overflowX: "scroll",
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        margin: "1em auto",
      }}
    >
      {images.map((image, idx) => (
        <Image key={idx} src={image.src} alt={image.alt} height={100} rounded />
      ))}
    </Stack>
  );
}

const SectionStyle = {
  padding: "1em",
  border: "1px solid #black",
};

const InfoBlock = ({ title, children, Icon }) => (
  <Col style={SectionStyle} sm={12} md={6} lg={3} className="mb-3 mb-lg-0">
    <h4>{title}</h4>
    <div className="text-center" style={{ fontSize: "7rem" }}>
      {<Icon />}
    </div>
    {children}
  </Col>
);

const InfoRow = ({ title, children }) => (
  <Row className="justify-content-md-center">{children}</Row>
);

function Reasons() {
  return (
    <Col>
      <br />
      <p className="lead text-center my-5">
        4 Gründe, warum Sie SimuLern verwenden sollten:
      </p>
      <InfoRow>
        <InfoBlock title="Interprofessionalität" Icon={IconTeam}>
          {ABOUT_PAGE_TEXT_INTERPROFESSIONAL}
        </InfoBlock>
        <InfoBlock title="360-Grad-Ansicht" Icon={Icon360}>
          {ABOUT_PAGE_TEXT_360}
        </InfoBlock>
        <InfoBlock title="Geführtes Debriefing" Icon={IconTeaching}>
          {ABOUT_PAGE_TEXT_DEBRIEFING}
        </InfoBlock>
        <InfoBlock title="Flexibilität" Icon={IconMobile}>
          {ABOUT_PAGE_TEXT_FLEXIBILITY}
        </InfoBlock>
      </InfoRow>
      <Row>
        <Contact />
      </Row>
      <br />
    </Col>
  );
}

function News() {
  return (
    <>
      <p>Hier finden Sie die neuesten Informationen zu SimuLern.</p>
      <Card>
        <Card.Header>InSim 2023</Card.Header>
        <Card.Body>
          <Card.Title>SimuLern gewinnt 2. Platz bei InSim 2023</Card.Title>
          Lesen Sie mehr über den Auftritt von SimuLern auf der InSim 2023,der
          größte Kongress für medizinische und präklinische Simulation im
          deutschsprachigen Raum.
          <br />
          <br />
          <Row>
            <Col>
              <Image
                src={preisüberleihung}
                alt="Preisüberleihung"
                width={"100%"}
              />
            </Col>
            <Col>
              <Image src={plakat} alt="Plakat" width={"100%"} />
            </Col>
          </Row>
          <Card.Footer>
            <a
              href="https://www.dhbw-stuttgart.de/artikel/studierende-gewinnen-preis-beim-simulationskongress-insim/"
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="dark">Zum Artikel</Button>
            </a>
          </Card.Footer>
        </Card.Body>
      </Card>

      <br />
    </>
  );
}

function LogoAction() {
  return (
    <Col className="text-center">
      <Container>
        <Row>
          <Col>
            <Video />
          </Col>
        </Row>
      </Container>
      <p className="text-center lead">{SIMULERN_MOTTO}</p>
      <br />
      <Button variant="dark" href="/demo">
        Jetzt ausprobieren! <i className="fas fa-play"></i>
      </Button>
      <br />
      <br />
    </Col>
  );
}

function MainInfo() {
  return (
    <Row>
      <Col sm={12} md={12} lg={6}>
        <Accordion className="mt-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Mehr Infos</Accordion.Header>
            <Accordion.Body>{MORE_INFOS_SIMULERN}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
      <Col sm={12} md={12} lg={6}>
        <Accordion className="mt-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Neuigkeiten</Accordion.Header>
            <Accordion.Body>
              <News />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
}

function Video() {
  return (
    <Col className="text-center my-5">
      <iframe
        title="SimuLern Video"
        width="560"
        height="315"
        className="mw-100"
        src="https://www.youtube.com/embed/XsdybhnXcYo"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
      ></iframe>
      <br />
      <br />
    </Col>
  );
}

function About() {
  return (
    <>
      <Container>
        <Row>
          <LogoAction />
        </Row>
        <Row>
          <ImageRow />
        </Row>
        <Row></Row>
        <MainInfo />
        <Row>
          <Reasons />
        </Row>
      </Container>
    </>
  );
}

export default About;
