import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { UserContext } from "./contexts/UserContext";
import { onAuthStateChanged } from "firebase/auth";
import { readUser } from "./services/firebase/api_old";
import Loading from "./components/shared/Loading";
import Routes from "./routes/Routes";
import { auth } from "./services/firebase/init";

function App() {
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
      const decodedToken = jwtDecode(accessToken);
      if (decodedToken.exp * 1000 < Date.now()) {
        sessionStorage.removeItem("accessToken");

        return null;
      } else {
        return decodedToken;
      }
    } else {
      return null;
    }
  });

  // Gets called every time the user changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = await readUser(user.uid);
        sessionStorage.setItem("accessToken", user.accessToken);
        setUser({
          ...user,
          ...userData,
        });
        setLoading(false);
      } else {
        setUser(null);
        setLoading(false);
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Loading />
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <UserContext.Provider value={{ user, setUser }}>
        <Router>
          <Routes user={user} setLoading={setLoading} />
        </Router>
        <ToastContainer />
      </UserContext.Provider>
    </div>
  );
}

export default App;
