import React from "react";
import { Modal } from "react-bootstrap";
import TeamTable from "../TeamTable";

function PlaygroundTeamModal({ show, onHide, team }) {
  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Body style={{ width: "100%", overflowX: "auto" }}>
        <TeamTable team={team} highlightUser={true} />
      </Modal.Body>
    </Modal>
  );
}

export default PlaygroundTeamModal;
