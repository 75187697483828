import { useEffect, useState } from "react";
import { onSnapshot, doc } from "firebase/firestore";
import { readScenario } from "../services/firebase/api_old";
import { db } from "../services/firebase/init";


export function useSimulation(simulationId) {
  const [simulation, setSimulation] = useState({});

  

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "simulations", simulationId),
      (doc) => {
        if (doc.exists()) {
          // Read doc.data().scenario (ref) and get the scenario data
          readScenario(doc.data().scenario).then((scenario) => {
            
            setSimulation({ ...doc.data(), _id: doc.id, scenario: scenario });
          });

          setSimulation({ ...doc.data(), _id: doc.id });
        } else {
          
        }
      }
    );

    return () => unsubscribe();
  }, [simulationId]);

  return simulation;
}
