import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Container } from "react-bootstrap";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";

function PlaygroundItemModal({
  show,
  setShow,
  title,
  image,
  notes,
  addNote,
  mode,
  id,
}) {
  const { user } = useContext(UserContext);
  const [item, setItem] = useState(null);
  const [note, setNote] = useState("");
  const [isChanged, setIsChanged] = useState(false); // added

  useEffect(() => {
    if (show) {
      setItem({ title: title, image: image, _id: id });
      const existingNote = notes[id]?.noteContent;
      setNote(existingNote ? existingNote : "");
    }
    setIsChanged(false); // added
    // eslint-disable-next-line
  }, [show]);

  const handleClose = () => {
    setShow(false);
  };

  const handleAddNote = () => {
    let userData = user;
    if (mode !== "multiplayer") {
      userData = {
        first_name: "Maxi",
        last_name: "Muster",
        id: "1",
        profile_picture: "...",
      };
    }
    addNote(item, note, {
      first_name: userData.first_name,
      last_name: userData.last_name,
      id: userData.uid,
      profile_picture: userData.profile_picture,
    });
    handleClose();
  };

  const handleTextChange = (e) => {
    setNote(e.target.value);
    setIsChanged(true); // if text changed, set flag to true
  };

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{item && item.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {item && (
          <Container>
            <img
              src={item.image}
              
              alt={item.title}
              style={{ display: "block", margin: "auto", height: "50vh" }}
            />
            <Form>
              <Form.Group className="mt-3" controlId="formBasicEmail">
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Wenn Sie denken, dass es sich hierbei um eine Gefährdung handelt, beschreiben Sie diese bitte kurz."
                  value={note}
                  onChange={handleTextChange} // use the new handleTextChange method
                />
              </Form.Group>
            </Form>
          </Container>
        )}
      </Modal.Body>
      <Modal.Footer>
        {isChanged && ( // if text has changed, show the button
          <Button variant="success" onClick={handleAddNote}>
            Speichern
          </Button>
        )}

        <Button variant="dark" onClick={handleClose}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PlaygroundItemModal;
