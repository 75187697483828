import { Card, Button, Col, FormControl } from "react-bootstrap";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";
import ImageProfile from "../../shared/imageprofile/ImageProfile";
import {
  getItemStyle,
  getListStyle,
  getUniqueTeamName,
  removeTeam,
} from "./TeamsHelpers";

import {
  IconBack,
  IconDelete,
  IconMagic,
  IconStart,
  IconTeam,
} from "../../../constants/Icons";

export function PlayerCard({ player, index, provided, snapshot, simId }) {
  const playerJoined = player.current_simulation?._id === simId;
  const backgroundColor = playerJoined ? "success" : "white";
  const fontColor = playerJoined ? "white" : " black";

  return (
    <Card
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
        flex: "1 0 20%",
        margin: "0.5rem",
      }}
      bg={backgroundColor}
      text={fontColor}
      body
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span>
          <b>
            {player.first_name} {player.last_name}
          </b>
        </span>
        <ImageProfile src={player.profile_picture} size="30px" />
      </div>
      {player.occupation} <br />
      {player.organizationName}
    </Card>
  );
}

export function TeamCard({ teamId, teamPlayers, simId, type }) {
  return (
    <Droppable droppableId={teamId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{
            ...getListStyle(snapshot.isDraggingOver),
            display: type === "pool" ? "flex" : "block", // Add this
            flexWrap: type === "pool" ? "wrap" : "nowrap", // And this
          }}
          {...provided.droppableProps}
        >
          {teamPlayers.length === 0 && (
            <Card.Body>
              <Card.Text>
                <b>Keine Spieler vorhanden</b>
              </Card.Text>
            </Card.Body>
          )}
          {teamPlayers.map((player, index) => {
            return (
              <Draggable
                key={player._id}
                draggableId={player._id}
                index={index}
              >
                {(provided, snapshot) => (
                  <PlayerCard
                    player={player}
                    index={index}
                    provided={provided}
                    snapshot={snapshot}
                    simId={simId}
                  />
                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export const TeamsMainHeader = ({
  playerPool,
  state,
  isChanged,
  handleDistributePlayers,
  handleResetTeams,
  handleSaveTeams,
  setShowConfirmStart,
  setShowConfirmLeave,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Button
        variant="dark"
        onClick={() =>
          isChanged ? setShowConfirmLeave(true) : window.location.href = "/trainings"
        }
      >
        <IconBack />
      </Button>

      <Button variant="dark" onClick={() => setShowConfirmStart(true)}>
        <IconStart /> Training starten
      </Button>

      {isChanged && (
        <Button variant="success" onClick={handleSaveTeams}>
          Speichern
        </Button>
      )}

      {state.some((team) => team.players.length > 0) && (
        <Button variant="danger" onClick={handleResetTeams}>
          Zurücksetzen
        </Button>
      )}

      {playerPool.length > 0 && state.length > 0 && (
        <Button variant="dark" onClick={handleDistributePlayers}>
          Auto <IconMagic />
        </Button>
      )}
    </div>
  );
};

export function TeamsGrid({
  state,
  playerPool,
  handleOnDragEnd,
  setState,
  setIsChanged,
  registerKey,
  registeredPlayers,
  simId,
}) {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <span>
          Zugangscode: <b>{registerKey}</b>
        </span>
      </div>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
            flexWrap: "wrap",
            gap: "0.5rem",
            marginBottom: "1rem",
          }}
        >
          <Card style={{ borderRadius: "15px", width: "100%" }}>
            <Card.Header>Nicht zugeordnet</Card.Header>
            <TeamCard
              teamId="playerPool"
              teamPlayers={playerPool}
              simId={simId}
              type="pool"
            />
          </Card>
        </div>
        <hr className="my-4" />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(310px, 1fr))",
            gap: "0.5rem",
            width: "100%",
          }}
        >
          {state.map((team, teamId) => {
            return (
              <Col key={teamId}>
                <Card style={{ borderRadius: "15px", width: "100%" }}>
                  <Card.Header
                    className="d-flex justify-content-between"
                    style={{ alignItems: "center" }}
                  >
                    <IconTeam />
                    <FormControl
                      style={{
                        width: "150px",
                        padding: "0px 5px",
                        textAlign: "center",
                      }}
                      value={team.name}
                      onChange={(e) => {
                        let newState = [...state];
                        newState[teamId].name = e.target.value;
                        setState(newState);
                        setIsChanged(true);
                      }}
                    />

                    <span>{team.players.length}</span>
                    <Button
                      style={{
                        padding: "0px 5px",
                      }}
                      variant="secondary"
                      size="sm"
                      onClick={() => {
                        removeTeam(setState, state, setIsChanged)(teamId);
                      }}
                      disabled={team.players.length > 0}
                    >
                      <IconDelete />
                    </Button>
                  </Card.Header>
                  <TeamCard
                    teamId={team.name}
                    teamPlayers={team.players}
                    simId={simId}
                  />
                </Card>
              </Col>
            );
          })}
          <Button
            variant="dark"
            size="sm"
            onClick={() => {
              setState([
                ...state,
                {
                  name: getUniqueTeamName(state.map((team) => team.name)),
                  players: [],
                },
              ]);
              setIsChanged(true);
            }}
            style={{ height: "115px", width: "100%", borderRadius: "15px" }}
          >
            Team hinzufügen +
          </Button>
        </div>
      </DragDropContext>
    </>
  );
}
