import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { generateRegisterLink } from "../../services/firebase/api_old";

function RegisterLinks() {
  const [type, setType] = useState("");

  return (
    <Container>
      <h3>Registrierung-Link</h3>
      <br />
      <Form>
        <Form.Group className="mb-3" controlId="formBasicSelect">
          <Form.Control
            as="select"
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <option value="">Auswählen...</option>
            <option value="Player">Spieler</option>
            <option value="Trainer">Trainer</option>
          </Form.Control>
        </Form.Group>

        <Button
          variant="dark"
          onClick={() =>
            generateRegisterLink(type).then((link) => {
              document.getElementById("registerLink").value = link;
            })
          }
        >
          Link generieren
        </Button>
      </Form>
      <br />
      <br />
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Registrierungslink</Form.Label>
          <Form.Control
            id="registerLink"
            type="text"
            placeholder="Hier erscheint der Link"
            readOnly
          />
          <Form.Text className="text-muted">
            Dieser Key ist nur einmal sichtbar. Speichern Sie ihn sicher ab.
          </Form.Text>
        </Form.Group>
      </Form>
    </Container>
  );
}

export default RegisterLinks;
