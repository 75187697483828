import { Container, Image } from "react-bootstrap";
import logo from "../../media/logo-transparent-background.png";
import { IMPRESSUM_TEXT } from "../../constants/Texts";

export const Datenschutz = () => {
  return (
    <Container className="my-5">
      <Image src={logo} width={200} height={200} />
      {IMPRESSUM_TEXT}
    </Container>
  );
};
