import { Button, Form, InputGroup } from "react-bootstrap";
import { IconSearch } from "../../constants/Icons";

function Search({
  searchTerm,
  placeholder,
  onChange,
  showButton,
  onButtonClick,
}) {
  return (
    <InputGroup>
      <InputGroup.Text>
        <IconSearch />
      </InputGroup.Text>

      <Form.Control
        type="search"
        placeholder={placeholder}
        value={searchTerm}
        onChange={onChange}
      />

      {showButton && (
        <Button variant="dark" onClick={onButtonClick}>
          Anmelden
        </Button>
      )}
    </InputGroup>
  );
}

export default Search;
