import React, { useEffect } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import PageNotFound from "../components/shared/NotFound";
import Contact from "../components/shared/Contact";

// Reusable components
import Login from "../components/shared/Login";
import Registration from "../components/shared/Registration";
import Profile from "../components/shared/Profile";
import Footer from "../components/shared/footer/Footer";
import Playground from "../components/shared/training/Playground";
import Impressum from "../components/shared/Impressum";

// Player specific components
import PlayerNavbar from "../components/player/PlayerNavbar";
import PlayerHome from "../components/player/PlayerHome";
import Explore from "../components/player/Explore";

// Trainer specific components
import Teams from "../components/trainer/manage-teams/Teams";
import TableSimulations from "../components/trainer/manage-trainings/Trainings";
import Scenarios from "../components/trainer/scenarios/Scenarios";
import TrainerHome from "../components/trainer/TrainerHome";
import TrainerNavbar from "../components/trainer/TrainerNavbar";
import Dashboard from "../components/admin/Dashboard";

// Admin specific components
import AdminNavbar from "../components/admin/AdminNavbar";
import Registrations from "../components/player/Registrations";
import FormResetPassword from "../components/shared/FormResetPassword";
import About from "../components/shared/About";
import PublicNavbar from "../components/shared/publicnavbar/PublicNavbar";
import WaitingRoom from "../components/player/WaitingRoom";
import Configurator from "../components/admin/Configurator";
import Debriefing from "../components/trainer/debriefing/Debriefing";
import Ceremony from "../components/trainer/ceremony/Ceremony";
import { Datenschutz } from "../components/shared/Datenschutz";
import BaseFAQ from "../components/shared/BaseFAQ";
import { answers_trainer, questions_trainer } from "../constants/Texts";
import RegisterLinks from "../components/admin/RegisterLinks";
import UserOverview from "../components/admin/UserOverview";

function RespectiveNavbar({ user }) {
  if (user) {
    if (user.type === "Player") {
      return <PlayerNavbar />;
    } else if (user.type === "Trainer") {
      return <TrainerNavbar />;
    } else if (user.type === "Admin") {
      return <AdminNavbar />;
    } else {
      return <PublicNavbar />;
    }
  } else {
    return <PublicNavbar />;
  }
}

function Routes({ user, setLoading }) {
  // Every time user changes, the routes are re-evaluated, therefore print user to console
  useEffect(() => {}, [user]);

  const redirectRoutes = [
    {
      path: "/",
      element: <Navigate to="/about" />,
    },
    {
      path: "/home",
      element: <Navigate to="/about" />,
    },
  ];

  const publicRoutes = [
    {
      path: "/about",
      element: (
        <>
          <RespectiveNavbar user={user} />
          <About />
          <Footer />
        </>
      ),
    },
    {
      path: "/login",
      element: <Login /> /* Show this page if user is not logged in */,
    },
    {
      path: "/reset-password",
      element: <FormResetPassword />,
    },
    {
      path: "/register",
      element: <Registration />,
    },
    {
      path: "/demo",
      element: <Playground />,
    },
    {
      path: "/playground",
      element: <Playground />,
    },

    {
      path: "/contact",
      element: (
        <>
          <RespectiveNavbar user={user} />
          <Contact /> <Footer />
        </>
      ),
    },
    {
      path: "/impressum",
      element: (
        <>
          <RespectiveNavbar user={user} />
          <Impressum />
          <Footer />
        </>
      ),
    },
    {
      path: "/datenschutz",
      element: (
        <>
          <RespectiveNavbar user={user} />
          <Datenschutz />
          <Footer />
        </>
      ),
    },
  ];

  // Routes that are only available for logged in admins

  const adminRoutes = [
    ...publicRoutes,
    {
      path: "/home",
      element: user ? (
        <>
          <AdminNavbar />
          <Dashboard />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/profile",
      element: user ? (
        <>
          <AdminNavbar />
          <Profile />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/configurator",
      element: user ? (
        <>
          <AdminNavbar />
          <Configurator />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/register-links",
      element: user ? (
        <>
          <AdminNavbar />
          <RegisterLinks />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/user-overview",
      element: user ? (
        <>
          <AdminNavbar />
          <UserOverview />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
  ];

  // Routes that are only available for logged in players
  const playerRoutes = [
    ...publicRoutes,
    {
      path: "/",
      element: user ? (
        <>
          <PlayerNavbar />
          <PlayerHome />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/home",
      element: user ? (
        <>
          {" "}
          <PlayerNavbar />
          <PlayerHome />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/registrations",
      element: user ? (
        <>
          <PlayerNavbar />
          <Registrations />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/explore",
      element: user ? (
        <>
          <PlayerNavbar />
          <Explore />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/demo",
      element: user ? <Playground user={user} /> : <Navigate to="/login" />,
    },
    {
      path: "/waitingroom",
      element: user ? <WaitingRoom user={user} /> : <Navigate to="/login" />,
    },
    {
      path: "/training",
      element: user ? (
        <Playground user={user} mode="multiplayer" />
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/profile",
      element: user ? (
        <>
          <PlayerNavbar />
          <Profile />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/player-faq",
      element: user ? (
        <>
          <PlayerNavbar />
          <BaseFAQ
            questions={questions_trainer}
            answers={answers_trainer}
          />; <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/contact",
      element: user ? (
        <>
          <PlayerNavbar />
          <Contact /> <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },

    {
      path: "/*", // Catch all other routes
      element: user ? (
        <>
          <PlayerNavbar />
          <PageNotFound />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
  ];

  // Routes that are only available for logged in trainers

  const trainerRoutes = [
    ...publicRoutes,
    {
      path: "/",
      element: user ? (
        <>
          <TrainerNavbar />
          <TrainerHome />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/home",
      element: user ? (
        <>
          {" "}
          <TrainerNavbar />
          <TrainerHome />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/trainings",
      element: user ? (
        <>
          <TrainerNavbar />
          <TableSimulations setLoading={setLoading} />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/scenarios",
      element: user ? (
        <>
          <TrainerNavbar />
          <Scenarios setLoading={setLoading} />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/trainer-faq",
      element: user ? (
        <>
          <TrainerNavbar />
          <BaseFAQ
            questions={questions_trainer}
            answers={answers_trainer}
          />{" "}
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/profile",
      element: user ? (
        <>
          <TrainerNavbar />
          <Profile />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/manage-teams",
      element: user ? (
        <>
          <TrainerNavbar />
          <Teams />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/debriefing",
      element: user ? (
        <>
          <TrainerNavbar />
          <Debriefing />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/ceremony",
      element: user ? (
        <>
          <TrainerNavbar />
          <Ceremony />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/demo",
      element: user ? <Playground user={user} /> : <Navigate to="/login" />,
    },
    {
      path: "/contact",
      element: user ? (
        <>
          <TrainerNavbar />
          <Contact /> <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/impressum",
      element: user ? (
        <>
          {" "}
          <TrainerNavbar />
          <Impressum />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
    {
      path: "/*", // Catch all other routes
      element: user ? (
        <>
          <TrainerNavbar />
          <PageNotFound />
          <Footer />
        </>
      ) : (
        <Navigate to="/login" />
      ),
    },
  ];

  const routesToRender = user
    ? user.type === "Trainer"
      ? trainerRoutes
      : user.type === "Player"
      ? playerRoutes
      : user.type === "Admin"
      ? adminRoutes
      : [...publicRoutes, ...redirectRoutes]
    : [...publicRoutes, ...redirectRoutes];

  const routes = useRoutes(routesToRender);

  return routes;
}

export default Routes;
