import React, { useState } from "react";

function Watermark() {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="text-center fixed-bottom w-25 mx-auto mb-1 mb-md-5"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        zIndex: 1,
      }}
    >
      <p
        style={{
          color: "white",
          fontSize: "0.8rem",
          cursor: "pointer",
          ...(isHovered && { textDecoration: "underline" }),
        }}
        onClick={() => (window.location.href = "/contact")}
      >
        Beta Version <br /> © 2023 <span className="d-none d-md-inline">
          Daniel Seger und Veronika Spielmann <br />{" "}
          Foto: Klinikum Stuttgart
        </span>
      </p>
    </div>
  );
}

export default Watermark;
