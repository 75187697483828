import { Modal } from "react-bootstrap";
import RedirectCountdown from "./PlaygroundRedirectCountdown";

function PlaygroundEndModal({ show, handleClose }) {
  return (
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Zeit abgelaufen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Die Zeit ist abgelaufen. Vielen Dank für Ihre Teilnahme!
          <br />
          <br />
          Ihr Ergebnis wird von Ihren TrainerInnen ausgewertet und diese teilen die
          Ergebnisse mit Ihnen im anschließenden, gemeinsamen Debriefing.
          <br />
          <br />
          Sie werden in wenigen Sekunden automatisch zur Startseite
          weitergeleitet. Falls Sie nicht automatisch weitergeleitet werden,
          klicken Sie bitte <a href="/" style={{ color: "black" }}>hier</a>.
          <br />
          <br />
          <RedirectCountdown initialCount={15} redirectTo="/" />
        </Modal.Body>
      </Modal>
  );
}

export default PlaygroundEndModal;
