import {
  Button,
  Col,
  ProgressBar,
  Row,
  Table,
  Container,
  Stack,
  Modal,
  Image,
} from "react-bootstrap";
import "./Debriefing.css";

const DebriefStart = ({ teams, setDebriefingStarted }) => (
  <Container className="d-flex flex-column justify-content-center align-items-center mt-5">
    <h1>Debriefing</h1>
    <br />
    <p className="w-50 mb-5 mx-auto">
      Hier können Sie die Antworten der Teams auf die Fragen des Trainings
      einsehen und die Musterlösung präsentieren. Klicken Sie dazu auf
      "Musterlösung Anzeigen" und "Weiter" um zur nächsten Frage zu gelangen.
    </p>
    <br />
    <Table>
      <thead>
        <tr>
          <th>Team Name</th>
          <th>Anzahl Antworten</th>
        </tr>
      </thead>
      <tbody>
        {Object.values(teams).map((team, index) => {
          return (
            <tr key={index}>
              <td>{team.name}</td>
              <td>
                {
                  (
                    Object.keys(team?.notes).filter((key) => {
                      return team?.notes[key]?.noteContent;
                    }) || {}
                  ).length
                }
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
    <br />
    <Button variant="dark" onClick={() => setDebriefingStarted(true)} className="mb-4" >
      Debriefing starten
    </Button>
  </Container>
);

export const ScoreDiv = ({
  teamIndex,
  score,
  point,
  currenItemIndex,
  handleScoreUpdate,
}) => (
  <div
    className={score >= point ? "circular-div green" : "circular-div"}
    onClick={() => {
      handleScoreUpdate(
        currenItemIndex,
        teamIndex,
        score === 1 && point === 1 ? 0 : point
      );
    }}
  >
    {point} Punkt{point > 1 ? "e" : ""}
  </div>
);

const DebriefInProgress = ({
  currentItem,
  handleReview,
  reviewedItems,
  setBlurred,
  blurred,
  items,
  setScenarioItems,
  currenItemIndex,
  handleScoreUpdate,
  setShowZoomModal,
  showZoomModal,
}) => {
  return (
    <div>
      <Container className="d-flex justify-content-between">
        <h3>{currentItem.title}</h3>
        <Stack gap={3} direction="horizontal">
          <Button onClick={() => handleReview(-1)} variant="dark">
            Zurück
          </Button>

          <Button onClick={() => handleReview(1)} variant="dark">
            Weiter
          </Button>
        </Stack>
      </Container>
      <br />
      <Table bordered>
        <thead>
          <tr>
            <th>Team</th>
            <th>Antwort</th>
          </tr>
        </thead>
        <tbody>
          {currentItem.answers.map((answer, index) => {
            return (
              <tr key={index}>
                <td>{answer.team}</td>
                <td style={{ width: "50%" }}>{answer.answer?.noteContent}</td>
                <td>
                  <Container className="d-flex justify-content-between">
                    <ScoreDiv
                      teamIndex={index}
                      score={currentItem.scores[index]}
                      point={1}
                      index={index}
                      currenItemIndex={currenItemIndex}
                      items={items}
                      setScenarioItems={setScenarioItems}
                      handleScoreUpdate={handleScoreUpdate}
                    />
                    <ScoreDiv
                      teamIndex={index}
                      score={currentItem.scores[index]}
                      point={2}
                      index={index}
                      currenItemIndex={currenItemIndex}
                      items={items}
                      setScenarioItems={setScenarioItems}
                      handleScoreUpdate={handleScoreUpdate}
                    />
                  </Container>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Row className="justify-content-md-center mt-5">
        <Col md="auto"></Col>
      </Row>
      <Row className="justify-content-center my-5">
        <Col>
          <img
            src={currentItem.photo_url}
            style={{
              cursor: "pointer",
            }}
            alt="item"
            height={200}
            onClick={() => setShowZoomModal(true)}
          />
          <ZoomModal
            title={currentItem.title}
            imageSource={currentItem.photo_url}
            show={showZoomModal}
            setShow={setShowZoomModal}
          />
        </Col>
        <Col md="auto">
          <Stack gap={3}>
            <p
              style={{
                filter: blurred ? "blur(5px)" : "none",
              }}
            >
              {currentItem.solution}
            </p>
            <Button onClick={() => setBlurred(!blurred)} variant="dark">
              {blurred ? "Musterlösung Anzeigen" : "Musterlösung Ausblenden"}
            </Button>
          </Stack>
        </Col>
      </Row>
      <ProgressBar className="w-50 mb-4 mx-auto" style={{ height: "40px" }}>
        <ProgressBar
          style={{ height: "40px" }}
          variant="success"
          now={(reviewedItems / items.length) * 100}
          label={
            <span
              style={{
                color: "white",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              Erledigt
            </span>
          }
        />
        <ProgressBar
          style={{ height: "40px" }}
          striped
          animated
          variant="warning"
          now={(1 / items.length) * 100}
          key={1}
        />
      </ProgressBar>
    </div>
  );
};

const DebriefComplete = ({ setReviewedItems, setcurrenItemIndex }) => (
  <Container className="d-flex flex-column justify-content-center align-items-center mt-5">
    <h1>Debriefing abgeschlossen</h1>
    <br />
    <p className="w-50 mb-5">
      Das Debriefing ist abgeschlossen. Sie können nun zur Siegerehrung
      wechseln.
    </p>
    <br />
    <Button
      variant="dark"
      onClick={() => {
        setReviewedItems(0);
        setcurrenItemIndex(0);
      }}
    >
      Zurück
    </Button>
    <br />
    <Button variant="dark" onClick={() => (window.location.href = "/ceremony")}>
      Zur Siegerehrung
    </Button>
  </Container>
);

const DebriefingContent = ({
  debriefingStarted,
  reviewedItems,
  itemsLength,
  currentItem,
  teams,
  handleReview,
  setDebriefingStarted,
  setBlurred,
  blurred,
  items,
  setScenarioItems,
  currenItemIndex,
  setReviewedItems,
  setcurrenItemIndex,
  handleScoreUpdate,
  setShowZoomModal,
  showZoomModal,
}) => {
  return (
    <div>
      {debriefingStarted ? (
        reviewedItems === itemsLength ? (
          <DebriefComplete
            setReviewedItems={setReviewedItems}
            setcurrenItemIndex={setcurrenItemIndex}
          />
        ) : (
          <DebriefInProgress
            currentItem={currentItem}
            handleReview={handleReview}
            reviewedItems={reviewedItems}
            setBlurred={setBlurred}
            blurred={blurred}
            items={items}
            setScenarioItems={setScenarioItems}
            currenItemIndex={currenItemIndex}
            handleScoreUpdate={handleScoreUpdate}
            setShowZoomModal={setShowZoomModal}
            showZoomModal={showZoomModal}
          />
        )
      ) : (
        <DebriefStart
          teams={teams}
          setDebriefingStarted={setDebriefingStarted}
        />
      )}
    </div>
  );
};

export default DebriefingContent;

function ZoomModal({ title, imageSource, show, setShow }) {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
      style={{ zIndex: 9999 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Image src={imageSource} alt={title} style={{ height: "70vh" }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={() => setShow(false)}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
