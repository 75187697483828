import React from "react";
import dummy from "../../../media/dummy/dummy-profile-pic.png";
import "./ImageProfile.css";

const ImageProfile = ({
  src,
  alt,
  size,
  onClick = () => {},
  choose = false,
  upload = false,
}) => {
  const styles = {
    cursor: choose ? "pointer" : "auto",
    borderRadius: "50%",
    width: size,
    height: size,
    objectFit: "cover", // This will prevent stretching of the image
    objectPosition: "center", // This will center the image in the circle
  };

  if (!src) {
    src = dummy;
  }

  return (
    <div
      className="round-image-container"
      onClick={choose ? onClick : undefined}
    >
      <img src={src} alt={alt} style={styles} />
      {choose && <div className="image-overlay" />}
      {upload && (
        <span
          style={{
            backgroundColor: "grey",
            opacity: "0.5",
            padding: "5px",
            borderRadius: "5%",
            position: "absolute",
            bottom: "25%",
            right: "10%",
            color: "white",
            fontSize: "0.8rem",
            fontWeight: "bold",
          }}
        >
          Profilbild
          <br /> hinzufügen
        </span>
      )}
    </div>
  );
};

export default ImageProfile;
