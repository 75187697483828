import React, { useContext, useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { UserContext } from "../../../contexts/UserContext";
import { readScenarios } from "../../../services/firebase/api_old";

import { Scenario } from "./ScenariosComponents";

function Scenarios() {
  const { user } = useContext(UserContext);

  const [scenarios, setScenarios] = useState([]);

  const [selectedScenario, setSelectedScenario] = useState(null);

  let locationHash = window.location.hash.substring(1);

  useEffect(() => {
    const fetchData = async () => {
      const scenarios = await readScenarios(user.scenarios);

      setScenarios(scenarios);
      if (scenarios.length > 0) {
        if (locationHash) {
          const selected = scenarios.find(
            (scenario) => scenario._id === locationHash
          );
          setSelectedScenario(selected ? selected._id : scenarios[0]._id);
        } else {
          setSelectedScenario(scenarios[0]._id);
        }
      }
    };
    fetchData();
  }, [user.scenarios, locationHash]);

  const handleScenarioChange = (event) => {
    setSelectedScenario(event.target.value);
  };

  return (
    <Container>
      <p>
        Wählen Sie ein Szenario aus, um die Ansichten und die darin enthaltenen
        Gegenstände zu sehen.
      </p>
      <Form.Select value={selectedScenario} onChange={handleScenarioChange}>
        {scenarios.map((scenario, scenarioIndex) => (
          <option value={scenario._id} key={scenarioIndex}>
            {scenario.title}
          </option>
        ))}
      </Form.Select>
      <br />

      {scenarios.length > 0 ? (
        <Scenario
          scenario={scenarios.find(
            (scenario) => scenario._id === selectedScenario
          )}
        />
      ) : (
        <p className="mb-5 mt-4 text-center">Keine Szenarien verfügbar</p>
      )}
    </Container>
  );
}

export default Scenarios;
