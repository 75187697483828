import React, { useState } from "react";
import { Container } from "react-bootstrap";
import {
  distributePlayers,
  resetTeams,
  onDragEnd,
  saveTeams,
} from "./TeamsHelpers";

import DialogConfirm from "../../shared/Confirm";
import useTeams from "../../../hooks/useTeams";
import { startSimulation } from "../../../services/firebase/api_old";

import { TeamsMainHeader, TeamsGrid } from "./TeamsComponents";
import { useSimulation } from "../../../hooks/useSimulation";
import Loading from "../../shared/Loading";

export function Teams() {
  const [showConfirm, setShowConfirmStart] = useState(false);
  const [showConfirmLeave, setShowConfirmLeave] = useState(false);
  const simulationId = sessionStorage.getItem("simulationId");
  const simulation = useSimulation(simulationId);
  const [isLoading, setIsLoading] = useState(false);

  const { state, playerPool, setPlayerPool, originalPlayers, setState } =
    useTeams(simulation);

  const [isChanged, setIsChanged] = useState(false);

  const handleDistributePlayers = () =>
    distributePlayers(playerPool, state, setPlayerPool, setState, setIsChanged);
  const handleOnDragEnd = (result) =>
    onDragEnd(playerPool, setPlayerPool, state, setState, setIsChanged, result);
  const handleSaveTeams = () => {
    saveTeams(simulationId, simulation, state, setIsChanged);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleResetTeams = () =>
    resetTeams(originalPlayers, setPlayerPool, state, setState, setIsChanged);

  if (simulation === undefined || isLoading === true) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Loading />
      </div>
    );
  }

  return (
    <Container className="mb-3 mb-5">
      <TeamsMainHeader
        playerPool={playerPool}
        state={state}
        isChanged={isChanged}
        handleDistributePlayers={handleDistributePlayers}
        handleResetTeams={handleResetTeams}
        handleSaveTeams={handleSaveTeams}
        setShowConfirmStart={setShowConfirmStart}
        setShowConfirmLeave={setShowConfirmLeave}
      />
      <hr />
      <TeamsGrid
        state={state}
        playerPool={playerPool}
        handleOnDragEnd={handleOnDragEnd}
        setState={setState}
        setIsChanged={setIsChanged}
        registerKey={simulation.register_key}
        registeredPlayers={simulation.registered_players}
        simId={simulationId}
      />

      <DialogConfirm
        show={showConfirmLeave}
        text={
          "Möchten Sie dieses Training wirklich verlassen? Ungespeicherte Änderungen gehen verloren."
        }
        onConfirm={() => {
          setShowConfirmLeave(false);
          window.history.back();
        }}
        onCancel={() => setShowConfirmLeave(false)}
      />

      <DialogConfirm
        show={showConfirm}
        text={
          "Möchten Sie dieses Training wirklich starten? Gehen Sie sicher, dass alle TeilnehmerInnen bereit sind."
        }
        onConfirm={() => {
          setShowConfirmStart(false);
          simulation.scenario = simulation.scenario._id;
          startSimulation(simulation);
          // Wait 1 second for the simulation to start
          setTimeout(() => {
            window.location.href = "/trainings";
          }, 1000);
        }}
        onCancel={() => setShowConfirmStart(false)}
      />
    </Container>
  );
}

export default Teams;
