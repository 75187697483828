import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  updateSimulation,
  readUser,
  generateRegisterLink,
} from "../services/firebase/api_old";
import { createSimulation } from "../services/firebase/api_old";

export function useSimulationForm(
  baseSim,
  user,
  setUser,
  selectedSimulation,
  isEditmode,
  setIsEditmode,
  setShowCreateSimulationModal
) {
  const [simulation, setSimulation] = useState(baseSim);

  useEffect(() => {
    if (selectedSimulation) {
      setSimulation(selectedSimulation);
    } else {
      setSimulation(baseSim);
    }
  }, [selectedSimulation, baseSim]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      simulation.title === "" ||
      simulation.description === "" ||
      simulation.register_key === "" ||
      simulation.scenario === "" ||
      simulation.duration === 0
    ) {
      return toast.error("Bitte fülle alle Felder aus");
    }

    try {
      if (isEditmode) {
        await updateSimulation(simulation._id, simulation);
        setIsEditmode(false);
        setShowCreateSimulationModal(false);
      } else {
        simulation.teams = [];
        await generateRegisterLink("Player").then((key) => {
          simulation.registrations_key = key;
        });
        await createSimulation(simulation, user.uid);

        // temporary fix to update simulations array
        const updatedSimRefs = await readUser(user.uid);
        setUser({
          ...user,
          simulations: updatedSimRefs.simulations,
        });

        setShowCreateSimulationModal(false);
        setSimulation(baseSim);
      }
    } catch (error) {
      console.error("Error details:", error);
      return alert(error.response);
    }
  };

  const handleChange = (event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (event.target.name === "scenario") {
      console.log(event.target.value);
      value = event.target.value;
    }

    setSimulation({
      ...simulation,
      [event.target.name]: value,
    });
  };

  return { simulation, handleChange, handleSubmit, setSimulation };
}
