import React from "react";

function PageNotFound() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <h1>404</h1>
      <h2>Seite nicht gefunden</h2>
      <p>
        Die Seite die Sie suchen, existiert nicht.
      </p>
      <a href="/" style={{ color: "black" }}>Zur Startseite</a>
    </div>
  );
}

export default PageNotFound;
