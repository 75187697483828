import {
  Card,
  Button,
  Table,
  Accordion,
} from "react-bootstrap";
import { Entity, Scene } from "aframe-react";
import { useEffect, useState } from "react";

export function ViewTable({ view, hoveredItemNum, setHoveredItemNum }) {
  return (
    <>
      {view && view.items?.length > 0 ? (
        <Accordion className="my-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Klickbare Elemente</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Gefahr</th>
                    <th>Bild</th>
                    <th>Musterlösung</th>
                  </tr>
                </thead>
                <tbody>
                  {view.items?.map((item, _) => {
                    return (
                      <tr key={item._id}>
                        <td
                          onMouseEnter={() => setHoveredItemNum(item._id)}
                          onMouseLeave={() => setHoveredItemNum(null)}
                          style={{
                            backgroundColor:
                              item._id === hoveredItemNum
                                ? "lightgray"
                                : "white",
                          }}
                        >
                          {item.title}
                        </td>
                        <td
                          onMouseEnter={() => setHoveredItemNum(item._id)}
                          onMouseLeave={() => setHoveredItemNum(null)}
                          style={{
                            backgroundColor:
                              item._id === hoveredItemNum
                                ? "lightgray"
                                : "white",
                          }}
                        >
                          {item.isDanger ? "Ja" : "Nein"}
                        </td>
                        <td
                          onMouseEnter={() => setHoveredItemNum(item._id)}
                          onMouseLeave={() => setHoveredItemNum(null)}
                          style={{
                            backgroundColor:
                              item._id === hoveredItemNum
                                ? "lightgray"
                                : "white",
                          }}
                        >
                          <img
                            src={item.photo_url}
                            alt={item.title}
                            style={{ width: "50px", height: "50px" }}
                          ></img>
                        </td>
                        <td
                          onMouseEnter={() => setHoveredItemNum(item._id)}
                          onMouseLeave={() => setHoveredItemNum(null)}
                          style={{
                            backgroundColor:
                              item._id === hoveredItemNum
                                ? "lightgray"
                                : "white",
                          }}
                        >
                          {item.solution}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <p className="text-center my-3">
          Keine klickbaren Elemente in dieser Perspektive sichtbar
        </p>
      )}
    </>
  );
}

export function View({
  view,
  viewIndex,
  scenario,
  setHoveredItemNum,
  hoveredItemNum,
  setHoveredSwitchNum,
  hoveredSwitchNum,
}) {
  const viewSlideStyle = {
    position: "absolute",
    top: 5,
    color: "white",
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: "5px",
    borderRadius: "5px",
  };

  const viewSlideStyleTitle = {
    ...viewSlideStyle,
    right: 5,
  };

  const viewSlideStyleItemAmount = {
    ...viewSlideStyle,
    left: 5,
  };

  return (
    <div style={{ position: "relative" }} key={viewIndex}>
      <div id="wrapper" style={{ height: "400px" }}>
        <Scene embedded cursor="rayOrigin: mouse" vr-mode-ui="enabled: false">
          <Entity
            primitive="a-camera"
            position="0 1.6 0"
            wasd-controls="enabled: false"
          />
          <Entity primitive="a-sky" src={view.photo_url} />
          {/* NEEEEEEW */}
          {view.items &&
            view.items.map((item, index) => {
              return (
                <>
                  <Entity
                    rotation={
                      item.rotation.x +
                      " " +
                      item.rotation.y +
                      " " +
                      item.rotation.z
                    }
                    position={
                      item.position.x +
                      " " +
                      item.position.y +
                      " " +
                      item.position.z
                    }
                    scale={item.scale.x + " " + item.scale.y}
                    key={index}
                    geometry={{
                      primitive: "plane",
                      width: 1,
                      height: 1,
                    }}
                    material={{
                      color:
                        hoveredItemNum === item._id ? "#FFFFFF" : "#808080",
                      opacity: 0.5,
                    }}
                    events={{
                      mouseenter: () => {
                        setHoveredItemNum(item._id);
                      },
                      mouseleave: () => {
                        setHoveredItemNum(null);
                      },
                    }}
                  />
                </>
              );
            })}
          {view.switches &&
            view.switches.map((switchItem, viewIndex) => {
              return (
                <Entity
                  text={{
                    value: switchItem.description,
                    align: "center",
                    width: 2,
                    color:
                      hoveredSwitchNum === switchItem._id ? "black" : "white",
                  }}
                  rotation={
                    switchItem.rotation.x +
                    " " +
                    switchItem.rotation.y +
                    " " +
                    switchItem.rotation.z
                  }
                  position={
                    switchItem.position.x +
                    " " +
                    switchItem.position.y +
                    " " +
                    switchItem.position.z
                  }
                  scale={switchItem.scale.x + " " + switchItem.scale.y}
                  key={switchItem._id}
                  geometry={{
                    primitive: "plane",
                    width: 1,
                    height: 1,
                  }}
                  material={{
                    color:
                      hoveredSwitchNum === switchItem._id ? "white" : "#808080",
                    opacity: hoveredSwitchNum === switchItem._id ? 1 : 0.5,
                  }}
                  events={{
                    mouseenter: () => {
                      setHoveredSwitchNum(switchItem._id);
                    },
                    mouseleave: () => {
                      setHoveredSwitchNum(null);
                    },
                  }}
                />
              );
            })}
          {/* NEEEEEEW */}
        </Scene>
      </div>

      <p style={viewSlideStyleItemAmount}>
        {view && view.items?.length > 0 ? view.items?.length : 0} Elemente
      </p>
      <p style={viewSlideStyleTitle}>
        {viewIndex + 1} / {scenario.views.length} - {view.description}
      </p>
    </div>
  );
}

export function Scenario({ scenario }) {
  useEffect(() => {
    scenario.views.map((view) => {
      return view;
    });
  }, [scenario.views]);

  const [hoveredItemNum, setHoveredItemNum] = useState(null);
  const [hoveredSwitchNum, setHoveredSwitchNum] = useState(null);

  // Everytime hoveredItemNum changes, the render function should be called again
  useEffect(() => {
    if (hoveredItemNum !== null) {
      document.getElementById(hoveredItemNum)?.emit("mouseenter");
    }
  }, [hoveredItemNum]);

  // Everytime hoveredSwitchNum changes, the render function is called again

  return (
    <Card className="mb-4">
      <Card.Body>
        <Card.Title className="d-flex justify-content-between">
          Übersicht
          <Button variant="dark" href="mailto:kontakt@simulern.de">
            Hilfe
          </Button>
        </Card.Title>
        <Card.Text>{scenario.description}</Card.Text>
        <hr />
        {scenario.views.map((view, viewIndex) => {
          return (
            <>
              <View
                view={view}
                viewIndex={viewIndex}
                key={viewIndex}
                scenario={scenario}
                hoveredItemNum={hoveredItemNum}
                setHoveredItemNum={setHoveredItemNum}
                hoveredSwitchNum={hoveredSwitchNum}
                setHoveredSwitchNum={setHoveredSwitchNum}
              />
              <ViewTable
                view={view}
                hoveredItemNum={hoveredItemNum}
                setHoveredItemNum={setHoveredItemNum}
              />

              <hr />
            </>
          );
        })}
      </Card.Body>
    </Card>
  );
}
