import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import Search from "./Search";
import {
  readPublicSimulations,
  readSimulationById,
  updateSimulation,
} from "../../services/firebase/api_old";
import { UserContext } from "../../contexts/UserContext";
import ImageProfile from "../shared/imageprofile/ImageProfile";
import { doc } from "firebase/firestore";
import { db } from "../../services/firebase/init";

function Explore() {
  const { user } = useContext(UserContext);

  const [simulations, setSimulations] = useState([]);
  const [filteredSimulations, setFilteredSimulations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const playerRef = doc(db, "users", user.uid);

  const handleSignIn = async (simulationId) => {
    const data = await readSimulationById(simulationId);

    if (data && !data.registered_players.includes(playerRef)) {
      await updateSimulation(data._id, {
        registered_players: [...data.registered_players, playerRef],
      });

      window.location.href = `/registrations`;
    }
  };

  useEffect(() => {
    readPublicSimulations().then((data) => {
      setSimulations(data);
    });
  }, []);

  useEffect(() => {
    if (!simulations) return;

    setFilteredSimulations(
      simulations.filter(
        (simulation) =>
          simulation.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          simulation.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      )
    );
  }, [simulations, searchTerm]);

  return (
    <Container>
      <h1>Entdecken</h1>
      Hier können Sie sich nach verfügbaren Trainings in Ihrer Organisation
      umsehen und sich für eine anmelden.
      <br />
      <br />
      <Search
        searchTerm={searchTerm}
        placeholder={"Suche"}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <br></br>
      {/* Display all simulations */}
      <Row className="gx-4 gy-1 w-100 overflow-auto align-items-stretch">
        {filteredSimulations.length === 0 && <p>Keine Trainings gefunden</p>}
        {filteredSimulations.map((simulation) => (
          <Col
            key={simulation._id}
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={3}
            className="d-flex align-items-stretch"
          >
            {" "}
            {/* Stellt sicher, dass jede Spalte die volle Höhe ihrer Zeile einnimmt */}
            <Card className="mb-3">
              {" "}
              {/* Stellt sicher, dass jede Karte die volle Breite ihrer Spalte einnimmt */}
              <Card.Img variant="top" src={simulation.scenario_photo_url} />
              <Card.Body>
                <Card.Title>{simulation.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  <ImageProfile
                    src={simulation.trainer_profile_picture}
                    alt={simulation.trainer_name}
                    size={30}
                  />
                  <br />
                  {simulation.trainer_name}
                </Card.Subtitle>
                <Card.Text>{simulation.description}</Card.Text>
                {!simulation.registered_players.includes(playerRef) && (
                  <Button
                    variant="dark"
                    onClick={() => {
                      handleSignIn(
                        simulation._id,
                        simulation.registered_players
                      );
                    }}
                  >
                    Anmelden
                  </Button>
                )}
                {simulation.registered_players.includes(playerRef) && (
                  <Button variant="dark" disabled>
                    Angemeldet
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Explore;
