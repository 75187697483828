import React from "react";
import { Modal, Button } from "react-bootstrap";

function ConfirmEndModal({ show, onHide, endPlayground, mode }) {
  let warntext = "";

  if (mode === "playground") {
    warntext = (
      <p>
        Sind Sie sicher, dass Sie die Demo-Training beenden möchten? <br />
        <br />
        Es handelt sich nur um eine Demo, aber in einem tatsächlichen Training
        würde dies auch das Ende für Ihre Teammitglieder bedeuten.
      </p>
    );
  } else {
    warntext = (
      <p>
        Sind Sie sicher, dass Sie das Training beenden möchten? <br />
        <br />
        Dies kann nicht rückgängig gemacht werden und für deine Teammitglieder
        bedeutet dies auch das Ende.
      </p>
    );
  }

  
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Demo Beenden</Modal.Title>
      </Modal.Header>

      <Modal.Body>{warntext}</Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Abbrechen
        </Button>
        <Button variant="danger" onClick={endPlayground}>
          Beenden
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmEndModal;
