import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { functions_base_url } from "../../services/firebase/init";
import {
  Badge,
  Button,
  Container,
  Form,
  InputGroup,
  Stack,
  Table,
} from "react-bootstrap";
import { readOrganizations } from "../../services/firebase/api_old";

import { useTable, useSortBy, useFilters } from "react-table";
import Loading from "../shared/Loading";
import { IconSearch } from "../../constants/Icons";

function AdminOverview() {
  const [users, setUsers] = useState([]);

  const [organizations, setOrganizations] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [deleteOption, setDeleteOption] = useState("both"); // Add this state

  // Update the delete function
  const handleDelete = async () => {
    setIsLoading(true);
    selectedUsers.forEach(async (user) => {
      if (deleteOption === "auth" || deleteOption === "both") {
        await axios.post(`${functions_base_url}/deleteUserFromAuth`, {
          id: user.uid,
        })
      }
      if (deleteOption === "firestore" || deleteOption === "both") {
        await axios.post(`${functions_base_url}/deleteUserFromFirestore`, {
          id: user.id,
        })
      }
    });
  };

  const fetchUsers = async () => {
    setIsLoading(true);
    let response = await axios.get(`${functions_base_url}/getUsers`);
    response = response.data.data;
    console.log(response);

    // Merge auth and firestore users, join where possible but dont leave out any that are not in both
    let users = [];
    response.firestoreUsers.forEach((user) => {
      users.push({
        ...user,
        ...response.authUsers.find((u) => u.uid === user.id),
      });
    });

    response.authUsers.forEach((user) => {
      if (!users.find((u) => u.uid === user.uid)) {
        users.push({
          ...user,
          ...response.firestoreUsers.find((u) => u.id === user.uid),
        });
      }
    });

    setUsers(users);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchOrganizations = async () => {
      const response = await readOrganizations();
      let organizations = {};
      response.forEach((org) => {
        organizations[org.id] = org;
      });

      setOrganizations(organizations);
    };

    fetchOrganizations();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: (data) => data.id?.substring(0, 3) + "...",
      },
      {
        Header: "UID",
        accessor: (data) => data.uid?.substring(0, 3) + "...",
      },
      {
        Header: "E-Mail",
        accessor: "email",
        filterable: true,
      },
      {
        Header: "Organisation",
        accessor: "organization",
        Cell: ({ value }) => organizations[value]?.name,
        filterable: true,
      },
      {
        Header: "Typ",
        accessor: "type",
        filterable: true,
      },
      {
        Header: "Alias",
        accessor: "first_name",
        filterable: true,
      },
      
      {
        Header: "Beruf/Studium",
        accessor: "occupation",
        filterable: true,
      },
    ],
    [organizations]
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: users, defaultColumn }, useFilters, useSortBy);

  return (
    <>
      <div
        id="overview-loading-spinner"
        className={
          "d-flex d-" +
          (isLoading ? "flex" : "none") +
          " justify-content-center align-items-center vh-100"
        }
      >
        <Loading />
      </div>

      <Container className="mt-3" style={{ display: isLoading ? "none" : "" }}>
        <h1>Admin Übersicht</h1>
        <Badge bg="danger" className="mb-3">
          Achtung: Das löschen von Nutzern kann nicht rückgängig gemacht werden!
        </Badge>

        <Stack direction="horizontal" gap={3} className="mb-3">
          <Form.Check
            inline
            label="Auth"
            name="deleteOption"
            type="radio"
            id="auth"
            value="auth"
            checked={deleteOption === "auth"}
            onChange={(e) => setDeleteOption(e.target.value)}
          />
          <Form.Check
            inline
            label="Firestore"
            name="deleteOption"
            type="radio"
            id="firestore"
            value="firestore"
            checked={deleteOption === "firestore"}
            onChange={(e) => setDeleteOption(e.target.value)}
          />
          <Form.Check
            inline
            label="Beide"
            name="deleteOption"
            type="radio"
            id="both"
            value="both"
            checked={deleteOption === "both"}
            onChange={(e) => setDeleteOption(e.target.value)}
          />
          <Button variant="dark" onClick={handleDelete}>
            Benutzer löschen
          </Button>
        </Stack>

        <Table {...getTableProps()} striped bordered hover responsive>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}{" "}
                    {column.isSorted ? (column.isSortedDesc ? "🔽" : "🔼") : ""}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
                <th>Auswahl</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                  <td>
                    <Form.Check
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedUsers([...selectedUsers, row.original]);
                        } else {
                          setSelectedUsers(
                            selectedUsers.filter(
                              (user) => user.id !== row.original.id
                            )
                          );
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button variant="warning" onClick={fetchUsers} className="mb-3">
          Laden
        </Button>
      </Container>
    </>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <InputGroup>
      <InputGroup.Text>
        <IconSearch />
      </InputGroup.Text>
      <Form.Control
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={"..."}
      />
    </InputGroup>
  );
}

export default AdminOverview;
