import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Image } from "react-bootstrap";
import logo from "../../media/logo-transparent-background.png";
import { resetPassword } from "../../services/firebase/api_old";





function FormResetPassword() {

  const [isReset, setIsReset] = useState(false);

  // Zustand für die Formulareingaben
  const [email, setEmail] = useState("");

  // Funktion, die aufgerufen wird, wenn das Formular abgeschickt wird
  const handleSubmit = async (event) => {
    event.preventDefault();
    const success = await resetPassword(email);
    if (success) {
      setIsReset(true);
    }
  };

  // Formular, das angezeigt wird, wenn der Benutzer nicht eingeloggt ist
  return (
    <Container className="min-vh-100 d-flex align-items-center justify-content-center">
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-center mx-auto w-25">
            <Image src={logo} fluid />
          </div>
          <div className="text-center">
            <h1>Passwort zurücksetzen</h1>
          </div>
          {isReset ? (
            <div className="text-center">
              <small className="text-muted">
                Wir haben ihnen eine E-Mail mit einem Link zum Zurücksetzen
                ihres Passworts gesendet.
              </small>
              <br />
              <Button
                variant="dark"
                className="mt-4"
                onClick={() => {
                  window.location.href = "/login";
                }}
              >
                Zurück zum Login
              </Button>
            </div>
          ) : (
            <>
              <div className="text-center">
                <small className="text-muted">
                  Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort
                  zurückzusetzen.
                </small>
              </div>
              <Form onSubmit={handleSubmit} className="w-50 mx-auto">
                <Form.Group controlId="formBasicUsername" className="mb-2 mt-4">
                  <Form.Control
                    type="text"
                    placeholder="E-Mail-Adresse"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Button variant="dark" type="submit" className="w-100">
                  Passwort zurücksetzen
                </Button>

                <div className="text-center mt-4">
                  <small className="text-muted">
                    Wir schicken Ihnen einen Link per E-Mail, mit dem Sie Ihr
                    Passwort zurücksetzen können.
                  </small>
                </div>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default FormResetPassword;
