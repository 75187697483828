import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { Entity, Scene } from "aframe-react";
import {
  addDoc,
  collection,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../../services/firebase/init";
import { toast } from "react-toastify";

const createNewItem = async (item) => {
  const itemData = {
    title: item.title,
    solution: item.solution,
    photo_url: item.photo_url,
    isDanger: item.isDanger === "true",
  };

  await addDoc(collection(db, "items"), itemData).then((docRef) => docRef);

  toast.success("Generisches Item erfolgreich erstellt");
};

const addItemRefWithCoordinatesToView = async (item) => {
  const itemData = {
    path: doc(db, "items", item.id),
    position: item.position,
    rotation: item.rotation,
    scale: item.scale,
  };

  const viewRef = doc(db, "views", item.view);
  await updateDoc(viewRef, {
    items: arrayUnion(itemData),
  });

  toast.success("Item Position erfolgreich zu View hinzugefügt");
};

const EntityComponent = ({ primitive, rotation, ...props }) => {
  if (!rotation) return null; // return null if rotation is undefined

  const rotationString = `${rotation.x} ${rotation.y} ${rotation.z}`;

  return <Entity primitive={primitive} rotation={rotationString} {...props} />;
};

const EntityGroup = () => (
  <Entity>
    {["#FF0000", "#00FF00", "#0000FF"].map((color, index) => (
      <EntityComponent
        key={index}
        primitive="a-cylinder"
        rotation={{ x: index * 90, y: 0, z: index * 90 }}
        radius="0.01"
        height="20"
        color={color}
      />
    ))}
  </Entity>
);

const Room = ({ view, item, hoveredItemNum, setHoveredItemNum }) => {
  if (!item?.rotation || !item?.position || !item?.scale) return null;

  return (
    <div style={{ width: "100%", height: "700px", position: "relative" }}>
      <Scene cursor="rayOrigin: mouse" vr-mode-ui="enabled: false" embedded>
        <EntityComponent
          primitive="a-camera"
          position="0 1.6 0"
          wasd-controls="enabled: false"
        />
        <Entity primitive="a-sky" src={view?.photo_url} />
        <EntityComponent
          rotation={item.rotation}
          position={item.position}
          scale={item.scale}
          index={0}
          geometry={{ primitive: "plane", width: 1, height: 1 }}
          material={{
            color: hoveredItemNum === 0 ? "#EF2D5E" : "#4CC3D9",
            opacity: 0.5,
          }}
          events={{
            mouseenter: () => setHoveredItemNum(0),
            mouseleave: () => setHoveredItemNum(-1),
          }}
        />
        <EntityGroup />
      </Scene>
    </div>
  );
};

const FormControlComponent = ({ id, label, value, onChange, setView, onFocus }) => {
  return (
    <InputGroup
      controlId={`form${id}`}
      style={{ marginBottom: "10px", width: "100%" }}
    >
      <InputGroup.Text>{label}</InputGroup.Text>
      <Form.Control type="text" value={value || ""} onChange={onChange} onFocus={onFocus} />
    </InputGroup>
  );
};

const ConfiguratorForm = ({ view, setView, item, setItem, setActiveDimension, setActiveAttribute }) => {
  const [mode, setMode] = useState("createGenericItem");
  return (
    <>
      <Container>
        <Form.Switch
          id="mode"
          label={"Funktion:" + mode}
          onChange={(e) => {
            if (e.target.checked) {
              setMode("addCoordinatesToView");
            } else {
              setMode("createGenericItem");
            }
          }}
        />
        <br />

        {mode === "addCoordinatesToView" && (
          <FormControlComponent
            id="View"
            label="View Foto Link"
            as="textarea"
            rows={3}
            value={view?.photo_url}
            onChange={(e) => setView({ photo_url: e.target.value })}
          />
        )}
        <hr />
      </Container>

      <Container className="my-3">
        <Row>
          <Col md={6}>
            {mode === "addCoordinatesToView" && (
              <Container>
                {["Position", "Rotation", "Scale"].map((type) => (
                  <Row key={type}>
                    <Col md={2}>{type}</Col>
                    {["x", "y", "z"].map((dim) => {
                      if (type === "Scale" && dim === "z") return null;
                      return (
                        <Col md={3} key={`${type}${dim}`}>
                          <FormControlComponent
                            id={`${type}-${dim}`}
                            label={dim}
                            value={item?.[type.toLowerCase()]?.[dim]}
                            onChange={(e) =>
                              setItem((prevItem) => ({
                                ...prevItem,
                                [type.toLowerCase()]: {
                                  ...prevItem[type.toLowerCase()],
                                  [dim]: e.target.value,
                                },
                              }))
                            }
                            onFocus={() => {
                              setActiveDimension(dim)
                              setActiveAttribute(type.toLowerCase())

                            }}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ))}
              </Container>
            )}
          </Col>

          {mode === "createGenericItem" && (
            <Col md={6}>
              <FormControlComponent
                id="title"
                label="Titel"
                onChange={(e) =>
                  setItem((prevItem) => ({
                    ...prevItem,
                    title: e.target.value,
                  }))
                }
                value={item?.title}
              />
              <FormControlComponent
                id="solution"
                label="Lösung"
                onChange={(e) =>
                  setItem((prevItem) => ({
                    ...prevItem,
                    solution: e.target.value,
                  }))
                }
                value={item?.solution}
              />
              <FormControlComponent
                id="photo_url"
                label="Foto Link"
                onChange={(e) =>
                  setItem((prevItem) => ({
                    ...prevItem,
                    photo_url: e.target.value,
                  }))
                }
                value={item?.photo_url}
              />

              <FormControlComponent
                id="isDanger"
                label="Gefährlich"
                onChange={(e) =>
                  setItem((prevItem) => ({
                    ...prevItem,
                    isDanger: e.target.value,
                  }))
                }
                value={item?.isDanger}
              />
            </Col>
          )}
          {mode === "addCoordinatesToView" && (
            <Col md={6}>
              <FormControlComponent
                id="id"
                label="Item ID"
                onChange={(e) =>
                  setItem((prevItem) => ({
                    ...prevItem,
                    id: e.target.value,
                  }))
                }
                value={item?.id}
              />
              <FormControlComponent
                id="viewId"
                label="View ID"
                onChange={(e) =>
                  setItem((prevItem) => ({
                    ...prevItem,
                    view: e.target.value,
                  }))
                }
                value={item?.view}
              />
            </Col>
          )}
        </Row>
      </Container>
      <Container className="mb-3">
        <Stack gap={2} direction="horizontal">
          <Button
            variant="danger"
            onClick={() => {
              sessionStorage.clear();
              window.location.reload();
            }}
          >
            Zurücksetzen
          </Button>
          <Button
            variant="success"
            onClick={() =>
              mode === "createGenericItem"
                ? createNewItem(item)
                : addItemRefWithCoordinatesToView(item)
            }
          >
            Absenden
          </Button>
        </Stack>
      </Container>
    </>
  );
};

const Configurator = () => {



  const getDefaultView = () =>
    JSON.parse(sessionStorage.getItem("view")) || {
      photo_url:
        "https://firebasestorage.googleapis.com/v0/b/simulearn-801f6.appspot.com/o/view_photos%2FcobCcxntFBxwItOW3KoG%2Fpatientenzimmer.jpeg?alt=media&token=d9ff32c8-0aab-4c9e-81fe-6db552ff2e08&_gl=1*1hwo6w1*_ga*MTIxMjk3NDA1Ny4xNjg5Nzg0Mzc1*_ga_CW55HF8NVT*MTY5NzY2NjM0MC4xMjUuMS4xNjk3NjY2MzQ4LjUyLjAuMA..",
    };
  const getDefaultItem = () =>
    JSON.parse(sessionStorage.getItem("item")) || {
      position: { x: -0.27, y: 1.68, z: -1.25 },
      rotation: { x: 0, y: 0, z: -10 },
      scale: { x: 1, y: 1 },
    };

  const [view, setView] = useState(getDefaultView);
  const [item, setItem] = useState(getDefaultItem);
  const [hoveredItemNum, setHoveredItemNum] = useState(-1);
  const [activeDimension, setActiveDimension] = useState(null);
  const [activeAttribute, setActiveAttribute] = useState(null);

  // Function to adjust the dimension values
  const adjustDimensionValue = (attribute, dimension, direction) => {
    console.log("Would change", attribute, dimension, direction);
    console.log(item);
    setItem((prevItem) => {
      const newValue = parseFloat(prevItem[attribute][dimension]) + (direction === 'up' ? 1 : -1);
      return { ...prevItem, [attribute]: { ...prevItem[attribute], [dimension]: newValue } };
    });
  };

  // Keydown event handler
  const handleKeyDown = (e) => {
    console.log(e);
    if (activeDimension && (e.key === 'ArrowUp' || e.key === 'ArrowDown')) {
      adjustDimensionValue(activeAttribute, activeDimension, e.key === 'ArrowUp' ? 'up' : 'down');
    }
  };

  // Attach event listener
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDimension]);

  useEffect(() => {
    sessionStorage.setItem("view", JSON.stringify(view));
    sessionStorage.setItem("item", JSON.stringify(item));
  }, [view, item]);





  return (
    <>
      <ConfiguratorForm
        setActiveAttribute={setActiveAttribute}
        setActiveDimension={setActiveDimension}
        view={view}
        setView={setView}
        item={item}
        setItem={setItem}
      />

      <Room
        view={view}
        item={item}
        hoveredItemNum={hoveredItemNum}
        setHoveredItemNum={setHoveredItemNum}
      />
    </>
  );
};

export default Configurator;
