import { toast } from "react-toastify";
import { createTeam, deleteTeam } from "../../../services/firebase/api_old";
import { doc } from "firebase/firestore";
import { db } from "../../../services/firebase/init";

export function onDragEnd(
  playerPool,
  setPlayerPool,
  state,
  setState,
  setIsChanged,
  result
) {
  const { source, destination } = result;

  // One of the possible reasons for this can be when the dragged item is released outside of any droppable.
  if (!destination) {
    return;
  }

  if (source.droppableId === destination.droppableId) {
    // If the source = destination, then we are reordering the items
    if (source.droppableId === "playerPool") {
      const newPlayerPool = reorder(
        playerPool,
        source.index,
        destination.index
      );
      setPlayerPool(newPlayerPool);
    } else {
      const items = reorder(
        state.find((team) => team.name === source.droppableId).players,
        source.index,
        destination.index
      );
      const newState = [...state];
      newState.find((team) => team.name === source.droppableId).players = items;
      setState(newState);
    }
  } else {
    // If the source and destination are different, then we are moving the items
    if (source.droppableId === "playerPool") {
      const result = movePlayer(
        playerPool,
        state.find((team) => team.name === destination.droppableId).players,
        source,
        destination
      );
      setPlayerPool(result["playerPool"]);
      const newState = [...state];
      newState.find((team) => team.name === destination.droppableId).players =
        result[destination.droppableId];
      setState(newState);
    } else if (destination.droppableId === "playerPool") {
      const player = state
        .find((team) => team.name === source.droppableId)
        .players.splice(source.index, 1)[0];
      const newPlayerPool = [...playerPool];
      newPlayerPool.splice(destination.index, 0, player);
      setPlayerPool(newPlayerPool);
      const newState = [...state];
      setState(newState);
    } else {
      const result = movePlayer(
        state.find((team) => team.name === source.droppableId).players,
        state.find((team) => team.name === destination.droppableId).players,
        source,
        destination
      );
      const newState = [...state];
      newState.find((team) => team.name === source.droppableId).players =
        result[source.droppableId];
      newState.find((team) => team.name === destination.droppableId).players =
        result[destination.droppableId];
      setState(newState);
    }
  }

  setIsChanged(true);
}

// export function to distribute players across teams
export const distributePlayers = (
  playerPool,
  state,
  setPlayerPool,
  setState,
  setIsChanged
) => {
  let newPlayerPool = [...playerPool];
  let newState = [...state];

  for (let i = 0; i < newPlayerPool.length; i++) {
    newState[i % newState.length].players.push(newPlayerPool[i]);
  }

  newPlayerPool = [];

  setPlayerPool(newPlayerPool);
  setState(newState);
  setIsChanged(true);
};

export const removeTeam =
  (setState, state, setIsChanged) => (teamIdToRemove) => {
    setState(
      state.filter((team, teamId) => teamId !== parseInt(teamIdToRemove))
    );
    setIsChanged(true);
  };

export const saveTeams = async (
  simulationId,
  simulation,
  state,
  setIsChanged
) => {
  // For every teamref in the teams array in the simulation document, delete it
  if (simulation.teams && simulation.teams?.length > 0) {
    for (const teamRef of simulation.teams) {
      try {
        deleteTeam(teamRef, simulationId);
      } catch (error) {
        console.log("Error deleting team: ", error);
      }
    }
  } else {
    console.log("No teams to delete");
  }

  // Call "createTeam" for every team in the state and pass simulationId and teamData
  for (const team of state) {
    // Create array of firebase doc refs to players
    const playerRefs = team.players.map((player) => {
      return doc(db, "users", player._id);
    });

    await createTeam(simulationId, {
      name: team.name,
      players: playerRefs,
      notes: {},
    });
  }

  setIsChanged(false);
  toast.success("Team-Verteilung gespeichert.");
};

// export function to reset teams and put all players back in the playerPool
export const resetTeams = (
  originalPlayers,
  setPlayerPool,
  state,
  setState,
  setIsChanged
) => {
  let newState = state.map((team) => ({
    ...team,
    players: [],
  }));
  setState(newState);

  // Use original players when resetting
  setPlayerPool([...originalPlayers]);

  setIsChanged(true);
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const movePlayer = (
  source,
  destination,
  droppableSource,
  droppableDestination
) => {
  console.log(
    "movePlayer: ",
    source,
    destination,
    droppableSource,
    droppableDestination
  );
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const getItemStyle = (isDragging, draggableStyle) => {
  const style = {
    ...draggableStyle,
    userSelect: "none",
    padding: 8,
    margin: `0 0 8px 0`,
    ...(isDragging && {
      width: "300px",
      textDecoration: "underline",
      backgroundColor: "black",
    }),
  };

  return style;
};

export const getListStyle = (isDraggingOver) => ({
  padding: 8,
  width: "100%",
  borderRadius: "15px",
  backgroundColor: isDraggingOver ? "#EEEEEE" : "",
});

export const getUniqueTeamName = (currentTeamNames) => {
  const available = [
    "Die Scrubs",
    "Die T-Killer",
    "Die Interventoren",
    "Die Diagnostiker",
    "Die Zellenzauberer",
    "Die Membranheiler",
    "Die Mikroskop-Meister",
    "Die Heilpraktiker",
    "Die Patientenpatrone",
    "Die Keimstaffel",
    "Die Dogtors",
    "Die Rotatoren",
    "Die Glycoolysen",
    "Die Samariter",
    "Die Ärzte",
    "Die Röntgen-Retter",
    "Die Analysatoren",
    "Die Mutation-Meister",
    "Die Körper-Konsultanten",
  ];

  let newName;
  for (let i = 0; i < available.length; i++) {
    if (!currentTeamNames.includes(available[i])) {
      newName = available[i];
      break;
    }
  }

  if (!newName) {
    let newTeamCount = currentTeamNames.filter((name) =>
      name.includes("Neues Team")
    ).length;

    newName =
      newTeamCount === 0 ? "Neues Team" : `Neues Team ${newTeamCount + 1}`;
  }

  return newName;
};
