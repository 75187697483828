// useTeams.js
import { useState, useEffect } from "react";
import { deleteTeam, readOrganization, readTeam, readUser } from "../services/firebase/api_old";

export default function useTeams(simulation) {
  const [state, setState] = useState([]);
  const [playerPool, setPlayerPool] = useState([]);
  const [originalPlayers, setOriginalPlayers] = useState([]);

  useEffect(() => {
    if (simulation) {
      console.log("useTeams got triggered: ", simulation);
      console.log(
        "Current teams state: ",
        state.map((team) => team.id)
      );
      console.log(
        "Simulation teams state (will be tried to read): ",
        simulation.teams?.map((team) => team.id)
      );

      const fetchPlayers = async () => {
        // Check if there is undefined data
        if (!simulation.teams && !simulation.registered_players) {
          return;
        }

        const playersPromises = simulation.registered_players.map((playerRef) =>
          readUser(playerRef.id).then((res) => {
            return { ...res, _id: playerRef.id };
          })
        );

        const players = await Promise.all(playersPromises);

        // For each player, read organization name
        const playersWithOrgPromises = players.map((player) =>
          readOrganization(player.organization).then((res) => {
            return { ...player, organizationName: res.name };
          })
        );

        const playersWithOrg = await Promise.all(playersWithOrgPromises); // wait for all promises to resolve

        const teamPromises = simulation.teams?.map(async (teamRef) => {
          const team = await readTeam(teamRef);
          if (!team) {
            console.log("Team not found: ", teamRef.id);
            deleteTeam(teamRef, simulation._id);
            return null; // Return null or any other default value
          }

          return {
            name: team.name,
            players: team.players,
            id: teamRef.id,
          };
        });

        let teams = [];
        if (teamPromises) {
          teams = (await Promise.all(teamPromises)).filter(Boolean); // filter out null or any default values
        }

        // Filter out players from the player pool that are already in a team
        const playerPool = playersWithOrg.filter(
          (player) =>
            !teams.some((team) =>
              team.players.some((teamPlayer) => teamPlayer._id === player._id)
            )
        );

        // Create new state
        const newState = [...teams];

        setPlayerPool(playerPool);
        setOriginalPlayers([...playersWithOrg]);

        // Update state
        setState(newState);
      };

      fetchPlayers();
    }
    // eslint-disable-next-line
  }, [simulation]);

  return { state, playerPool, setPlayerPool, originalPlayers, setState };
}
