import {
    AiOutlineSearch,
    AiOutlineEyeInvisible,
    AiOutlineCopy,
    AiOutlineDelete,
    AiOutlineEdit,
    AiOutlineTeam,
    AiOutlinePlus,
    AiOutlineEye,
    AiOutlineCalendar,
    AiOutlineClockCircle,
    AiOutlineInfoCircle,
  } from "react-icons/ai";
  import {
    BsSafe,
    BsCheck,
    BsMagic,
    BsCardText,
    BsChevronCompactDown,
    BsChevronCompactUp,
  } from "react-icons/bs";
  import { IoGameControllerOutline } from "react-icons/io5";
  import { IoMdBuild } from "react-icons/io";
  import { FaPlay } from "react-icons/fa";
  import { IoArrowBackOutline } from "react-icons/io5";
  import { Tb360View } from "react-icons/tb";
  import { GiTeacher } from "react-icons/gi";
  import { MdDevices } from "react-icons/md";
  
  // Debug Start VSCode Icon
  export const IconSearch = () => {
    return <AiOutlineSearch />;
  };
  export const IconEyeInvisible = () => {
    return <AiOutlineEyeInvisible />;
  };
  
  export const IconEye = () => {
    return <AiOutlineEye />;
  };
  
  export const IconCopy = () => {
    return <AiOutlineCopy />;
  };
  
  export const IconDelete = () => {
    return <AiOutlineDelete />;
  };
  
  export const IconEdit = () => {
    return <AiOutlineEdit />;
  };
  
  export const IconTeam = () => {
    return <AiOutlineTeam />;
  };
  
  export const IconSafe = () => {
    return <BsSafe />;
  };
  
  export const IconCheck = () => {
    return <BsCheck />;
  };
  
  export const IconMagic = () => {
    return <BsMagic />;
  };
  
  export const IconGame = () => {
    return <IoGameControllerOutline />;
  };
  
  export const IconPlus = () => {
    return <AiOutlinePlus />;
  };
  
  export const IconStart = () => {
    return <FaPlay />;
  };
  
  export const IconInfo = () => {
    return <AiOutlineInfoCircle />;
  };
  
  export const IconTime = () => {
    return <AiOutlineClockCircle />;
  };
  
  export const IconCalendar = () => {
    return <AiOutlineCalendar />;
  };
  
  export const IconText = () => {
    return <BsCardText />;
  };
  
  export const IconConfig = () => {
    return <IoMdBuild />;
  };
  
  export const IconDown = () => {
    return <BsChevronCompactDown />;
  };
  
  export const IconUp = () => {
    return <BsChevronCompactUp />;
  };
  
  export const IconBack = () => {
    return <IoArrowBackOutline />;
  };
  
  export const Icon360 = () => {
    return <Tb360View />;
  };
  
  export const IconTeaching = () => {
    return <GiTeacher />;
  };
  
  export const IconMobile = () => {
    return <MdDevices />;
  };
  