import React, { useContext, useEffect, useRef, useState } from "react";
import {
  readSimulationById,
  readSimulationsByPlayerRef,
  readTeam,
  updateSimulation,
  handleSignIn,
  readOrganization,
} from "../../services/firebase/api_old";
import { readUser } from "../../services/firebase/api_old";
import { UserContext } from "../../contexts/UserContext";
import { Container, Card, Button, Row, Col, Alert } from "react-bootstrap";
import ImageProfile from "../shared/imageprofile/ImageProfile";
import { toast } from "react-toastify";
import { doc } from "firebase/firestore";
import Search from "./Search";
import { db } from "../../services/firebase/init";
import TeamTable from "../shared/TeamTable";

function Registrations() {
  const { user } = useContext(UserContext);

  const [registrations, setRegistrations] = useState([]);
  const [trainerOrganizations, setTrainerOrganizations] = useState([]);

  const playerRef = useRef(doc(db, "users", user.uid));

  useEffect(() => {
    const observer = {
      next: async (querySnapshot) => {
        const data = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const registration = doc.data();

            let trainer;
            if (registration.trainer) {
              trainer = await readUser(registration.trainer.id);
              registration.trainer = trainer;
            }

            let assigned = false;
            let assignedTeam = {};

            // Access document id with doc.id
            registration._id = doc.id;

            if (registration.teams) {
              for (const team of registration.teams) {
                const teamData = await readTeam(team);
                console.log(teamData);
                if (!teamData) {
                  continue;
                }

                const playerIndex = teamData.players.findIndex(
                  (player) => player._id === playerRef.current.id
                );
                if (playerIndex !== -1) {
                  assigned = true;
                  assignedTeam = {
                    name: teamData.name,
                    players: teamData.players,
                    ended: teamData.ended,
                  };
                }
              }
            }

            return {
              ...registration,
              isAssigned: assigned,
              assignedTeam,
            };
          })
        );

        setRegistrations(data);
      },
      error: (error) => console.error(error),
    };

    const unsubscribe = readSimulationsByPlayerRef(playerRef.current, observer);

    // Cleanup function
    return () => unsubscribe();
  }, [playerRef]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const trainerOrganizations = await Promise.all(
        registrations.map((registration) =>
          readOrganization(registration.trainer.organization).then(
            (orgData) => orgData.name
          )
        )
      );

      setTrainerOrganizations(trainerOrganizations);
    };

    fetchOrganizations();
  }, [registrations]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const key = urlParams.get("code");
    console.log(key);
    handleSignIn(key, playerRef.current);
  }, []);

  const handleSignOut = async (simulationId) => {
    const data = await readSimulationById(simulationId);
    let isRegistered = false;
    data.registered_players?.forEach((player) => {
      if (player.id === playerRef.current.id) {
        isRegistered = true;
      }
    });
    if (isRegistered) {
      await updateSimulation(data._id, {
        registered_players: data.registered_players.filter(
          (player) => player.id !== playerRef.current.id
        ),
      });
      toast.success("Sie haben sich erfolgreich abgemeldet.");
      setRegistrations(
        registrations.filter((registration) => registration._id !== data._id)
      );
    } else {
      toast.error("Sie haben sich noch nicht für dieses Training angemeldet.");
    }
  };

  if (!registrations || registrations.length === 0) {
    return (
      <Container>
        <h1>Meine Anmeldungen</h1>
        <p>
          Sie haben sich noch für kein Training angemeldet. <br />
        </p>
        <Search
          placeholder="Code manuell eingeben"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          searchTerm={searchTerm}
          showButton={true}
          onButtonClick={() => {
            handleSignIn(searchTerm, playerRef.current);
          }}
        />
      </Container>
    );
  }

  return (
    <Container className="mb-5">
      <h1>Meine Anmeldungen</h1>
      Hier finden Sie alle Trainings, für die Sie sich angemeldet haben.
      <br />
      <br />
      <Search
        placeholder="Code manuell eingeben"
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        searchTerm={searchTerm}
        showButton={true}
        onButtonClick={() => {
          handleSignIn(searchTerm, playerRef.current);
        }}
      />
      <br />
      <Row className="gx-4 gy-1 w-100 overflow-auto align-items-stretch">
        {registrations.map((registration, index) => {
          return (
            <Col
              key={index}
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              className="d-flex align-items-stretch"
            >
              <Card className="mb-3 w-100 d-flex flex-column">
                <Card.Img variant="top" src={registration.scenario_photo_url} />
                <Card.Body className="flex-grow-1">
                  <Card.Title>{registration.title}</Card.Title>
                  <Card.Text>
                    <div className="d-flex justify-content-between">
                      <span className="text-muted mr-2">
                        {registration.trainer.first_name +
                          " " +
                          registration.trainer.last_name +
                          " (" +
                          trainerOrganizations[index] +
                          ")"}
                      </span>


                      <ImageProfile
                        src={registration.trainer.profile_picture}
                        size={30}
                      />
                    </div>
                    <br />
                    <p className="text-muted">{registration.trainer_name}</p>
                    {registration.description} <br /> <br />
                    {registration.assignedTeam?.ended ? (
                      <Alert variant="warning">
                        Jemand aus Ihrem Team hat das Training bereits für ihr
                        Team beendet.
                      </Alert>
                    ) : registration.ended ? (
                      <Alert variant="warning">
                        Das Training ist bereits vorbei.
                      </Alert>
                    ) : registration.isAssigned ? (
                      <>
                        <Alert variant="success">
                          Sie wurden dem Team{" "}
                          <span className="text-success">
                            {" "}
                            {registration.assignedTeam.name}{" "}
                          </span>
                          zugewiesen.
                        </Alert>
                        <TeamTable team={registration.assignedTeam} />
                      </>
                    ) : (
                      <Alert variant="warning">
                        Sie wurden noch keinem Team zugewiesen.
                      </Alert>
                    )}
                  </Card.Text>
                </Card.Body>
                {registration.ended ||
                  registration.assignedTeam.ended ? null : (
                  <Card.Footer>
                    <Button
                      variant="dark"
                      onClick={() => {
                        handleSignOut(registration._id);
                      }}
                    >
                      Abmelden
                    </Button>
                    <Button
                      variant="success"
                      className="float-end"
                      disabled={!registration.isAssigned || registration.ended}
                      onClick={() => {
                        sessionStorage.setItem(
                          "simulationId",
                          registration._id
                        );
                        window.location.href = "/waitingroom";
                      }}
                    >
                      Beitreten
                    </Button>
                  </Card.Footer>
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Registrations;
