import { useState, useEffect } from "react";
import { onSnapshot } from "firebase/firestore";

function useTeamsForNotes(teamRefs) {
  const [teams, setTeams] = useState({});

  useEffect(() => {
    if (teamRefs?.length === 0 || !teamRefs) return;

    const unsubscribes = teamRefs.map((team) =>
      onSnapshot(team, (docSnap) => {
        if (docSnap.exists()) {
          setTeams((prev) => ({
            ...prev,
            [docSnap.id]: { ...docSnap.data(), _id: docSnap.id },
          }));
        }
      })
    );

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [teamRefs]);

  return teams;
}

export default useTeamsForNotes;
