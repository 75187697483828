export const ABOUT_PAGE_TEXT_INTERPROFESSIONAL = (
  <p>
    <p>
      Einer der Schlüsselaspekte von SimuLern ist die{" "}
      <b>interprofessionelle Zusammenarbeit</b> und das gemeinsame Lernen.
    </p>
    <p>
      Die TeilnehmerInnen müssen zusammenarbeiten, um Gefahren zu identifizieren
      und Lösungen für diese Probleme zu finden. Sie teilen ihre Beobachtungen,
      diskutieren ihre Ideen und arbeiten als Team zusammen, um gemeinsam die
      Situation zu bewältigen.
    </p>
  </p>
);

export const ABOUT_PAGE_TEXT_360 = (
  <p>
    Die 360-Grad-Ansicht ermöglicht es Ihnen, sich in einem digitalen Raum
    umzuschauen, als ob Sie sich tatsächlich darin befinden würden. Dies sorgt
    für ein immersives und realistisches Erlebnis, welches das Identifizieren
    von Risiken zu einer spannenden und herausfordernden Aufgabe macht.
  </p>
);

export const ABOUT_PAGE_TEXT_DEBRIEFING = (
  <p>
    Mithilfe eines realitätsnahen, virtuellen Trainings wird gezielt das
    Situationsbewusstsein von Gefährdungen der Patientensicherheit trainiert und
    durch gemeinsames, anwendungsunterstütztes Debriefing nachhaltig fundiert.
  </p>
);

export const ABOUT_PAGE_TEXT_FLEXIBILITY = (
  <p>
    SimuLern ist eine webbasierte Anwendung, die auf jedem Gerät funktioniert.
    Sie können SimuLern auf Ihrem Computer, Tablet oder Smartphone verwenden.
    Dank der ortsunabhängigen Zugänglichkeit bietet SimuLern maximale
    Flexibilität - trainieren oder lernen Sie im digitalen Raum, egal wo Sie
    sich befinden. Ganz ohne VR- oder AR-Brillen.
  </p>
);

export const MORE_INFOS_SIMULERN = (
  <p>
    <p>
      SimuLern ist eine interaktive Plattform und dient als zentraler Hub sowohl
      für TrainerInnen, als auch TeilnehmerInnen. Für TrainerInnen fungiert
      SimuLern als zentraler Dreh- und Angelpunkt zur einfachen Durchführung von{" "}
      <b>simulierten Szenarien</b> und anschließenden <b>Debriefings</b>.
    </p>
    <p>
      Für TeilnehmerInnen ermöglicht SimuLern ein nachhaltiges, virtuelles
      Lernerlebnis zur Stärkung des Situationsbewusstseins von Fehler- bzw.
      Gefahrenquellen und damit zur Erhöhung der Patientensicherheit.{" "}
    </p>
    <p>
      Als TrainerIn haben Sie die Möglichkeit, Ihre eigenen Trainings und
      Szenarien zu erstellen und diese mit den TeilnehmerInnen zu teilen. Sie
      können die Ergebnisse der TeilnehmerInnen analysieren und auswerten und so
      wertvolle Erkenntnisse gewinnen.
    </p>
  </p>
);

export const questions_trainer = [
  "Was ist ein Szenario?",
  "Was ist ein Training?",
  "Wie können Teilnehmende bei einem Training beitreten?",
  "Können Trainings durchgeführt werden, ohne, dass ein Trainer anwesend ist?",
  "Wo sehe ich meine Trainings?",
  "Wie kann ich ein Training bearbeiten?",
  "Ich möchte etwas an meinem Szenario ändern. Wie kann ich das tun?",
];

export const answers_trainer = [
  "Ein Szenario entspricht einem individuell konfiguriertem Raum mit verschiedenen versteckten Gefährdungen.",
  "Ein Training ist eine Übung, die in einem Szenario, also einem Raum, durchgeführt wird. TrainerInnen leiten durch ein Training während TeilnehmerInnen nach Patientengefährdungen suchen.",
  "Entweder über einen Code, einen Link oder einen QR Code. Alle diese Möglichkeiten werdenvon Ihnen mit den Teilnehmern geteilt. Sie können diese unter 'Meine Trainings' und dann durch einen Click auf den festgelegten Code einsehen.",
  "Ja, Trainings können auch ohne TrainerIn durchgeführt werden. 'Öffentliche' Trainings können jederzeit auch ohne Trainer*innen durchgeführt werden.",
  "Unter 'Meine Trainings' können Sie alle Trainings sehen, die Sie erstellt haben.",
  "Unter 'Meine Trainings' können bereits erstellte Trainings bearbeitet und neue Trainings angelegt werden.",
  "Bei Änderungswünschen an einem Szenario wenden Sie sich bitte an Ihren Ansprechpartner bei SimuLern.",
];

export const questions_player = [
  "Wie nehme ich an einem Training teil?",
  "Wo kann ich überall Gefährdungen markieren?",
  "Kann ich vor dem Start die Steuerung testen?",
  "Wo kann ich meine persönlichen Daten ändern?",
];

export const answers_player = [
  "Entweder über einen Code, einen Link oder einen QR Code. Alle diese Möglichkeiten werden Ihnen von Ihrer TrainerIn zur Verfügung gestellt.",
  "Im Training werden Sie Felder sehen, mit denen sie interagieren könne. Diese sind daran zu erkennen, dass sie grau hinterlegt sind. Wenn Sie auf ein solches Feld klicken, öffnet sich ein Fenster, in dem Sie Ihre Entdeckung und Begründung dokumentieren können.",
  "Ja, im Demo-Modus können Sie die Steuerung und den Ablauf eines Trainings testen.",
  "Ihre persönliche Daten können Sie in ihren Profileinstellungen ändern. Diese finden Sie, indem sie auf ihren Avatar oben rechts in der Ecke klicken.",
];

export const SIMULERN_MOTTO = (
  <span>
    Die interaktive Plattform zur Identifizierung von Sicherheitsrisiken und
    Gefahren in simulierten Szenarien.
  </span>
);

export const IMPRESSUM_TEXT = (
  <>
    <h4>Datenschutzerklärung</h4>
    <p className="mt-4">
      <b>Geltungsbereich und Umgang mit personenbezogenen Daten </b>
      <br />
      Diese Datenschutzerklärung informiert Nutzer über die Verarbeitung
      personenbezogener Daten bei der Nutzung dieser Website. Die gesetzlichen
      Regelungen zum Datenschutz gehen aus der europäischen
      Datenschutzgrundverordnung hervor. Das Bundesdatenschutzgesetz (BDSG) und
      das Telemediengesetz (TMG) gelten ergänzend.
    </p>
    <p className="mt-4">
      <b>Verantwortlich für diese Website ist folgender Anbieter:</b> <br />
      Daniel Seger
      <br />
      Steinbachstr. 7<br />
      66851 Queidersbach
      <br />
      daniel.seger@simulern.de
      <br />
    </p>
    <p className="mt-4">
      <b>Personenbezogene Daten</b> <br />
      Zu den personenbezogenen Daten zählen alle Informationen, die sich auf
      eine natürliche Person beziehen. Dabei kann es sich zum Beispiel um den
      Namen, eine Anschrift, ein Geburtsdatum oder eine E-Mail-Adresse handeln.
      Diese Informationen werden nur gemäß der gesetzlichen
      Datenschutzbestimmungen auf der Website erhoben und verarbeitet.
    </p>
    <p className="mt-4">
      <b>Zugriffsdaten</b> <br />
      Nutzen Sie diese Website, um sich zu informieren und stoßen kein weiteres
      Gespräch mit uns an, um sich in einem späteren Schritt zu registrieren,
      dann werden nur die Daten verarbeitet, die zur Anzeige der Website auf dem
      von Ihnen verwendeten Gerät erforderlich sind. Das sind insbesondere:
      <ul>
        <li>IP-Adresse </li>
        <li>Zeitpunkt der Anfrage</li>
        <li>Übertragene Datenmenge</li>
        <li>Quellseite</li>
        <li>Verwendeter Browser</li>
        <li>Betriebssystem</li>
      </ul>
      Rechtsgrundlage für die Verarbeitung dieser Daten sind berechtigte
      Interessen gemäß Art. 6 Abs. 1 UAbs. 1 Buchstabe f DSGVO, um die
      Darstellung der Website grundsätzlich zu ermöglichen.
    </p>
    <p className="mt-4">
      <b>
        Speicherung und Verarbeitung personenbezogener Daten bei Registrierung
      </b>
      <br />
      Bei weiterem Interesse an SimuLern können Sie sich in einem späteren
      Schritt registrieren und einen Account anlegen. Bei einer Registrierung
      werden folgende Daten von Ihnen erhoben und gespeichert:
      <ul>
        <li>Alias Name</li>
        <li>E-Mail-Adresse</li>
        <li>Beruf</li>
        <li>Zugehörige Organisation</li>
      </ul>
      Die Daten werden nur für die Verwaltung Ihres Accounts und zur
      Bereitstellung der gewünschten Dienste verwendet. Rechtsgrundlage für
      diese Verarbeitung ist Art. 6 Abs. 1 UAbs. 1 Buchstabe b DSGVO, da die
      Verarbeitung für die Erfüllung eines Vertrags oder vorvertraglicher
      Maßnahmen erforderlich ist.
    </p>
    <p className="mt-4">
      <b>Datenübermittlung an Dritte </b>
      <br />
      Ihre personenbezogenen Daten werden ohne Ihre ausdrückliche Zustimmung
      nicht an Dritte weitergegeben, es sei denn, es besteht eine gesetzliche
      Verpflichtung dazu oder es ist zur Durchsetzung unserer Rechte,
      insbesondere zur Durchsetzung von Ansprüchen aus dem Vertragsverhältnis
      mit Ihnen, erforderlich.
    </p>
    <p className="mt-4">
      <b>Datenlöschung und Speicherdauer</b>
      <br />
      Personenbezogene Daten werden gelöscht oder gesperrt, sobald der Zweck der
      Speicherung entfällt. Eine längere Speicherung kann erfolgen, wenn dies
      durch den Gesetzgeber vorgesehen wurde. Datensicherheit Wir setzen
      technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten
      gegen Manipulationen, Verlust, Zerstörung oder gegen den Zugriff
      unberechtigter Personen zu schützen. Unsere Sicherheitsmaßnahmen werden
      entsprechend der technologischen Entwicklung fortlaufend verbessert.
    </p>
    <p className="mt-4">
      <b>Google Firebase</b>
      <br />
      Unsere Website wird über Google Firebase gehostet. Google Firebase kann
      Zugriffsdaten speichern und verarbeiten. Für weitere Informationen zur
      Datenverarbeitung durch Google Firebase, verweisen wir auf deren
      Datenschutzbestimmungen. Rechte der betroffenen Person Als betroffene
      Person haben Sie folgende Rechte:
      <br />
      <ul>
        <li>
          Recht auf Auskunft über die personenbezogenen Daten, die verarbeitet
          werden (Art. 15 DSGVO)
        </li>
        <li>
          Recht auf Berichtigung der Sie betreffenden Daten, wenn diese
          unrichtig oder unvollständig gespeichert werden (Art. 16 DSGVO)
        </li>
        <li>Recht auf Löschung (Art. 17 DSGVO)</li>
        <li>
          Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
          Daten zu verlangen (Art. 18 DSGVO)
        </li>
        <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
        <li>
          Widerspruchsrecht gegen die Verarbeitung Sie betreffender
          personenbezogenen Daten (Art. 21 DSGVO)
        </li>
        <li>
          Recht nicht einer ausschließlich auf einer automatisierten
          Verarbeitung beruhenden Entscheidung unterworfen zu werden, die Ihnen
          gegenüber rechtliche Wirkung entfaltet oder sie in ähnlicher Weise
          erheblich beeinträchtigt (Art. 22 DSGVO)
        </li>
        <li>
          Recht, sich bei einem vermuteten Verstoß gegen das Datenschutzrecht
          bei der zuständigen Aufsichtsbehörde zu beschweren (Art. 77 DSGVO)
        </li>
      </ul>
    </p>
    <p className="mt-4">
      <b>Cookies</b>
      <br /> Die aktuelle Website von SimuLern arbeitet gänzlich ohne Cookies.
    </p>
    <p className="mt-4">
      <b>Änderung der Datenschutzerklärung</b>
      <br />
      Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
      stets den aktuellen rechtlichen Anforderungen entspricht, oder um
      Änderungen unserer Dienste in der Datenschutzerklärung umzusetzen. Bei
      Ihrem erneuten Besuch gilt dann die neue Datenschutzerklärung.
    </p>
    <p className="mt-4">
      <b>Kontaktmöglichkeit bezüglich Datenschutz</b>
      <br />
      Bei Fragen zum Datenschutz können Sie sich jederzeit an den oben
      angegebenen Verantwortlichen wenden. Wenn Sie Fragen zur Erhebung,
      Verarbeitung oder Nutzung Ihrer personenbezogenen Daten haben,
      Informationen benötigen, Daten berichtigen, sperren oder löschen lassen
      möchten oder eine erteilte Einwilligung widerrufen möchten, wenden Sie
      sich bitte direkt an uns.
    </p>
  </>
);

export const HOME_PAGE_PLAYER_BRIEFING = (
  <p>
    <p>
      Wir freuen uns, Sie bei SimuLern begrüßen zu dürfen, der interaktiven
      Trainingsplattform für die Identifizierung von Sicherheitsrisiken und
      Gefahren in simulierten Szenarien.{" "}
    </p>
    <p>
      Hier können Sie Ihr Wissen und Ihre Fähigkeiten auf die Probe stellen und
      dabei Spaß haben. Im Training werden Sie in eine realitätsnahe{" "}
      <b>360-Grad-Umgebung</b> versetzt, welche Ihre TrainerInnen für Sie
      ausgewählt haben.
    </p>
    <p>
      Ihre Aufgabe besteht darin, mögliche Gefahren und Sicherheitsrisiken in
      einem virtuellen Raum zu identifizieren. Ob es sich dabei um fehlerhafte
      Geräte, Medikamente, unsachgemäße Lagerung von Materialien oder mangelhaft
      gesicherte Bereiche handelt – Sie haben die Verantwortung für die
      Sicherheit der PatientInnen und die Aufgabe, all diese Risiken bzw.
      potentiellen Gefahrenquellen zu identifizieren.
    </p>
    <br />
    <h2>Gemeinsam ans Ziel</h2>
    <p>
      Einer der Schlüsselaspekte von SimuLern ist die{" "}
      <b>interprofessionelle Zusammenarbeit</b>. Sie werden mit anderen
      TeilnehmerInnen zusammenarbeiten, um Gefahren zu identifizieren und
      Lösungen für diese Probleme zu finden.
    </p>
    <p>
      Teilen Sie Ihre Beobachtungen, diskutieren Sie Ihre Ideen und arbeiten Sie
      als Team, um alle Gefahrenquellen zu identifizieren, schriftlich zu
      erheben und sachgemäß zu verargumentieren.
    </p>
  </p>
);

export const HOME_PAGE_PLAYER_TECHNICAL_BRIEFING = (
  <p>
    <p>
      Sie haben keinen Code? Wenden Sie sich bitte an Ihre TrainerInnen, um
      einen Code zu erhalten oder nutzen Sie den Ihnen zugesandten Link, um sich
      beim Training anzumelden.
    </p>
    <p>
      Wenn Sie sich bereits für ein Training angemeldet haben, finden Sie diese
      unter "Meine Anmeldungen". Dort können Sie sich auch wieder abmelden.
    </p>
    <p>
      Möchten Sie sich erstmal mit der Plattform vertraut machen? Dann können
      Sie sich in der <span className="fw-bold">Demo</span> umsehen.
    </p>
  </p>
);

export const WAITING_ROOM_BRIEFING = (simulation) => (
  <>
    <p>
      Was kommt auf uns zu? Was müssen wir tun? Was ist das Ziel des Spiels?
    </p>
    <ol>
      <li>
        <b>Zeitlimit:</b> Sie haben {simulation.duration} Minuten, um alle
        Gefahrenquellen zu identifizieren. Beenden Sie das Spiel vor Ablauf der
        Zeit, indem Sie auf den Button "Beenden" klicken. Damit beenden Sie das
        Spiel für alle Teammitglieder.
      </li>
      <li>
        <b>Patientenakte:</b> Es gibt eine Patientenakte, die Sie jederzeit
        einsehen können. Sie enthält wichtige Informationen über den Patienten,
        die Ihnen helfen, die Gefahrenquellen zu identifizieren.
      </li>
      <li>
        <b>PatientInnengefährdungen dokumentieren:</b> Sie haben die
        Möglichkeit, Gefährdungen und Risiken, die Sie im Spiel für die
        Patienten erkennen, in Ihrem <b>Notizbuch</b> zu vermerken. Dies ist ein
        kritischer Aspekt des Spiels und Ihre Ausführungen sollten so
        detailliert wie möglich sein.
      </li>
      <li>
        <b>PatientInnengefährdungen begründen:</b> Sie müssen jede
        identifizierte Gefahrenquelle nicht nur dokumentieren, sondern auch
        begründen. Das bedeutet, Sie erklären, warum Sie glauben, dass sie eine
        Gefährdung darstellt.
      </li>
      <li>
        <b>Gefahrenquellen klarmachen:</b> Es ist wichtig, dass Sie die
        Gefahrenquelle klar definieren, um ihr Ausmaß und die potenziellen
        Folgen zu verdeutlichen.
      </li>
      <li>
        <b>Bearbeiten:</b> Sie können Ihre Einträge im Notizbuch jederzeit
        bearbeiten, falls Ihnen neues Wissen zugänglich wird oder Sie Ihre
        Meinung zu einer bestimmten Gefahr ändern.
      </li>
      <li>
        <b>Disziplinäre Perspektiven:</b> Ihr Notizbuch ermöglicht es Ihnen,
        Gefährdungen aus unterschiedlichen Fachperspektiven zu betrachten. Als
        Teammitglied können Sie sich absprechen und die Ansichten von anderen
        berücksichtigen, um ein umfassenderes Verständnis der Risiken zu
        erhalten.
      </li>
      <li>
        <b>Kommunikation:</b> Die Kommunikation zwischen Ihnen und anderen
        Teammitgliedern über die App ist ein wesentlicher Aspekt des Spiels.
        Diese Interaktionen können Ihnen helfen, neue Perspektiven zu gewinnen
        und Ihre Aussagen im Notizbuch zu verfeinern.
      </li>
    </ol>
  </>
);
