import { useContext } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { UserContext } from "../../../contexts/UserContext";
import "./Trainings.css";
import "react-toastify/dist/ReactToastify.css";
import Password from "../../shared/Password";
import { useSimulationForm } from "../../../hooks/useSimulationsForm";

import React from "react";
import {
  IconDelete,
  IconEdit,
  IconStart,
  IconTeam,
} from "../../../constants/Icons";

export function SimulationBox({
  simulation,
  scenario,
  onEdit,
  onStart,
  onShowResults,
  onDelete,
  onShowTeams,
  timeIsOver,
  remainingTime,
}) {
  const generateQRCode = (link) => {
    return `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${link}`;
  };

  simulation.registrations_link = `https://simulern.de/register?key=${simulation.registrations_key}&training=${simulation.register_key}`;
  simulation.ended = timeIsOver(simulation.end_at);

  console.log(simulation);
  return (
    <div className="simulation-box">
      <Row>
        <Col md={10}>
          <p>
            <b>Status:</b>{" "}
            {simulation.started
              ? simulation.ended
                ? "Beendet"
                : "Gestartet (" + remainingTime + " Minuten verbleiben)"
              : "Nicht gestartet"}
          </p>
          <p>
            <b>Beschreibung:</b> {simulation.description}
          </p>
          <p>
            <b>Ausgewähltes Szenario:</b>{" "}
            <a href={`/scenarios#${scenario?._id}`}>{scenario?.title}</a>
          </p>
          <p>
            <b>Spieldauer:</b> {simulation.duration} Minuten
          </p>
          <p>
            <b>Bisher eingewählte Teilnehmer: </b>
            {simulation.registered_players.length}
          </p>
          <br />
          <p>
            <b>
              (1) Falls TeilnehmerIn noch kein Account hat -
              SimuLern-Registrierungslink inkl. Einwahlcode:
            </b>
            :<br />
            <a href={simulation.registrations_link}>
              {simulation.registrations_link}
            </a>
          </p>

          <p>
            <b>(2) Falls TeilnehmerIn bereits einen Account hat -</b> Link mit Einwahlcode
            <br />
            <a
              href={
                "https://simulern.de/registrations?code=" +
                simulation.register_key
              }
            >
              https://simulern.de/registrations?code={simulation.register_key}
            </a>
            <br />
          </p>
        </Col>
        <Col md={2}>
          <img
            src={generateQRCode(
              "https://simulern.de/registrations?code=" +
              simulation.register_key
            )}
            alt="QR-Code"
          />
        </Col>
      </Row>

      <div className="simulation-actions">
        {simulation.started ? (
          <><Button variant="dark" onClick={() => onShowResults(simulation._id)}>
            Zum Debriefing
          </Button>
            <Button variant="danger" onClick={onDelete}>
              <IconDelete />
            </Button></>
        ) : (
          <>
            <Button variant="dark" onClick={onEdit}>
              <IconEdit />
            </Button>
            <Button variant="dark" onClick={onShowTeams}>
              <IconTeam />
            </Button>
            <Button variant="dark" onClick={() => onStart(simulation._id)}>
              <IconStart />
            </Button>
            <Button variant="danger" onClick={onDelete}>
              <IconDelete />
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

const baseSim = {
  title: "",
  description: "",
  register_key: "",
  scenario: "",
  duration: 10,
  public_open: false,
  registered_players: [],
};

export function CreateSimulationModal({
  show,
  setShowCreateSimulationModal,
  selectedSimulation,
  isEditmode,
  setIsEditmode,
  availableScenarios,
}) {
  const { user, setUser } = useContext(UserContext);

  const { simulation, handleSubmit, handleChange, setSimulation } =
    useSimulationForm(
      baseSim,
      user,
      setUser,
      selectedSimulation,
      isEditmode,
      setIsEditmode,
      setShowCreateSimulationModal
    );

  return (
    <Modal
      show={show}
      onHide={() => {
        setShowCreateSimulationModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEditmode ? "Training bearbeiten" : "Training erstellen"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <InputGroup controlId="formBasicName" className="mb-3">
            <InputGroup.Text id="basic-addon1" className="w-20">
              Name
            </InputGroup.Text>
            <Form.Control
              name="title"
              type="text"
              placeholder="..."
              value={simulation.title}
              onChange={handleChange}
            />
          </InputGroup>
          <Form.Group controlId="formBasicDescription" className="mb-3">
            <Form.Control
              name="description"
              type="text"
              placeholder="Beschreibung"
              value={simulation.description}
              as="textarea"
              rows={3}
              onChange={handleChange}
            />
          </Form.Group>

          <Password
            name="register_key"
            title="Code"
            placeholder="..."
            password={simulation.register_key}
            setPassword={(value) =>
              setSimulation({ ...simulation, register_key: value })
            }
          />

          <InputGroup controlId="formBasicCheckbox" className="mb-3 mt-2">
            <InputGroup.Text id="basic-addon1" className="w-20">
              Szenario
            </InputGroup.Text>
            <Form.Select
              name="scenario"
              aria-label="Default select example"
              value={simulation.scenario}
              onChange={handleChange}
            >
              <option>Wähle ein Szenario</option>
              {availableScenarios &&
                availableScenarios.map((scenario, scenarioIndex) => (
                  <option key={scenarioIndex} value={scenario._id}>
                    {scenario.title}
                  </option>
                ))}
            </Form.Select>
          </InputGroup>

          <InputGroup controlId="formBasicCheckbox" className="mb-3">
            <InputGroup.Text id="basic-addon1" className="w-20">
              Spieldauer
            </InputGroup.Text>
            <Form.Control
              name="duration"
              type="number"
              placeholder="Countdown Timer"
              value={simulation.duration}
              onChange={handleChange}
            />
            <InputGroup.Text id="basic-addon1" className="w-20">
              Minuten
            </InputGroup.Text>
          </InputGroup>

          <Form.Group controlId="formBasicCheckbox" className="mb-3">
            <Form.Check
              className="custom-checkbox"
              name="public_open"
              type="checkbox"
              label="Öffentlich zugänglich"
              checked={simulation.public_open}
              onChange={handleChange}
            />
          </Form.Group>

          <Button variant="dark" type="submit" className="w-100">
            {isEditmode ? "Änderungen speichern" : "Training erstellen"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CreateSimulationModal;
