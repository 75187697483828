import { Navbar, Container, Nav } from "react-bootstrap";
import logo from "../../../media/logo-white-background.png";
import "./PublicNavbar.css";

function PublicNavbar() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="mb-4">
      <Container>
        <Navbar.Brand href="/home">
          <img
            alt=""
            src={logo}
            height="40"
            className="d-inline-block align-top rounded"
          />{" "}
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav-1" />

        <Navbar.Collapse id="responsive-navbar-nav-1">
          <Nav className="ml-auto">
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/about">Über uns</Nav.Link>
            <Nav.Link href="/contact">Kontakt</Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <Navbar.Collapse
          id="responsive-navbar-nav-2"
          className="justify-content-end"
        >
          <Navbar.Text
            className="centerOnSmallScreen"
            onClick={() => {
              window.location.href = "/login";
            }}
            style={{
              marginRight: "1rem",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Anmelden
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default PublicNavbar;
