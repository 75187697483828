// React-Bootstrap Module
import { Table, Modal, Button, Accordion } from "react-bootstrap";
import klinikum_stuttgart from "../../../media/klinikum_stuttgart.png";

function PatientFile(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      fullscreen={true}
      contentClassName=""
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <img
            src={klinikum_stuttgart}
            alt="Klinikum Stuttgart"
            width="60px"
            style={{ borderRadius: "7%" }}
          />
        </Modal.Title>
        &emsp;&emsp;&emsp;Patientenakte
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Stammblatt</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Name, Vorname, Geburtsdatum:
                  </span>{" "}
                  Frau Grausam, Dolores, 24.12.1924
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>
                    Reanimationsstatus:
                  </span>{" "}
                  Ja
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Hauptdiagnose:</span>{" "}
                  Gonarthrose Kniegelenk rechts
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Nebendiagnose:</span> St.
                  Nach Hüfttotalprothese beidseits (links: 2003, rechts: 1996)
                  Essentielle Hypertonie (2002) Morbus Crohn (2005)
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Allergien:</span> Nüsse
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Jetziges Leiden:</span>{" "}
                  Frau Grausam tritt um 07.30 Uhr geplant in die Klinik ein, für
                  eine Knietotalprothese rechts. Sie äussert Schmerzen im
                  rechten Knie visuelle Analogskala (VAS) in Ruhe 1; in Bewegung
                  3, vor allem beim Treppensteigen.
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Diagnostik:</span> Labor
                  unauffällig, EKG unauffälig
                </li>
                <li>
                  <span style={{ fontWeight: "bold" }}>Soziales:</span> Frau
                  Grausam wohnt zusammen mit ihrem Mann in einem Haus ohne
                  Treppen. Sie wird von ihrem Mann und den drei Kindern gut
                  unterstützt und äußert ein gutes soziales Umfeld zu haben.
                  Frau G. ist selbstständig mobil, ohne Gehhilfen und macht
                  einen gepflegten Eindruck.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Medikamente</Accordion.Header>
            <Accordion.Body>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th>Medikamente fix:</th>
                    <th>morgens</th>
                    <th>mittags</th>
                    <th>abends</th>
                    <th>nachts</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Eprosartan Mepha Lactabs 600 mg</td>
                    <td>0.5</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Esomprazol Tbl. 20 mg</td>
                    <td>1</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Metamizol Tbl. 500 mg</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>Oxycodon/Naloxon Tbl. 10 mg/5 mg</td>
                    <td>1</td>
                    <td>0</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Paracetamol Tbl. 500 mg</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>Celecoxib Tbl. 200 mg</td>
                    <td>1</td>
                    <td>0</td>
                    <td>1</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Zolpidem Tbl. 10 mg</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0.5</td>
                  </tr>
                  <tr>
                    <td>Paraffin Sirup ml</td>
                    <td>0</td>
                    <td>20</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td colSpan={5}></td>
                  </tr>
                  <tr>
                    <td>Ringerfundin 1000ml / 24h i.v. Dauerinfusion</td>
                    <td>8 Uhr</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>

              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <th colSpan={4}>Medikamente in Reserve:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Schmerz</td>
                    <td colSpan={3}>
                      Oxycodon Schmelztablette 5 mg p.o. max. 4-stdl.
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Nausea</td>
                    <td>1) Metoclopramid Tbl. 10 mg max 3x1 / 24 h</td>
                  </tr>
                  <tr>
                    <td>2) Ondansetron Filmtbl. 4 mg max. 8-stdl.</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Verordnungen an die Pflege</Accordion.Header>
            <Accordion.Body>
              <Table responsive striped bordered hover>
                <tbody>
                  <tr>
                    <td>
                      Blutdruck / Puls / Sauerstoffsättigung (sO&#8322;) /
                      Atemfrequenz
                    </td>
                    <td>
                      1. postoperativer Tag 4x tgl.<br></br>2. postoperativer
                      Tag 3x tgl.<br></br>ab 3. postoperativer Tag 1x tgl.
                      <br></br>Limiten:<br></br>Blutdruck systolisch {">"}
                      180mmHg &rarr; Meldung an Arzt<br></br>Blutdruck
                      diastolisch {"<"}
                      90mmHg &rarr; 250ml Ringer Bolus und Meldung an den Arzt{" "}
                      <br></br>
                      Puls {"<"} 45/min &rarr; Meldung an den Arzt<br></br>
                      Puls {">"} 120 &rarr; Meldung an den Arzt<br></br>
                      sO&#8322; {"<"} 90% &rarr; 2-4Liter/min O&#8322;
                      nasalAtemfrequenz {"<"}10/min &rarr; Meldung an Arzt
                      <br></br>
                    </td>
                  </tr>
                  <tr>
                    <td>Temperatur</td>
                    <td>
                      Analog zu Blutdruck / Puls<br></br>Limite: Temperatur{" "}
                      {">"}38.5°C &rarr; 2x2 Blutkulturen und Meldung an
                      Dienstarzt
                    </td>
                  </tr>
                  <tr>
                    <td>Essen / Trinken</td>
                    <td>Trinken ab sofort, Essen wenn keine Nausea</td>
                  </tr>
                  <tr>
                    <td>Kost</td>
                    <td>Normal</td>
                  </tr>
                  <tr>
                    <td>Mobilität</td>
                    <td>
                      Frei, sobald Sensorik und Motorik ohne Befund,
                      Vollbelastung mit Gehstöcken
                    </td>
                  </tr>
                  <tr>
                    <td>Labor</td>
                    <td>Morgen Blutentnahme: kleines Blutbild</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Kurve</Accordion.Header>
            <Accordion.Body>
              <Table responsive striped bordered hover>
                <tbody>
                  <tr>
                    <td colSpan={2}></td>
                    <td colSpan={2}>Tag 1</td>
                    <td colSpan={2}>Tag 2</td>
                  </tr>
                  <tr>
                    <td colSpan={2}></td>
                    <td>abends</td>
                    <td>nachts</td>
                    <td>morgens</td>
                    <td>mittags</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Größe: 172 cm</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Gewicht (kg)</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Temperatur (°C)</td>
                    <td>36.6</td>
                    <td>36.8</td>
                    <td>36.8</td>
                    <td>36.4</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Blutdruck (mmHg)</td>
                    <td>128/84</td>
                    <td>132/68</td>
                    <td>140/80</td>
                    <td>144/80</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Puls</td>
                    <td>68</td>
                    <td>80</td>
                    <td>70</td>
                    <td>68</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Sauerstoffsättigung (sO&#8322;)</td>
                    <td>94%</td>
                    <td>97%</td>
                    <td>97%</td>
                    <td>95%</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Stuhlgang</td>
                    <td></td>
                    <td>kein</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>Schmerzen</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      Peripherer Venenkatheter linker Unterarm; Verbandswechsel:
                      alle 5 Tage oder bei Bedarf
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Pflegediagnosen</Accordion.Header>
            <Accordion.Body>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <td></td>
                    <td colSpan={2}>Diagnose</td>
                    <td colSpan={2}>Merkmale</td>
                    <td colSpan={2}>Beeinflussende Faktoren</td>
                    <td colSpan={2}>Ziel</td>
                    <td colSpan={2}>Maßnahmen</td>
                    <td colSpan={2}>Evaluation</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td colSpan={2}>Beeinträchtigte Gehfähigkeit</td>
                    <td colSpan={2}>
                      Beeinträchtigte Fähigkeit Treppen zu steigen,
                      erforderliche Strecken zu gehen
                    </td>
                    <td colSpan={2}>
                      <ul>
                        <li>Reduzierte Ausdauer</li>
                        <li>Umgebungsbedingte Hürde</li>
                        <li>ungenügende Muskelkraft</li>
                        <li>Schmerzen</li>
                      </ul>
                    </td>
                    <td colSpan={2}>
                      Patientin ist bis zum Austritt sicher und selbstständig
                      mit oder Gehstöcke mobil inkl. Treppensteigen
                    </td>
                    <td colSpan={2}>
                      <ul>
                        <li>Gehtraining in Begleitung morgens und abends</li>
                        <li>Tägliche Physiotherapie</li>
                      </ul>
                    </td>
                    <td colSpan={2}>Täglich</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td colSpan={2}>Selbstversorgungsdefizit Körperpflege</td>
                    <td colSpan={2}>
                      Beeinträchtigte Fähigkeit, den Körper zu waschen und
                      abzutrocknen
                    </td>
                    <td colSpan={2}>
                      <ul>
                        <li>umgebungsbezogenes Hindernis</li>
                        <li>Schwäche</li>
                        <li>Muskuloskeletale Beeinträchtigungen</li>
                        <li>Schmerzen</li>
                      </ul>
                    </td>
                    <td colSpan={2}>
                      Führt Körperpflege unter Einbezug ihrer Ressourcen mit
                      Unterstützung der Pflegeperson durch
                    </td>
                    <td colSpan={2}>
                      <ul>
                        <li>Unterstützung bei Körperpflege und Ankleiden</li>
                      </ul>
                    </td>
                    <td colSpan={2}>Täglich</td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Pflegeverlauf</Accordion.Header>
            <Accordion.Body>
              <Table responsive striped bordered hover>
                <thead>
                  <tr>
                    <td>Datum</td>
                    <td>Zeit</td>
                    <td colSpan={2}>Thema</td>
                    <td colSpan={6}>Pflegeverlauf</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={3}>Tag 1</td>
                    <td>07.30</td>
                    <td colSpan={2}>Eintritt</td>
                    <td colSpan={6}>
                      Frau Grausam tritt in Begleitung ihres Mannes auf der
                      Bettenstation ein für eine geplante Knie Totalprothese.
                      Eintrittsgespräch und präoperative Vorbereitungen werden
                      durchgeführt.
                    </td>
                  </tr>
                  <tr>
                    <td>08.10</td>
                    <td colSpan={2}>Prämedikation</td>
                    <td colSpan={6}>Frau G. wird prämediziert.</td>
                  </tr>
                  <tr>
                    <td>08.45</td>
                    <td colSpan={2}>Verlegung in Operationssaal</td>
                    <td colSpan={6}>
                      Frau G. wird in den Operationssaal verlegt.
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={12}>
                      Operation und Aufenthalt Überwachungsstation
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={3}>Tag 1</td>
                    <td>15.00</td>
                    <td colSpan={2}>Verlegung aufs Zimmer</td>
                    <td colSpan={6}>
                      Frau Grausam wird aufs Zimmer verlegt. Patientin äußert
                      momentan keine Beschwerden.
                    </td>
                  </tr>
                  <tr>
                    <td>16.00</td>
                    <td colSpan={2}>Mobilisation</td>
                    <td colSpan={6}>
                      Patientin wird von der Physiotherapie mobilisiert. Frau G.
                      läuft mit Gehstöcken eine kurze Strecke im Zimmer.
                      Patientin konnte spontan eine große Portion Urin lösen.
                    </td>
                  </tr>
                  <tr>
                    <td>20.00</td>
                    <td colSpan={2}>Befinden</td>
                    <td colSpan={6}>
                      Patientin äußert sich momentan zufrieden. Wird ans Lavabo
                      mobilisiert für die Körperpflege.
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={3}>Tag 2</td>
                    <td>02.00</td>
                    <td colSpan={2}>Schlaf</td>
                    <td colSpan={6}>Patientin scheint zu schlafen.</td>
                  </tr>
                  <tr>
                    <td>07.00</td>
                    <td colSpan={2}>Labor</td>
                    <td colSpan={6}>
                      Blutentnahme noch nicht durchgeführt. Liegt gerichtet im
                      Zimmer bereit
                    </td>
                  </tr>
                  <tr>
                    <td>08.00</td>
                    <td colSpan={2}>Befinden</td>
                    <td colSpan={6}>
                      Frau Keller verspürt starke Übelkeit und muss sich
                      mehrfach übergeben. Äusser ebenfalls starke Schmerzen (VAS
                      8) . Reserve gegen Übelkeit und Schmerzen verabreicht.
                      Frau K. fühlt sich danach besser.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} variant="dark">
          Zurück
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const configs = [
  {
    label: "Patientenakte",
    component: PatientFile,
  },
];

export default function PlaygroundAdditionalInfo(props) {
  const { config, ...rest } = props;
  
  const Component = configs[config].component;
  return <Component {...rest} />;
}
