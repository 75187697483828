import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
  Container,
  Table,
  Button,
  InputGroup,
  FormControl,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import ImageProfile from "./imageprofile/ImageProfile";
import {
  deleteUser,
  readOrganization,
  updateUser,
} from "../../services/firebase/api_old";
import DialogConfirm from "./Confirm";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { deleteObject } from "firebase/storage";
import { storage } from "../../services/firebase/init";
import Loading from "./Loading";
import { IconDelete } from "../../constants/Icons";

function Profile() {
  const { user } = useContext(UserContext);
  const [editMode, setEditMode] = useState(false);
  const [profileData, setProfileData] = useState(user);
  const [profilePicIsLoading, setProfilePicIsLoading] = useState(false);

  const [organizationName, setOrganizationName] = useState("");

  useEffect(() => {
    const getOrganization = async () => {
      console.log(profileData);
      const organizationData = await readOrganization(profileData.organization);
      setOrganizationName(organizationData.name);
    };
    getOrganization();
  }, [profileData, profileData.organization]);

  const handleInputChange = (event) => {
    setProfileData({ ...profileData, [event.target.name]: event.target.value });
  };

  const removeProfilePicture = async (uid) => {
    setProfilePicIsLoading(true);
    const profile_picture_ref = ref(storage, `user_photos/${uid}`);
    await deleteObject(profile_picture_ref);
    setProfileData({ ...profileData, profile_picture: undefined });
    setProfilePicIsLoading(false);
  };

  const handleProfilePictureChange = async (user, uid, newProfilePicture) => {
    setProfilePicIsLoading(true);
    if (newProfilePicture === undefined) {
      delete user.profile_picture;
    } else {
      const profile_picture_ref = ref(storage, `user_photos/${uid}`);
      await uploadBytes(profile_picture_ref, newProfilePicture);
      const profile_picture_url = await getDownloadURL(profile_picture_ref);
      setProfileData({ ...profileData, profile_picture: profile_picture_url });
    }
    setProfilePicIsLoading(false);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSave = () => {
    const updateData = {
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      organization: profileData.organization,
      profile_picture: profileData.profile_picture,
    };

    if (profileData.type === "Player") {
      updateData.occupation = profileData.occupation;
    }

    updateUser(user.uid, updateData);

    setEditMode(false);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDelete = () => {
    deleteUser(user.uid);
  };

  console.log(profileData.profile_picture);

  return (
    <Container className="mb-5">
      <DialogConfirm
        show={showDeleteModal}
        text="Möchten Sie Ihr Profil wirklich löschen?"
        onConfirm={handleDelete}
        onCancel={() => setShowDeleteModal(false)}
      />
      <h1>Profil</h1>
      <h6 className="text-muted">
        {user.type === "Player" ? "Spieler" : user.type}
      </h6>
      <br />
      {profilePicIsLoading ? (
        <Spinner animation="border" />
      ) : (
        <ImageProfile src={profileData.profile_picture} size={150} />
      )}

      {editMode ? (
        <InputGroup className="mt-3">
          <FormControl
            type="file"
            name="profile_picture"
            onChange={(e) =>
              handleProfilePictureChange(
                user,
                user.uid,
                e.target.files[0]
              ).then(() => console.log("Profile picture changed"))
            }
          />
          {profileData.profile_picture === undefined ? null : (
            <Button
              variant="danger"
              onClick={() => removeProfilePicture(user.uid)}
            >
              <IconDelete />
            </Button>
          ) || <Loading />}
        </InputGroup>
      ) : null}
      <br />
      <br />
      <Table responsive>
        <thead>
          <tr>
            <td>
              <b>Alias Name</b>
            </td>
            <td>
              {editMode ? (
                <InputGroup>
                  <FormControl
                    name="first_name"
                    defaultValue={profileData.first_name}
                    onChange={handleInputChange}
                    disabled
                  />
                </InputGroup>
              ) : (
                profileData.first_name
              )}
            </td>
          </tr>

          <tr>
            <td>
              <b>E-Mail</b>
            </td>
            <td>
              {editMode ? (
                <InputGroup>
                  <FormControl
                    name="email"
                    defaultValue={profileData.email}
                    disabled
                  />
                </InputGroup>
              ) : (
                profileData.email
              )}
            </td>
          </tr>
          <tr>
            <td>
              <b>Organisation</b>
            </td>
            <td>
              {editMode ? (
                <InputGroup>
                  <FormControl
                    name="organization"
                    defaultValue={profileData.organization}
                    onChange={handleInputChange}
                    disabled
                  />
                </InputGroup>
              ) : organizationName ? (
                organizationName
              ) : (
                <Loading />
              )}
            </td>
          </tr>
          {profileData.type === "Player" ? (
            <tr>
              <td>
                <b>Beschäftigung</b>
              </td>
              <td>
                {editMode ? (
                  <InputGroup>
                    <FormControl
                      name="occupation"
                      defaultValue={profileData.occupation}
                      onChange={handleInputChange}
                      disabled
                    />
                  </InputGroup>
                ) : (
                  profileData.occupation
                )}
              </td>
            </tr>
          ) : null}
        </thead>
      </Table>
      <br />
      <Row>
        <Col>
          {editMode ? (
            <span>
              <Button variant="dark mr-2" onClick={() => setEditMode(false)}>
                Abbrechen
              </Button>{" "}
              <Button variant="dark" onClick={handleSave}>
                Speichern
              </Button>
            </span>
          ) : (
            <Button onClick={handleEditMode} variant="dark">
              Bearbeiten
            </Button>
          )}
          <br />
          <Button
            variant="danger"
            className="mt-2"
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            Konto löschen
          </Button>
        </Col>
        <Col className="d-flex justify-content-end align-items-start">
          <Button
            variant="dark"
            onClick={() => {
              window.location.href = "/reset-password";
            }}
          >
            Passwort ändern
          </Button>{" "}
        </Col>
      </Row>
    </Container>
  );
}

export default Profile;
