import BaseNavbar from "../shared/navbar/BaseNavbar";

function AdminNavbar() {
  const links = [
    {
      type: "dropdown",
      title: "Trainings",
      items: [
        {
          href: "/trainings",
          className: "custom-nav-item",
          text: "Meine Trainings",
        },
        { href: "/demo", className: "custom-nav-item", text: "Demo-Training" },
      ],
    },
    {
      type: "link",
      href: "/scenarios",
      title: "Verfügbare Szenarien",
    },
    {
      type: "dropdown",
      title: "Hilfe",
      items: [
        { href: "/trainer-faq", className: "custom-nav-item", text: "FAQ" },
        { href: "/contact", className: "custom-nav-item", text: "Kontakt" },
      ],
    },
    {
      type: "link",
      title: "Über uns",
      href: "/about",
    }
  ];

  return BaseNavbar(links);
}

export default AdminNavbar;
