import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { useEffect, useState } from "react";
import { readSimulationsByTrainerId } from "../../services/firebase/api_old";
import { IconConfig, IconCopy, IconGame } from "../../constants/Icons";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../services/firebase/init";

function QuickActions() {
  return (
    <Row className="mb-5">
      <Col sm={12} lg={4} className="mb-3 mb-lg-0">
        <Button
          variant="outline-dark"
          className="w-100 py-3 h-100"
          onClick={() => {
            window.location.href = "/trainings";
          }}
        >
          <IconCopy />
          <br />
          <span className="fs-4">Trainings</span>
          <p>Zur Planung und Verwaltung der Simulationstrainings</p>
        </Button>
      </Col>
      <Col sm={12} lg={4} className="mb-3 mb-lg-0">
        <Button
          variant="outline-dark"
          className="w-100 py-3 h-100"
          onClick={() => {
            window.location.href = "/scenarios";
          }}
        >
          <IconConfig />
          <br />
          <span className="fs-4">Szenarien</span>{" "}
          <p>Zur Verwaltung der Szenen Ihrer Simulationen</p>
        </Button>
      </Col>

      <Col sm={12} lg={4} className="mb-3 mb-lg-0">
        <Button
          variant="outline-dark"
          className="w-100 py-3 h-100"
          onClick={() => {
            window.location.href = "/demo";
          }}
        >
          <IconGame />
          <br />
          <span className="fs-4">Demo</span>
          <p>Zum Testen einer Demo-Szene!</p>
        </Button>
      </Col>
    </Row>
  );
}

function RecentTrainings({ simulations, loading }) {
  return simulations.length > 0 ? (
    <Container
      style={{ borderRadius: 10, borderColor: "black" }}
      className="my-5 p-0"
    >
      <p>Zuletzt erstellte Trainings:</p>
      <Row
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        {loading ? (
          <p>Lädt...</p>
        ) : (
          simulations &&
          simulations.slice(0, 4).map((simulation, index) => (
            <Col key={index} md={4} className="mb-3">
              <Card className="border-1 shadow-sm">
                <Card.Body>
                  <Card.Title>{simulation.title}</Card.Title>
                  <Card.Text>
                    <Row>
                      <Col>
                        <b>Erstellt am: </b>
                      </Col>
                      <Col>
                        {simulation.created_at.toDate().toLocaleDateString()}{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <b>Beschreibung: </b>
                      </Col>
                      <Col>
                        <div style={{ maxHeight: 100, overflowY: "auto" }}>
                          {simulation.description} <br />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <b>Teilnehmer: </b>{" "}
                      </Col>
                      <Col>
                        <span>
                          <a
                            href="/manage-teams"
                            onClick={() =>
                              sessionStorage.setItem(
                                "simulationId",
                                simulation._id
                              )
                            }
                            className="text-decoration-none"
                          >
                            {simulation.registered_players.length}
                          </a>{" "}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <b>Szenario: </b>{" "}
                      </Col>
                      <Col>
                        <a
                          href={"/scenarios#" + simulation.scenario}
                          className="text-decoration-none"
                        >
                          {simulation.scenarioTitle}
                        </a>{" "}
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </Container>
  ) : null;
}

function TrainerHome() {
  const { user } = useContext(UserContext);

  const [simulations, setSimulations] = useState([]);
  const [loading, setLoading] = useState(true);

  const getScenarioTitle = async (scenarioId) => {
    const scenarioSnapshot = await getDoc(doc(db, "scenarios", scenarioId));
    const scenarioTitle = scenarioSnapshot.data().title;
    return scenarioTitle;
  };

  // Fetch the trainers latest simulations on mount
  useEffect(() => {
    if (user) {
      readSimulationsByTrainerId(user.uid, 3).then((simulations) => {
        var simulationPromises = simulations.map((simulation) => {
          return getScenarioTitle(simulation.scenario).then((scenarioTitle) => {
            return { ...simulation, scenarioTitle: scenarioTitle };
          });
        });

        Promise.all(simulationPromises).then((simulationsWithTitles) => {
          setSimulations(simulationsWithTitles);
          setLoading(false);
        });
      });
    }
  }, [user]);

  console.log("User: ", user);

  return (
    <Container>
      <h3 className="mt-4">
        Hallo, {user.first_name} {user.last_name}!
      </h3>
      <p>Willkommen im Trainerbereich.</p>
      <QuickActions />
      <RecentTrainings simulations={simulations} loading={loading} />
    </Container>
  );
}

export default TrainerHome;
