import React from "react";
import { Container, Accordion } from "react-bootstrap";

function BaseFAQ({ questions, answers }) {
  return (
    <Container>
      <h1>FAQ</h1>
      <Accordion defaultActiveKey="0" className="mt-4 mb-4">
        {Array.from({ length: questions.length }).map((_, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>{questions[index]}</Accordion.Header>
            <Accordion.Body>{answers[index]}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <br />

      <p>
        Sollten Sie weitere Fragen haben, wenden Sie sich gerne an{"  "}
        <a
          variant="link"
          href="mailto:kontakt@simulern.de"
          className="text-decoration-none"
        >
          kontakt@simulern.de
        </a>
      </p>
      <br />
    </Container>
  );
}

export default BaseFAQ;
