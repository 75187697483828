// NoteItem.js
import { useContext, useState } from "react";
import {
  ListGroup,
  Button,
  Form,
  Container,
  Card,
  Modal,
} from "react-bootstrap";
import { IconDelete, IconDown, IconEdit, IconUp } from "../../../constants/Icons";
import "./Notebook.css";
import { UserContext } from "../../../contexts/UserContext";

export function NoteItem({ id, title, note, deleteNote, editNote }) {
  const { user } = useContext(UserContext);
  const [editTitle, setEditTitle] = useState(null);

  const item = {
    _id: id,
    title: title,
    noteContent: note.noteContent,
    editedBy: note.editedBy,
  };

  return (
    <ListGroup.Item>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <span>
          <b>{title}</b>{" "}
          <i>
            Zuletzt bearbeitet von: {note.editedBy.first_name}{" "}
            {note.editedBy.last_name}
          </i>
        </span>

        <div>
          <Button
            variant="secondary"
            onClick={() => setEditTitle(title)}
            style={{
              padding: "0.1rem 0.3rem",
              fontSize: "1rem",
              marginRight: "0.5rem",
            }}
          >
            <IconEdit />
          </Button>

          <Button
            variant="danger"
            onClick={() => deleteNote(item)}
            style={{ padding: "0.1rem 0.3rem", fontSize: "1rem" }}
          >
            <IconDelete />
          </Button>
        </div>
      </div>

      {editTitle === title ? (
        <div>
          <Form.Control
            as="textarea"
            rows={3}
            defaultValue={note.noteContent}
            onChange={(e) => (note.noteContent = e.target.value)}
            className="mt-2 mb-2"
          />
          <Button
            onClick={() => {
              editNote(item, note.noteContent, {
                first_name: user ? user.first_name : "Maxi",
                last_name: user ? user.last_name : "Mustermann (Sie)",
                id: user ? user.uid : "1",
                profile_picture: user ? user.profile_picture : "",
              }); // assuming editedBy is userData
              setEditTitle(null);
            }}
            variant="dark"
            className="mb-2"
          >
            Speichern
          </Button>
        </div>
      ) : (
        <p>{note.noteContent}</p>
      )}
    </ListGroup.Item>
  );
}

function EditableNoteContent({ notes, deleteNote, editNote }) {
  console.log(notes);

  return (
    // Show scroll bar if content is too long
    <div style={{ overflow: "auto" /* styles */ }}>
      <ListGroup
        variant="flush"
        style={{
          maxHeight: "70vh",
          overflow: "auto",
          marginBottom: "0.5rem",
        }}
      >
        {Object.keys(notes).length > 0 ? (
          Object.entries(notes).map(([id, note], index) => {
            if (!note) {
              return null;
            }
            console.log(note);
            return (
              <NoteItem
                id={id}
                key={index}
                title={note.title}
                note={note}
                deleteNote={deleteNote}
                editNote={editNote}
              />
            );
          })
        ) : (
          <Container className="d-flex align-items-center justify-content-center mt-5">
            Ihr habt noch keine Notizen erstellt. Geht auf die Suche, die Zeit
            läuft!
          </Container>
        )}
      </ListGroup>
    </div>
  );
}

export default function Notebook({
  notes,
  deleteNote,
  editNote,
  type,
  show,
  onHide,
}) {
  const [isNotebookOpen, setIsNotebookOpen] = useState(false);

  const handleToggleClick = () => {
    setIsNotebookOpen(!isNotebookOpen);
  };

  if (type === "small") {
    return (
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Notizbuch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditableNoteContent
            notes={notes}
            deleteNote={deleteNote}
            editNote={editNote}
          />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Card
      id="notebook"
      bg="light"
      text="dark"
      border="dark"
      className={
        isNotebookOpen ? "notebook notebook-open" : "notebook notebook-closed"
      }
    >
      <Card.Header onClick={handleToggleClick} className="notebook-header">
        <div style={{ flex: 1, textAlign: "left" }}>
          {Object.keys(notes).length} Notiz
          {Object.keys(notes).length !== 1 && "en"}
        </div>
        <div style={{ flex: 1, textAlign: "right" }}>
          {isNotebookOpen ? <IconUp /> : <IconDown />}
        </div>
      </Card.Header>
      <EditableNoteContent
        notes={notes}
        deleteNote={deleteNote}
        editNote={editNote}
      />
    </Card>
  );
}
