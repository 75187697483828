import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import ImageProfile from "../../shared/imageprofile/ImageProfile";
import logo from "../../../media/logo-white-background.png";
import "./BaseNavbar.css";
import { logout } from "../../../services/firebase/api_old";

const createNavbarLinks = (links) => {
  console.log(links.length);
  return (
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto" activeKey={window.location.pathname}>
        {links.length > 0 &&
          links.map((link, index) => {
            console.log(link);
            if (link.type === "link") {
              return (
                <Nav.Link key={index} href={link.href}>
                  {link.title}
                </Nav.Link>
              );
            } else if (link.type === "dropdown") {
              return (
                <NavDropdown
                  key={index}
                  title={link.title}
                  id={`nav-dropdown-${index}`}
                >
                  {link.items.map((item, itemIndex) => (
                    <NavDropdown.Item
                      key={itemIndex}
                      href={item.href}
                      className={item.className}
                    >
                      {item.text}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              );
            } else {
              return null;
            }
          })}
      </Nav>
    </Navbar.Collapse>
  );
};

const BaseNavbar = (links) => {
  const { user } = useContext(UserContext);
  console.log(links);

  if (!user) {
    return null;
  }

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="mb-4"
      style={{
        display: window.location.pathname === "/demo" ? "none" : "",
      }}
    >
      <Container>
        <Navbar.Brand href="/home">
          <img
            alt=""
            src={logo}
            height="40"
            className="d-inline-block align-top rounded logo"
          />{" "}
        </Navbar.Brand>
        {/* Show this on small screens */}
        <Nav.Link href="/profile" className="d-lg-none">
          <ImageProfile src={user.profile_picture} size="40px" choose />
        </Nav.Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {createNavbarLinks(links)}
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text
            href="#logout"
            onClick={logout}
            style={{
              marginRight: "1rem",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Abmelden
          </Navbar.Text>
          {/* Hide this on small screens */}
          <Nav.Link href="/profile" className="d-none d-lg-block">
            <ImageProfile src={user.profile_picture} size="40px" choose />
          </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default BaseNavbar;
