import React from "react";
import { Card, Col, Container, Image, Row, Stack } from "react-bootstrap";
import contact_photo_both from "../../media/contact/contact_photo_both.JPG";

function Contact() {
  return (
    <Container>
      <h1 className="mt-3">Interesse geweckt?</h1>
      <p>
        <a href="mailto:kontakt@simulern.de">kontakt@simulern.de</a>
      </p>
      <Row>
        <Col>
          <Image
            src={contact_photo_both}
            rounded
            className="img-fluid mt-3"
            style={{ minWidth: "300px" }}
            alt="Kontakt"
          />
        </Col>
        <Col>
          <Stack>
            <Card className="my-3">
              <Card.Body>
                <Card.Title>Veronika Spielmann</Card.Title>
                <Card.Text>
                  <b>E-Mail:</b>{" "}
                  <a href="mailto:veronika.spielmann@simulern.de">
                    veronika.spielmann@simulern.de
                  </a>
                  <br />
                  <b>LinkedIn:</b>{" "}
                  <a href="https://www.linkedin.com/in/veronikaspielmann/">
                    Veronika Spielmann
                  </a>{" "}
                </Card.Text>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Daniel Seger</Card.Title>
                <Card.Text>
                  <b>E-Mail:</b>{" "}
                  <a href="mailto:daniel.seger@simulern.de">
                    daniel.seger@simulern.de
                  </a>
                  <br />
                  <b>LinkedIn:</b>{" "}
                  <a href="https://www.linkedin.com/in/daniel-seger/">
                    Daniel Seger
                  </a>{" "}
                </Card.Text>
              </Card.Body>
            </Card>
          </Stack>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Kooperationspartner STUPS</Card.Title>

              <Card.Text>
                <b>Ansprechpartner:</b> Dr. med. Christina Jaki
                <br />
                <a href="https://www.klinikum-stuttgart.de/leistungsspektrum/stuttgarter-paediatrie-und-patienten-simulator-stups/">
                  Website
                </a>
                <br />
                <a href="https://www.facebook.com/stups0711/">Facebook</a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <br />
    </Container>
  );
}

export default Contact;
