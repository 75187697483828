import { Container, Navbar, Row, Col, Stack, Image } from "react-bootstrap";
import logo from "../../../media/logo-white-background.png";
import { SIMULERN_MOTTO } from "../../../constants/Texts";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Navbar
      bg="dark"
      variant="dark"
      className="py-3 mw-100 w-100 footer"
      style={{ marginTop: "auto"}}
    >
      <Container fluid className="my-5">
        <Row className="mx-auto" style={{ width: "85%" }}>
          <Col xs={12} sm={3} className="mt-4">
            <b style={{ color: "#fff" }}>© {year} SimuLern </b>

            <br />
            <br />
            <p className="text-white-50">
              {SIMULERN_MOTTO}
            </p>
            <small className="text-white">Alle Rechte vorbehalten.</small>
            <br />
          </Col>

          <Col xs={12} sm={3} className="mt-4 text-center">
            <Image
              src={logo}
              alt="SimuLern Logo"
              height={100}
              className="d-inline-block align-top rounded"
            />
          </Col>

          <Col xs={12} sm={3} className="mt-4 text-start">
            <FooterLink
              title="Links"
              links={[
                { href: "/impressum", text: "Impressum" },
                { href: "/datenschutz", text: "Datenschutz" },
              ]}
            />
          </Col>

          <Col xs={12} sm={3} className="mt-4 text-start">
            <FooterLink
              title="Kontakt"
              links={[
                { href: "mailto:kontakt@simulern.de", text: "E-Mail" },
                {
                  href: "https://www.instagram.com/simulern/",
                  text: "Instagram",
                },
                {
                  href: "https://youtube.com/@SimuLern?si=ryaLZ6U1yo-zJs-B",
                  text: "YouTube",
                },
              ]}
            />
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

const FooterLink = ({ title, links }) => (
  <Stack>
    <b style={{ color: "#fff" }}>{title}</b>
    {links.map(({ href, text }) => (
      <a key={href} href={href} style={{ color: "#fff" }}>
        {text}
      </a>
    ))}
  </Stack>
);

export default Footer;
