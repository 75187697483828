import React from "react";
import { Container, Image } from "react-bootstrap";

import logo from "../../media/logo-transparent-background.png";

const Impressum = () => {
  const companyData = {
    name: "SimuLern, Veronika Spielmann und Daniel Seger GbR",
    street: "Wolframstr. 36",
    zip: "70191",
    city: "Stuttgart",
    partners: "Veronika Spielmann und Daniel Seger",
    phone: "+49 174 3078945",
    fax: "-",
    email: "kontakt@simulern.de",
    responsible: "Daniel Seger",
    responsibleAddress: "Steinbachstr. 7",
    responsibleZip: "66851",
    responsibleCity: "Queidersbach",
  };

  return (
    <Container className="mb-5">
      <Image src={logo} width={200} height={200} />
      <h4>Impressum</h4>
      <p>
        {companyData.name}
        <br />
        {companyData.street}
        <br />
        {companyData.zip} {companyData.city}
        <br />
        <br />
        <p>Gesellschafter: {companyData.partners}</p>
        Telefon: {companyData.phone}
        <br />
        Fax: {companyData.fax}
        <br />
        <br />
        E-Mail: <a href={`mailto:${companyData.email}`}>{companyData.email}</a>
      </p>

      <p>
        Inhatlich Verantwortlicher:
        <br />
        {companyData.responsible}
        <br />
        {companyData.responsibleAddress}
        <br />
        {companyData.responsibleZip} {companyData.responsibleCity}
      </p>

      <p>
        <a href="/datenschutz">Datenschutzerklärung</a>
      </p>
    </Container>
  );
};

export default Impressum;
