import { Table } from "react-bootstrap";
import ImageProfile from "./imageprofile/ImageProfile";

export default function TeamTable({ team, highlightUser = false }) {
  const getHighlightUserStyle = (player) => {
    if (player.uid === "1") {
      return { fontWeight: "bold" };
    } else {
      return {};
    }
  };

  return (
    <Table striped bordered hover>
      <tbody>
        {team && team?.players?.length > 0
          ? team.players.map((player, index) => (
              <tr
                key={index}
                style={highlightUser ? getHighlightUserStyle(player) : {}}
              >
                <td>{player.first_name + " " + player.last_name}</td>
                <td>{player.organizationName}</td>
                <td>{player.occupation}</td>
                <td>
                  <div className="d-flex justify-content-center align-items-center">
                    <ImageProfile src={player.profile_picture} size={30} />
                  </div>
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </Table>
  );
}
