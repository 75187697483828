import BaseNavbar from "../shared/navbar/BaseNavbar";

function PlayerNavbar() {
  const links = [
    { type: "link", href: "/registrations", title: "Meine Anmeldungen" },
    { type: "link", href: "/explore", title: "Entdecken" },
    {
      type: "dropdown",
      title: "Hilfe",
      items: [
        { href: "/player-faq", className: "custom-nav-item", text: "FAQ" },
        { href: "/contact", className: "custom-nav-item", text: "Kontakt" },
      ],
    },
    {
      type: "link",
      title: "Über uns",
      href: "/about",
    },
  ];

  return BaseNavbar(links);
}

export default PlayerNavbar;
