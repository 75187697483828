import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
  Stack,
  FormGroup,
  Alert,
} from "react-bootstrap";
import {
  registerUser,
  readOrganizations,
} from "../../services/firebase/api_old";
import { toast } from "react-toastify";
import ImageProfile from "./imageprofile/ImageProfile";
import Loading from "./Loading";
import { IconEye, IconEyeInvisible } from "../../constants/Icons";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../services/firebase/init";

const Registration = () => {
  // eslint-disable-next-line
  const [availableOrganizations, setAvailableOrganizations] = useState([]);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [key, setKey] = useState("");
  const [trainingParam, setTrainingParam] = useState(null); // Optional

  const [form, setForm] = useState({ last_name: "" });
  const [keyIsValid, setKeyIsValid] = useState(false);

  const validateApiKey = async (apiKey) => {
    console.log(apiKey);
    const keyDoc = await getDoc(doc(db, "api_keys", apiKey)).then((doc) => doc);

    console.log(keyDoc.data());

    if (keyDoc.exists()) {
      return true;
    } else {
      return false;
    }
  };

  const retrieveParametersFromUrl = async () => {
    // Check for query in URL (?key=...)
    const urlParams = new URLSearchParams(window.location.search);
    const apiKey = urlParams.get("key");
    const trainingCode = urlParams.get("training"); // Optional
    if (trainingCode) {
      setTrainingParam(trainingCode);
    }

    // Get key document from database and set the userType, organizationId and occupation
    const keyDoc = await getDoc(doc(db, "api_keys", apiKey)).then((doc) => doc);
    const type = keyDoc.data().type;

    setForm({
      ...form,
      type: type,
    });

    // Example: http://simulern/register?key=iTYitOprRygmIgQ3AnLw
    setKey(apiKey);
    console.log(apiKey);
    if (apiKey) {
      // Check if API key is valid
      const isValid = await validateApiKey(apiKey);
      if (isValid) {
        setKeyIsValid(true);
      }
    }
  };

  useEffect(
    () => {
      retrieveParametersFromUrl();
    },
    /* eslint-disable-next-line */
    []
  );

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowRepeatPassword = () =>
    setShowRepeatPassword(!showRepeatPassword);

  const saveFormToSessionStorage = () => {
    // Save form to session storage but remove password and repeatPassword
    const tempForm = { ...form };
    delete tempForm.password;
    delete tempForm.repeatPassword;
    sessionStorage.setItem("form", JSON.stringify(tempForm));
  };

  const loadFormFromSessionStorage = () => {
    const savedForm = sessionStorage.getItem("form");
    if (savedForm) {
      setForm(JSON.parse(savedForm));
    }
  };

  const handleInputChange = (event) => {
    const newForm = {
      ...form,
      [event.target.name]: event.target.value,
    };
    setForm(newForm);
    saveFormToSessionStorage();

    if (
      event.target.name === "password" ||
      event.target.name === "repeatPassword"
    ) {
      if (newForm.password || newForm.repeatPassword) {
        setPasswordError(newForm.password !== newForm.repeatPassword);
      }
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setForm({ ...form, profile_picture: file });
      setProfilePicturePreview(URL.createObjectURL(file));
    }
  };

  const fileInputRef = useRef();

  const handleClickOnUploadImageButton = () => {
    fileInputRef.current.click();
  };

  // Get all organizations from database on page load
  useEffect(() => {
    loadFormFromSessionStorage();
    const getOrganizations = async () => {
      const organizations = await readOrganizations();
      // Put "Sonstige" at the end of the list
      organizations.sort((a, b) => {
        if (a.name === "Sonstige") {
          return 1;
        } else if (b.name === "Sonstige") {
          return -1;
        } else {
          return 0;
        }
      });
      setAvailableOrganizations(organizations);
    };
    getOrganizations();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if form contains empty fields
    const toleratedEmptyFields = ["occupation", "profile_picture", "last_name"];
    for (const [key, value] of Object.entries(form)) {
      if (value === "" && !toleratedEmptyFields.includes(key)) {
        toast.error("Bitte fülle alle Felder aus");
        return;
      }
    }

    if (passwordError) {
      toast.error("Passwörter stimmen nicht überein");
      return;
    }

    setLoading(true);

    registerUser(form, setLoading, setRegistered, false, key, trainingParam);
  };

  if (keyIsValid === false) {
    return (
      <Container
        className="min-vh-100 d-flex align-items-center justify-content-center mb-5"
        style={{ textAlign: "center" }}
      >
        <Stack gap={3} className="w-100 mt-5">
          <div className="text-center">
            <h1>Registrierung</h1>
          </div>
          <div className="text-center">
            <small className="text-muted">
              Dieser Link ist ungültig oder wurde bereits verwendet. Um sich zu
              registrieren, benötigen Sie einen gültigen Link. Beispiel:
              <br />
              <code>https://www.simulern.de/register?key=1234567890</code>
            </small>
          </div>
        </Stack>
      </Container>
    );
  }

  return loading ? (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Loading />
    </div>
  ) : registered ? (
    <Container className="min-vh-100 d-flex align-items-center justify-content-center mb-5">
      <br />
      <br />
      <br />
      <Stack gap={3} className="w-100 mt-5">
        <div className="text-center">
          <h1>Registrierung</h1>
        </div>
        <div className="text-center">
          <small className="text-muted">
            Wir haben ihnen eine E-Mail mit einem Bestätigungslink geschickt.
            Bitte bestätigen Sie Ihre E-Mail-Adresse, um Ihr Konto zu
            aktivieren.
          </small>
          <br />
          <Button variant="dark" href="/login" className="mt-3">
            Zum Login
          </Button>
        </div>
      </Stack>
    </Container>
  ) : (
    <Container className="min-vh-100 d-flex align-items-center justify-content-center mb-5">
      <Stack gap={3} className="w-100 mt-5">
        <div className="text-center">
          <h1>Registrierung</h1>
        </div>
        <div className="text-center">
          <small className="text-muted">
            Bitte fülle die untenstehenden Felder aus, um ein neues Konto zu
            erstellen.
          </small>
        </div>

        <Form
          onSubmit={handleSubmit}
          className="w-50 mx-auto"
          style={{
            minWidth: "350px",
          }}
        >
          <Alert variant="info" className="mt-2" dismissible>
            <small>
              <b>Tipp:</b> Fügen Sie ein Bild von sich hinzu, damit andere Sie
              in Trainings wiedererkennen können.
            </small>
          </Alert>
          <Form.Group
            controlId="formBasicprofile_picture"
            className="mb-2"
            style={{ display: "none" }}
          >
            <Form.Control
              ref={fileInputRef}
              type="file"
              text="Profilbild"
              name="profile_picture"
              onChange={handleImageChange}
            />
          </Form.Group>
          <div className="text-center">
            <br />
            <ImageProfile
              onClick={handleClickOnUploadImageButton}
              size={100}
              choose={true}
              src={profilePicturePreview}
              upload={profilePicturePreview === null}
            />
            <br />
            <Button
              className={profilePicturePreview === null ? "d-none" : "mb-2"}
              variant="warning"
              onClick={() => setProfilePicturePreview(null)}
            >
              Profilbild entfernen
            </Button>
            <br />

            <br />
          </div>
          <fieldset className="mb-5">
            {/*
            <Form.Group controlId="formBasicType" className="mb-2">
              <Form.Select
                aria-label="Type select example"
                name="type"
                value={form.type}
                onChange={handleInputChange}
              >
                <option>Ich bin...</option>
                <option value="Trainer">Trainer</option>
                <option value="Player">Spieler</option>
              </Form.Select>
            </Form.Group>
            */}
            <Form.Group controlId="formBasicFirstName" className="mb-2">
              <Form.Control
                type="text"
                name="first_name"
                placeholder="Alias Name"
                value={form.first_name}
                onChange={handleInputChange}
              />
            </Form.Group>

            {form.type === "Player" && (
              <Form.Group controlId="formBasicOccupation" className="mt-2">
                <Form.Select
                  aria-label="Occupation select example"
                  name="occupation"
                  value={form.occupation}
                  onChange={handleInputChange}
                >
                  <option>Studiengang / Beruf</option>
                  <option value="Pflegeberuf">Pflegeberuf</option>
                  <option value="Pflegeausbildung">Pflegeausbildung</option>
                  <option value="Medizinstudium">Medizinstudium</option>
                  <option value="Arzt/Ärztin">Arzt/Ärztin</option>
                  <option value="Duales Studium">Duales Studium</option>
                  <option value="Keine Angabe">Keine Angabe</option>
                </Form.Select>
              </Form.Group>
            )}
          </fieldset>

          <fieldset>
            <Alert variant="info" className="" dismissible>
              <small>
                <b>Hinweis:</b> Um eine reibungslose Registrierung zu
                gewährleisten, verwenden Sie am besten eine private
                E-Mail-Adresse.
              </small>
            </Alert>
            <Form.Group controlId="formBasicEmail" className="mb-2">
              <Form.Control
                type="email"
                name="email"
                placeholder="E-Mail-Adresse"
                value={form.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <FormGroup
              controlId="formBasicPassword"
              className={`mb-2 ${passwordError ? "border border-danger" : ""}`}
            >
              <InputGroup>
                <FormControl
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Passwort"
                  value={form.password}
                  onChange={handleInputChange}
                />
                <InputGroup.Text
                  onClick={handleShowPassword}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <IconEyeInvisible /> : <IconEye />}
                </InputGroup.Text>
              </InputGroup>
            </FormGroup>
            <FormGroup
              controlId="formBasicRepeatPassword"
              className={`mb-2 ${passwordError ? "border border-danger" : ""}`}
            >
              <InputGroup>
                <FormControl
                  type={showRepeatPassword ? "text" : "password"}
                  name="repeatPassword"
                  placeholder="Passwort wiederholen"
                  value={form.repeatPassword}
                  onChange={handleInputChange}
                />
                <InputGroup.Text
                  onClick={handleShowRepeatPassword}
                  style={{ cursor: "pointer" }}
                >
                  {showRepeatPassword ? <IconEyeInvisible /> : <IconEye />}
                </InputGroup.Text>
              </InputGroup>
            </FormGroup>
          </fieldset>
          <br />
          <fieldset>
            <Form.Group controlId="formBasicOrganization" className="mb-2">
              <Form.Select
                aria-label="Organization select example"
                name="organization"
                value={form.organization}
                onChange={handleInputChange}
              >
                <option>Organisation</option>
                {availableOrganizations &&
                  availableOrganizations.map(
                    (organization, organizationIndex) => (
                      <option key={organizationIndex} value={organization.id}>
                        {organization.name}
                      </option>
                    )
                  )}
              </Form.Select>
            </Form.Group>
          </fieldset>
          <Button variant="dark" type="submit" className="w-100 mt-2">
            Registrieren
          </Button>
        </Form>
        <div className="text-center mt-4">
          <small className="text-muted">
            Sie haben bereits ein Konto?{" "}
            <a href="/login" style={{ color: "black" }}>
              Melden Sie sich hier an!
            </a>
          </small>
        </div>
      </Stack>
    </Container>
  );
};

export default Registration;
