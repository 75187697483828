import { Accordion, Button, Container, Stack } from "react-bootstrap";

function AdminStuff() {
  return (
    <Container fluid>
      <h3>Funktionen für Admins</h3>
      <br />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Szenarien</Accordion.Header>
          <Accordion.Body>
            <Button
              variant="dark"
              onClick={() => (window.location.href = "/configurator")}
            >
              Szenario konfigurieren
            </Button>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Benutzer</Accordion.Header>
          <Accordion.Body>
            <Button
              variant="dark"
              onClick={() => (window.location.href = "/register-links")}
            >
              Registrierungslinks erstellen
            </Button>
            <br />
            <br />
            <Button
              variant="dark"
              onClick={() => (window.location.href = "/user-overview")}
            >
              Nutzer Übersicht
            </Button>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Trainings</Accordion.Header>
          <Accordion.Body>Keine vorhanden</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Organisationen</Accordion.Header>
          <Accordion.Body>Keine vorhanden</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}

function Dashboard() {
  return (
    <Container>
      <Stack gap={3}>
        <AdminStuff />
      </Stack>
    </Container>
  );
}

export default Dashboard;
