import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useSimulation } from "../../../hooks/useSimulation";
import { readScenario, readTeam } from "../../../services/firebase/api_old";
import useTeamsForNotes from "../../../hooks/useTeamNotes";
import DebriefingContent from "./DebriefingComponents";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../services/firebase/init";

function Debriefing() {
  const simulationId = sessionStorage.getItem("simulationId");
  const simulation = useSimulation(simulationId);
  const teams = useTeamsForNotes(simulation?.teams);
  const scenarioRef = useRef(simulation?.scenario);
  const [scenarioItems, setScenarioItems] = useState({});
  const [currenItemIndex, setcurrenItemIndex] = useState(
    Number(sessionStorage.getItem("currenItemIndex")) || 0
  );
  const [blurred, setBlurred] = useState(true);
  const [debriefingStarted, setDebriefingStarted] = useState(false);

  const [reviewedItems, setReviewedItems] = useState(0);

  function handleReview(direction) {
    if (direction === 1) {
      if (currenItemIndex + 1 >= Object.values(scenarioItems).length) {
        if (reviewedItems === Object.values(scenarioItems).length - 1) {
          setReviewedItems((prevItems) => prevItems + 1);
        }
        return;
      }
      // increment index and store in session storage
      setcurrenItemIndex((prevIndex) => {
        sessionStorage.setItem("currenItemIndex", prevIndex + 1);
        return prevIndex + 1;
      });
      setReviewedItems((prevItems) => prevItems + 1);
    } else if (direction === -1) {
      if (currenItemIndex - 1 < 0) {
        return;
      }
      // decrement index and store in session storage
      setcurrenItemIndex((prevIndex) => {
        sessionStorage.setItem("currenItemIndex", prevIndex - 1);
        return prevIndex - 1;
      });
      setReviewedItems((prevItems) => prevItems - 1);
    }
  }

  useEffect(() => {
    // reset currenItemIndex in sessionStorage when debriefing starts anew
    if (!debriefingStarted && currenItemIndex !== 0) {
      sessionStorage.setItem("currenItemIndex", 0);
      setcurrenItemIndex(0);
    }
  }, [currenItemIndex, debriefingStarted]);

  useEffect(() => {
    const fetchData = async () => {
      const scenario = await readScenario(simulation?.scenario);
      scenarioRef.current = scenario;
      if (scenario) {
        const newScenarioItems = scenario.views
          ?.flatMap((view) => view.items || [])
          .reduce((obj, item) => {
            obj[item._id] = {
              ...item,
              answers: Object.values(teams).map((team) => ({
                team: team.name,
                answer: team?.notes?.[item._id],
              })),
              scores: Object.values(teams).map((team) => {
                return team?.notes?.[item._id]?.score || 0;
              }),
            };
            return obj;
          }, {});
        console.log(newScenarioItems);
        setScenarioItems(newScenarioItems);
      }
    };
    fetchData();
  }, [simulation?.scenario, teams]);

  const items = Object.values(scenarioItems);
  const currentItem = items[currenItemIndex];

  const handleScoreUpdate = (itemIndex, teamIndex, score) => {
    console.log(
      "Updating score from item number ",
      itemIndex,
      " for team number ",
      teamIndex,
      "to ",
      score
    );
    const updatedItems = [...items];

    updatedItems[itemIndex].scores[teamIndex] = score;
    setScenarioItems(updatedItems);
    console.log(
      updatedItems.map((item) => {
        return { title: item.title, scores: item.scores };
      })
    );
  };

  const handleNotesUpdate = async (teamRef, teamNotes) => {
    await updateDoc(teamRef, {
      notes: teamNotes,
    });
  };

  const updateTeam = async (teamRef, scores) => {
    await readTeam(teamRef).then((team) => {
      let teamNotes = team.notes;
      Object.keys(scores).forEach((itemId) => {
        if (!teamNotes[itemId]) {
          teamNotes[itemId] = {};
        }
        teamNotes[itemId].score = scores[itemId].score || 0;
      });

      handleNotesUpdate(teamRef, teamNotes);
    });
  };

  const [showZoomModal, setShowZoomModal] = useState(false);

  // Everytime, a score is updated, update the score in the team.notes[itemId] in firebase
  useEffect(() => {
    Object.values(teams).forEach((team, teamIndex) => {
      let scores = {};
      Object.values(scenarioItems).forEach((item, itemIndex) => {
        scores[item._id] = {
          score: item.scores[teamIndex],
        };
      });

      updateTeam(doc(db, "teams", team._id), scores);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioItems]);

  return (
    <Container>
      <DebriefingContent
        debriefingStarted={debriefingStarted}
        reviewedItems={reviewedItems}
        itemsLength={items.length}
        currentItem={currentItem}
        teams={teams}
        handleReview={handleReview}
        handleScoreUpdate={handleScoreUpdate}
        setDebriefingStarted={setDebriefingStarted}
        setBlurred={setBlurred}
        blurred={blurred}
        items={items}
        setScenarioItems={setScenarioItems}
        currenItemIndex={currenItemIndex}
        setReviewedItems={setReviewedItems}
        setcurrenItemIndex={setcurrenItemIndex}
        setShowZoomModal={setShowZoomModal}
        showZoomModal={showZoomModal}
      />
    </Container>
  );
}

export default Debriefing;
