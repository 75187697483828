import { Card, Container, Stack, Table } from "react-bootstrap";
import { readSimulationById, readTeam } from "../../../services/firebase/api_old";
import { useEffect, useState } from "react";

export default function Ceremony() {
  const [scores, setScores] = useState({});
  const [teams, setTeams] = useState([]);

  const simulationId = sessionStorage.getItem("simulationId");
  useEffect(() => {
    readSimulationById(simulationId).then((simulation) => {
      simulation.teams.map((team) => {
        return readTeam(team).then((team) => {
          setTeams((prevTeams) => [...prevTeams, team]);
          // For each entry in the team.notes object (which is a map), add the score to the scores object
          Object.keys(team?.notes).forEach((key) => {
            setScores((prevScores) => {
              if (!team?.notes[key]?.score) {
                return prevScores;
              }
              console.log("prevScores", prevScores);
              console.log("Adding ", team.notes[key].score, " to ", team._id);

              console.log("newScores", {
                ...prevScores,
                [team._id]: prevScores[team._id]
                  ? prevScores[team._id] + team.notes[key].score
                  : team.notes[key].score,
              });

              return {
                ...prevScores,
                [team._id]: prevScores[team._id]
                  ? prevScores[team._id] + team.notes[key].score
                  : team.notes[key].score,
              };
            });
          });
        });
      });
    });
  }, [simulationId]);

  return (
    <Container>
      <h1>Siegerehrung</h1>
      <Table>
        <thead>
          <tr>
            <th>Team</th>
            <th>Punkte</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => {
            console.log(team);
            console.log(scores[team._id]);
            return (
              <tr key={index}>
                <td>
                  {teams[index].name}{" "}
                  {scores[team._id] === Math.max(...Object.values(scores)) && (
                    <span role="img" aria-label="trophy">
                      🏆
                    </span>
                  )}
                </td>
                <td>{scores[team._id]}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Container>
        {teams
          .filter((team) => {
            return scores[team._id] === Math.max(...Object.values(scores));
          })
          .map((team) => {
            return (
              <Card className="w-50 mx-auto my-5">
                <Card.Header>
                  <Card.Title>Herzlichen Glückwunsch!</Card.Title>
                </Card.Header>
                <Card.Body>
                  <b>Sieger: {team.name}</b>
                  <br />
                  <br />
                  <Stack direction="horizontal" gap={3}>
                    {team.players.map((player) => {
                      return (
                        <Stack>
                          {player.first_name} {player.last_name}
                          <br />
                          <br />
                          <img
                            src={player.profile_picture}
                            alt=""
                            style={{ width: "100px" }}
                          />
                        </Stack>
                      );
                    })}
                  </Stack>
                </Card.Body>
              </Card>
            );
          })}
      </Container>
    </Container>
  );
}
