import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import Search from "./Search";
import { readOrganization } from "../../services/firebase/api_old";
import { HOME_PAGE_PLAYER_BRIEFING, HOME_PAGE_PLAYER_TECHNICAL_BRIEFING } from "../../constants/Texts";

function PlayerHome() {
  const { user } = useContext(UserContext);

  const [enteredCode, setEnteredCode] = useState("");

  const [organization, setOrganization] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      console.log(user);
      await readOrganization(user.organization).then((org) => {
        console.log(org);
        setOrganization(org);
      });
    };
    fetchData();
  }, [user, user.organization]);

  const searchElement = (
    <>
      <h1>Willkommen, {user.first_name}!</h1>
      <p>
        Melden Sie sich hier direkt mit dem Code Ihrer TrainerInnen bei einem
        Training an:
      </p>
      <Search
        placeholder="Code eingeben"
        onChange={(e) => {
          setEnteredCode(e.target.value);
        }}
        searchTerm={enteredCode}
        showButton={true}
        onButtonClick={() => {
          window.location.href = "/registrations?code=" + enteredCode;
        }}
      />
      <br />
      <p>
        Ansprechpartner für SimuLern in Ihrer Organisation ist <br />
        <Container className="fw-bold text-center my-5">
        {organization?.contact?.title} {organization?.contact?.first_name} {organization?.contact?.last_name}
        </Container>
      </p>
      {HOME_PAGE_PLAYER_TECHNICAL_BRIEFING}
    </>
  );

  const infoElement = (
    <>
      <span className="d-flex justify-content-between">
        <h2>Was ist SimuLern?</h2>
        <Button
          variant="dark"
          className="mb-3"
          onClick={() => {
            window.location.href = "/demo";
          }}
        >
          Demo-Training
        </Button>
      </span>
      {HOME_PAGE_PLAYER_BRIEFING}
      <br />
    </>
  );

  return (
    <Container>
      <Row>
        <Col lg={6} md={12} className="mb-4">
          {searchElement}
        </Col>
        <Col
          lg={6}
          md={12}
          className="bg-light p-4 mb-4"
          style={{ borderRadius: 10 }}
        >
          {infoElement}
        </Col>
      </Row>
    </Container>
  );
}

export default PlayerHome;
