import React, { useState } from "react";

import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Image,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import logo from "../../media/logo-transparent-background.png";
import { loginUser } from "../../services/firebase/api_old";
import { IconEye, IconEyeInvisible } from "../../constants/Icons";

function Login() {
  // Zustand für die Formulareingaben
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Benutzerkontext lesen und prüfen, ob der Benutzer eingeloggt ist
  const { user } = useContext(UserContext);

  // Wenn der Benutzer eingeloggt ist, navigiere ihn zu /home
  if (user && user.emailVerified) {
    window.location.href = "/home";
  }

  const [showPassword, setShowPassword] = useState(false);

  // Funktion, die aufgerufen wird, wenn das Formular abgeschickt wird
  const handleSubmit = async (event) => {
    
    event.preventDefault();
    await loginUser(email, password).then((res) => {
      if (res.success) {
        window.location.href = "/home";
      }
    });
  };

  // Formular, das angezeigt wird, wenn der Benutzer nicht eingeloggt ist
  return (
    <Container className="min-vh-100 d-flex align-items-center justify-content-center">
      <Row>
        <Col>
          <div className="d-flex align-items-center justify-content-center mx-auto w-25">
            <Image src={logo} fluid />
          </div>
          <div className="text-center">
            <strong>
              Willkommen! <br />{" "}
            </strong>{" "}
            Bitte melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort
            an.
          </div>
          <Form onSubmit={handleSubmit} className="w-75 mx-auto" style={{maxWidth: "600px"}}>
            <Form.Group controlId="formBasicUsername" className="mb-2 mt-4">
              <Form.Control
                type="text"
                placeholder="E-Mail-Adresse"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <InputGroup className="mb-2">
              <FormControl
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputGroup.Text
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <IconEyeInvisible /> : <IconEye />}
              </InputGroup.Text>
            </InputGroup>

            <Button variant="dark" type="submit" className="w-100">
              Anmelden
            </Button>

            <div className="text-center mt-2">
              <small className="text-muted">
                <a href="/reset-password" style={{ color: "black" }}>
                  Passwort vergessen...
                </a>
              </small>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
