import React from "react";
import "aframe";
import { Entity, Scene } from "aframe-react";
import { Alert, Container, Spinner } from "react-bootstrap";
import medialInfoIcon from "../../../media/medical-record.png";

import PlaygroundItemModal from "./PlaygroundItemModal";
import Notebook from "./PlaygroundNotebook";
import Countdown from "./PlaygroundCountdown";
import PlaygroundEndModal from "./PlaygroundEndModal";
import PlaygroundTeamModal from "./PlaygroundTeamModal";
import ConfirmEndModal from "./PlaygroundConfirmEndModal";

import {
  readScenario,
  updateTeam,
  readUser,
} from "../../../services/firebase/api_old";
import "./Playground.css";
import { toast } from "react-toastify";
import sticky_note from "../../../media/sticky_note.png";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import ImageProfile from "../imageprofile/ImageProfile";
import Watermark from "../Watermark";

import photo_member_1 from "../../../media/dummy/team/dummy_team_member_1.jpeg";
import photo_member_2 from "../../../media/dummy/team/dummy_team_member_2.jpeg";
import photo_member_3 from "../../../media/dummy/team/dummy_team_member_3.jpeg";
import { db } from "../../../services/firebase/init";
import PlaygroundAdditionalInfo from "./PlaygroundAdditionalInfo";

class Playground extends React.Component {
  constructor(props) {
    super(props);

    let userPlayer = {};

    if (props.mode !== "multiplayer") {
      userPlayer = {
        uid: "1",
        first_name: "Maxi",
        last_name: "Muster (Sie)",
        organization: "Musteruniversität",
        occupation: "Musterstudent:in",
      };
    } else {
      userPlayer = {
        uid: props.user.uid,
        first_name: props.user.first_name,
        last_name: props.user.last_name,
        organization: props.user.organization,
        occupation: props.user.occupation,
      };
    }
    this.state = {
      mode: this.props.mode,
      scenario_id: "cobCcxntFBxwItOW3KoG", // "s1ooHqL1yd0reGQblE8v",
      showPlaygroundItemModal: false,
      registrations_key: [],
      clickedItemId: null,
      hoveredItemNum: null,
      hoveredSwitchNum: null,
      countdownEnded: false,
      showWarning: false,
      notes: {}, // replace with notes from backend
      editNoteTitle: null,
      isLoading: true,
      team: {
        _id: "1",
        name: "Team 1",
        players: [
          userPlayer,
          {
            uid: "2",
            first_name: "Annina",
            last_name: "Musterfrau",
            organization: "Kreisklinik Reutlingen",
            occupation: "Duales Studium",
            profile_picture: photo_member_1,
          },
          {
            uid: "3",
            first_name: "Robert",
            last_name: "Musterstudent",
            organization: "Universität Tübingen",
            occupation: "Medizinstudium",
            profile_picture: photo_member_2,
          },
          {
            uid: "4",
            first_name: "Markus",
            last_name: "Musterschüler",
            organization: "Klinikum Stuttgart",
            occupation: "Pflegeausbildung",
            profile_picture: photo_member_3,
          },
        ],
      },
      views: [],
      currentViewNum: 0,
      end_at: new Date(Date.now() + 2 * 60000),
      showAdditionalInfo: false,
      showNotebookForSmallScreens: false,
    };

    this.unsubscribeFromSimulations = null;
    this.unsubscribeFromTeams = null;

    if (this.props.mode !== "multiplayer") {
      this.loadScenario(this.state.scenario_id);
    }
  }

  componentDidMount() {
    let tempScenarioId = null;
    if (this.props.mode === "multiplayer") {
      // SIMULATION SUBSCRIPTION
      // Subscribe to changes in the simulation
      this.unsubscribeFromSimulations = onSnapshot(
        doc(db, "simulations", sessionStorage.getItem("simulationId")),
        (doc) => {
          if (doc.exists()) {
            const { end_at, scenario } = doc.data();

            const newEndAt = new Date(end_at);

            if (typeof scenario === "object") {
              this.setState({
                scenario_id: scenario._id,
                end_at: newEndAt,
                registrations_key: doc.data().registrations_key,
              });
              tempScenarioId = scenario._id;
            } else {
              this.setState({
                scenario_id: scenario,
                end_at: newEndAt,
                registrations_key: doc.data().registrations_key,
              });
              tempScenarioId = scenario;
            }
            // add this after setting the scenario_id
            this.loadScenario(tempScenarioId);
          }
        }
      );

      // TEAMS SUBSCRIPTIONS
      const teamDocRef = doc(db, "teams", sessionStorage.getItem("teamId"));
      // Start with initial team load
      getDoc(teamDocRef)
        .then((docSnap) => {
          if (docSnap.exists()) {
            if (docSnap.data().ended) {
              this.setState({ countdownEnded: true });
            }

            const playersDataPromises = docSnap.data().players.map((player) =>
              readUser(player.id).then((user) => {
                player.first_name = user.first_name;
                player.last_name = user.last_name;
                player.organization = user.organization;
                player.occupation = user.occupation;
                player.profile_picture = user.profile_picture;
                return player;
              })
            );

            Promise.all(playersDataPromises).then((players) => {
              const team = { ...docSnap.data(), players };
              this.setState({ team, notes: docSnap.data().notes });
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching team: ", error);
        });

      // Then subscribe to changes
      this.unsubscribeFromTeams = onSnapshot(
        doc(db, "teams", sessionStorage.getItem("teamId")),
        (docSnap) => {
          if (docSnap.exists()) {
            if (docSnap.data().ended) {
              this.setState({ countdownEnded: true });
            }
            const newNotes = docSnap.data().notes;
            this.setState({
              notes: newNotes,
              team_id: sessionStorage.getItem("teamId"),
            });
          }
        }
      );
    } else {
      setTimeout(() => {
        this.setState({
          isLoading: false,
        });

        setTimeout(() => {
          this.setState({
            clickedItemId: this.state.views[0].items[0]._id,
          });
          this.addNote(
            this.state.views[0].items[0],
            "Hi, ich bin deine Mitspielerin! Das hier ist eine Notiz. Probier es selbst aus, indem du auf ein Objekt klickst, oder diese Notiz im Notizbuch bearbeitest!",
            this.state.team.players[1]
          );

          setTimeout(() => {
            this.setState({
              clickedItemId: this.state.views[0].items[1]._id,
            });
            this.addNote(
              this.state.views[0].items[1],
              "Hier ist noch eine Notiz. Ich denke, das hier könnte eine Gefahr für die Patientin sein. Was denkst du?",
              this.state.team.players[2]
            );

            setTimeout(() => {
              this.setState({
                clickedItemId: this.state.views[0].items[0]._id,
              });
              this.editNote(
                this.state.views[0].items[0],
                "Begründung: Das Desinfektionsmittel fehlt. Es besteht die Gefahr einer Infektion.",
                this.state.team.players[0]
              );
            }, 15000);
          }, 15000);
        }, 20000);
      }, 1000);
    }
  }

  componentWillUnmount() {
    // Unsubscribe from changes when the component unmounts
    if (this.unsubscribeFromSimulations) {
      this.unsubscribeFromSimulations();
    }

    if (this.unsubscribeFromTeams) {
      this.unsubscribeFromTeams();
    }
  }

  // Countdown
  setCountdownEnded = (ended) => {
    this.setState({ countdownEnded: ended });
  };

  // Notes
  setEditNoteIndex = (index) => {
    this.setState({ editNoteIndex: index });
  };

  addNote = (item, note, userData) => {
    console.log("add note", item, note, userData);
    this.setState((prevState) => {
      const notes = { ...prevState.notes };
      const noteData = {
        title: item.title,
        noteContent: note,
        editedBy: userData,
      };

      if (prevState.editNoteTitle !== null) {
        notes[prevState.editNoteTitle] = noteData;
      } else {
        notes[item._id] = noteData;
      }

      if (this.props.mode === "multiplayer") {
        console.log("update team");
        console.log(sessionStorage.getItem("teamId"));
        console.log({ notes });
        updateTeam(sessionStorage.getItem("teamId"), { notes }, "note");
      }

      return { notes, editNoteTitle: null };
    });
  };

  editNote = (item, newNote, userData) => {
    this.setState((prevState) => {
      const notes = { ...prevState.notes };
      notes[item._id] = {
        noteContent: newNote,
        editedBy: userData,
        title: item.title,
      };

      if (this.props.mode === "multiplayer") {
        updateTeam(sessionStorage.getItem("teamId"), { notes }, "note");
      }

      return { notes };
    });
  };

  deleteNote = (item) => {
    this.setState((prevState) => {
      const notes = { ...prevState.notes };
      delete notes[item._id];

      if (this.props.mode === "multiplayer") {
        updateTeam(sessionStorage.getItem("teamId"), { notes }, "note");
      }

      return { notes };
    });
  };

  createToast = (isNoteDeleted, isNoteAdded, key) => {
    const note = this.state.notes[key];
    /*const message = isNoteDeleted
      ? `Notiz zu "${note.title}" von ${note.editedBy.first_name} ${note.editedBy.last_name} gelöscht`
      : isNoteAdded
      ? `Neue Notiz zu "${note.title}" von ${note.editedBy.first_name} ${note.editedBy.last_name}`
      : `${note.editedBy.first_name} ${note.editedBy.last_name} hat die Notiz zu "${note.title}" bearbeitet`;*/

    const message = isNoteDeleted
      ? `Eine Notiz wurde gelöscht`
      : isNoteAdded
        ? `Neue Notiz zu "${note.title}" von ${note.editedBy.first_name} ${note.editedBy.last_name}`
        : `${note.editedBy.first_name} ${note.editedBy.last_name} hat die Notiz zu "${note.title}" bearbeitet`;

    const images = (
      <>
        <ImageProfile src={note.editedBy.profile_picture} size="30px" />
        <ImageProfile src={sticky_note} alt="sticky note" size="30px" />
      </>
    );

    toast.info(
      <span>
        {message}
        <br />
        <br />
        {images}
      </span>,
      {
        position: "top-left",
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.notes !== this.state.notes) {
      const prevNoteKeys = Object.keys(prevState.notes);
      const currentNoteKeys = Object.keys(this.state.notes);
      const intersectingKeys = prevNoteKeys.filter((n) =>
        currentNoteKeys.includes(n)
      );

      console.log(intersectingKeys);

      if (intersectingKeys.length > 0 && currentNoteKeys.length > 0) {
        if (intersectingKeys.length === currentNoteKeys.length) {
          for (let i = 0; i < intersectingKeys.length; i++) {
            const key = intersectingKeys[i];
            if (
              prevState.notes[key].noteContent !==
              this.state.notes[key].noteContent
            ) {
              this.createToast(false, false, key);
              return;
            }
          }
        }

        if (prevNoteKeys.length > intersectingKeys.length) {
          this.createToast(true, false, prevNoteKeys[prevNoteKeys.length - 1]);
          return;
        }

        if (
          currentNoteKeys.length > intersectingKeys.length &&
          this.state.notes[currentNoteKeys[currentNoteKeys.length - 1]]
        ) {
          this.createToast(
            false,
            true,
            currentNoteKeys[currentNoteKeys.length - 1]
          );
        }
      }
    }
  }

  // Item Modal
  hidePlaygroundItemModal = () => {
    this.setState({
      showPlaygroundItemModal: false,
      hoveredItemNum: null,
      hoveredSwitchNum: null,
    });
  };

  // Load data from backend
  loadScenario = async (scenario_id) => {
    try {
      const response = await readScenario(scenario_id);
      console.log(response);
      if (response && response.views) {
        this.setState({
          // : response.initial_view_num,
          views: response.views,
          isLoading: false,
          additional_info_id: response.additional_info_id,
        });
      }
    } catch (error) {
      console.error("Failed to load data", error);
    }
  };
  render = () => {
    // eslint-disable-next-line
    const {
      isLoading,
      countdownEnded,
      end_at,
      hoveredItemNum,
      hoveredSwitchNum,
      views,
      currentViewNum,
    } = this.state;

    if (isLoading) {
      return (
        // Center horizontally and vertically
        <Container className="d-flex align-items-center justify-content-center mt-5">
          <Spinner animation="border" variant="dark" />
        </Container>
      );
    }

    return (
      <Scene cursor="rayOrigin: mouse" vr-mode-ui="enabled: false">
        {!countdownEnded ? (
          <>
            {this.state.showWarning ? (
              <div
                style={{
                  background: "rgba(0, 0, 0, 0.5)",
                  position: "fixed",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  zIndex: 999,
                }}
              >
                <Alert
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                  }}
                  variant="warning"
                  onClose={() => {
                    this.setState({ showWarning: false });
                  }}
                  dismissible
                >
                  <Alert.Heading>Tick-tack...</Alert.Heading>
                  <p>
                    Die Zeit läuft ab! Finden Sie in der verbleibenden Zeit die
                    verbleibenden PatientInnengefährdungen und dokumentieren
                    bzw. begründen Sie diese.
                  </p>
                </Alert>
              </div>
            ) : null}

            <Watermark />
            <PlaygroundEndModal show={countdownEnded} />
            <Entity
              primitive="a-camera"
              position="0 1.6 0"
              wasd-controls="enabled: false"
            />
            <Entity primitive="a-sky" src={views[currentViewNum].photo_url} />
            <Countdown
              end_at={end_at}
              setCountdownEnded={this.setCountdownEnded}
              mode={this.state.mode}
              showTimeWarning={() => this.setState({ showWarning: true })}
            />
            <div
              className="team-badge"
              onClick={() => this.setState({ showTeamModal: true })}
            >
              Team
            </div>
            <PlaygroundTeamModal
              show={this.state.showTeamModal}
              onHide={() => this.setState({ showTeamModal: false })}
              team={this.state.team} // pass your team data here
            />
            <span className="patient-file-badge-title">
              Patientenakte
            </span>
            <input
              type="image"
              alt="Zusätzliche Infos"
              src={medialInfoIcon}
              height="80px"
              width="100px"
              onClick={() => this.setState({ showAdditionalInfo: true })}
              className="additional-info-button"
            />

            <div
              className="end-simulation-badge"
              onClick={() => this.setState({ showEndPlaygroundModal: true })}
            >
              Beenden
            </div>
            <div className="view-title-badge">
              {views[currentViewNum].title}
            </div>
            <ConfirmEndModal
              show={this.state.showEndPlaygroundModal}
              onHide={() => this.setState({ showEndPlaygroundModal: false })}
              endPlayground={() => {
                this.setState({ countdownEnded: true });
                this.setState({ showEndPlaygroundModal: false });
                if (this.state.mode === "multiplayer") {
                  updateTeam(
                    this.state.team_id,
                    {
                      ended: true,
                    },
                    "note"
                  );
                }
              }}
              mode={this.state.mode}
            />

            <div
              className="notebook-badge d-block d-md-none d-lg-none d-xl-none"
              onClick={() =>
                this.setState({ showNotebookForSmallScreens: true })
              }
            >
              Notizbuch
            </div>
            {/*Only on small screens*/}
            <div className="d-block d-md-none d-lg-none d-xl-none">
              <Notebook
                user={this.state.team[0]}
                type="small"
                show={this.state.showNotebookForSmallScreens}
                onHide={() =>
                  this.setState({ showNotebookForSmallScreens: false })
                }
                notes={this.state.notes}
                deleteNote={this.deleteNote}
                editNote={this.editNote}
                setEditNoteIndex={this.setEditNoteIndex}
              />
            </div>
            <div className="d-none d-md-block">
              <Notebook
                user={this.props.user}
                notes={this.state.notes}
                deleteNote={this.deleteNote}
                editNote={this.editNote}
                setEditNoteIndex={this.setEditNoteIndex}
              />
            </div>
            <PlaygroundAdditionalInfo
              config={this.state.additional_info_id}
              show={this.state.showAdditionalInfo}
              onHide={() => this.setState({ showAdditionalInfo: false })}
            />

            {views[currentViewNum].items &&
              views[currentViewNum].items.map((item, index) => {
                return (
                  <>
                    <PlaygroundItemModal // Modal for item interaction
                      id={item._id}
                      title={item.title}
                      image={item.photo_url}
                      show={
                        this.state.showPlaygroundItemModal &&
                        this.state.clickedItemId === item._id
                      }
                      setShow={this.hidePlaygroundItemModal}
                      addNote={this.addNote}
                      notes={this.state.notes}
                      mode={this.state.mode}
                    />
                    <Entity
                      rotation={
                        item.rotation.x +
                        " " +
                        item.rotation.y +
                        " " +
                        item.rotation.z
                      }
                      position={
                        item.position.x +
                        " " +
                        item.position.y +
                        " " +
                        item.position.z
                      }
                      scale={item.scale.x + " " + item.scale.y}
                      key={item._id}
                      geometry={{
                        primitive: "plane",
                        width: 1,
                        height: 1,
                      }}
                      material={{
                        color:
                          hoveredItemNum === item._id ? "#FFFFFF" : "#808080",
                        opacity: 0.5,
                      }}
                      events={{
                        click: () => {
                          this.setState({
                            clickedItemId: item._id,
                            showPlaygroundItemModal: true,
                          });
                        },
                        mouseenter: () => {
                          this.setState({ hoveredItemNum: item._id });
                        },
                        mouseleave: this.hidePlaygroundItemModal,
                      }}
                    />
                    {this.state.notes[item._id] && (
                      <Entity
                        geometry={{
                          primitive: "plane",
                          width: 0.1,
                          height: 0.1,
                        }}
                        position={
                          item.position.x +
                          " " +
                          (item.position.y + 0.1) +
                          " " +
                          item.position.z
                        }
                        rotation={
                          item.rotation.x +
                          " " +
                          item.rotation.y +
                          " " +
                          item.rotation.z
                        }
                        scale={"0.5 0.5"}
                        material={{
                          src: sticky_note,
                        }}
                        events={{
                          click: () => {
                            this.setState({
                              clickedItemId: item._id,
                              showPlaygroundItemModal: true,
                            });
                          },
                        }}
                      />
                    )}
                  </>
                );
              })}
            {views[currentViewNum].switches &&
              views[currentViewNum].switches.map((switchItem, index) => {
                return (
                  <Entity
                    id={switchItem._id}
                    text={{
                      value: switchItem.description,
                      align: "center",
                      width: 2,
                      color:
                        hoveredSwitchNum === switchItem._id ? "black" : "white",
                    }}
                    rotation={
                      switchItem.rotation.x +
                      " " +
                      switchItem.rotation.y +
                      " " +
                      switchItem.rotation.z
                    }
                    position={
                      switchItem.position.x +
                      " " +
                      switchItem.position.y +
                      " " +
                      switchItem.position.z
                    }
                    scale={switchItem.scale.x + " " + switchItem.scale.y}
                    key={switchItem._id}
                    geometry={{
                      primitive: "plane",
                      width: 1,
                      height: 1,
                    }}
                    material={{
                      color:
                        hoveredSwitchNum === switchItem._id
                          ? "white"
                          : "#808080",
                      opacity: hoveredSwitchNum === switchItem._id ? 1 : 0.3,
                    }}
                    events={{
                      click: () => {
                        // Set the loading indicator to true
                        this.setState({ isLoading: true });

                        // Find the new view
                        const newViewNum = views.findIndex(
                          (view) => view._id === switchItem.linked_view.id
                        );

                        // Set the new view and the loading indicator to false
                        this.setState({
                          currentViewNum: newViewNum,
                        });

                        // Use a setTimeout to simulate the loading process
                        setTimeout(() => {
                          this.setState({ isLoading: false });
                        }, 1000);
                      },

                      mouseenter: () => {
                        this.setState({ hoveredSwitchNum: switchItem._id });
                      },
                      mouseleave: () => {
                        this.setState({ hoveredSwitchNum: null });
                      },
                    }}
                  />
                );
              })}
          </>
        ) : (
          <>
            <PlaygroundEndModal show={countdownEnded} />
          </>
        )}
      </Scene>
    );
  };
}

export default Playground;
