// React Libraries
import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Accordion } from "react-bootstrap";

// Trainer specific components
import CreateSimulationModal, { SimulationBox } from "./TrainingsComponents";
import Loading from "../../shared/Loading";

// Reusable components
import DialogConfirm from "../../shared/Confirm";

// API
import {
  deleteSimulation,
  startSimulation,
} from "../../../services/firebase/api_old";

// Context
import { UserContext } from "../../../contexts/UserContext";

// Styles
import "./Trainings.css";

import { getDoc, collection, query, doc, where } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { db } from "../../../services/firebase/init";

const timeIsOver = (end_at) => {
  const now = new Date();
  const end = new Date(end_at);
  console.log(now, end);
  return now > end;
};

const calculateRemainingTime = (end_at) => {
  const now = new Date();
  const end = new Date(end_at);
  const diff = end - now;

  const minutes = Math.floor(diff / 1000 / 60)
    .toString()
    .padStart(2, "0");
  const seconds = Math.floor((diff / 1000) % 60)
    .toString()
    .padStart(2, "0");

  if (diff < 0) {
    console.log("time is over");
    return false;
  }

  return `${minutes}:${seconds}`;
};

function SimulationBoxWrapper({
  key,
  simulation,
  scenarios,
  setShowConfirmDeleteDialog,
  setShowConfirmStartDialog,
  setSelectedSimulationId,
  setShowCreateSimulationModal,
  setIsEditmode,
}) {
  const [remainingTime, setRemainingTime] = useState(0);

  // if sim has "end_at" -> calculate remaining time
  useEffect(() => {
    if (simulation.end_at) {
      const interval = setInterval(() => {
        setRemainingTime(calculateRemainingTime(simulation.end_at));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [simulation.end_at]);

  return (
    <Accordion className="mb-2" defaultActiveKey="0" key={key}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <span>
            Training: <b>{simulation.title}</b>
          </span>

          {calculateRemainingTime(simulation.end_at) && simulation.end_at && (
            <div className="running-badge" />
          )}
        </Accordion.Header>
        <Accordion.Body>
          <SimulationBox
            simulation={simulation}
            scenario={scenarios?.find(
              (scenario) => scenario._id === simulation.scenario
            )}
            onEdit={() => {
              setIsEditmode(true);
              setSelectedSimulationId(simulation._id);
              setShowCreateSimulationModal(true);
            }}
            onStart={() => {
              setSelectedSimulationId(simulation._id);
              setShowConfirmStartDialog(true);
            }}
            onShowResults={() => {
              sessionStorage.setItem("simulationId", simulation._id);
              window.location.href = `/debriefing`;
            }}
            onDelete={() => {
              setSelectedSimulationId(simulation._id);
              setShowConfirmDeleteDialog(true);
            }}
            onShowTeams={() => {
              sessionStorage.setItem("simulationId", simulation._id);
              window.location.href = `/manage-teams`;
            }}
            calculateRemainingTime={calculateRemainingTime}
            timeIsOver={timeIsOver}
            remainingTime={remainingTime}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

// Displays a table of all simulations for a given trainer
function TableSimulations({ setLoading }) {
  const { user, setUser } = useContext(UserContext);

  // Add State variables
  const [filteredCollectionRef, loading] = useCollection(
    query(
      collection(db, "simulations"),
      where("trainer", "==", doc(db, "users", user.uid))
    )
  );

  const [simulations, setSimulations] = useState([]);

  useEffect(() => {
    if (filteredCollectionRef) {
      setSimulations(
        filteredCollectionRef.docs.map((doc) => {
          console.log(doc.data());
          return { ...doc.data(), _id: doc.id };
        })
      );
    }
  }, [filteredCollectionRef]);

  const [scenarios, setScenarios] = useState([]);

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [showCreateSimulationModal, setShowCreateSimulationModal] =
    useState(false);
  const [showConfirmStartDialog, setShowConfirmStartDialog] = useState(false);

  const [selectedSimulationId, setSelectedSimulationId] = useState(null);

  const [isEditmode, setIsEditmode] = useState(false);

  useEffect(() => {
    const fetchScenarios = async () => {
      const fetchedScenarios = [];

      for (let scenarioRef of user.scenarios) {
        const doc = await getDoc(scenarioRef);
        if (doc.exists()) {
          fetchedScenarios.push({ ...doc.data(), _id: doc.id });
        }
      }

      setScenarios(fetchedScenarios);
    };

    fetchScenarios();
  }, [user.scenarios]);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Loading />
      </div>
    );

  return (
    <Container style={{ marginBottom: "200px" }}>
      <DialogConfirm
        show={showConfirmStartDialog}
        text={
          "Möchten Sie dieses Training wirklich starten? Gehen Sie sicher, dass alle TeilnehmerInnen bereit sind."
        }
        onConfirm={() => {
          setShowConfirmStartDialog(false);
          startSimulation(
            simulations.find((s) => s._id === selectedSimulationId)
          );
        }}
        onCancel={() => setShowConfirmStartDialog(false)}
      />

      <DialogConfirm
        show={showConfirmDeleteDialog}
        text={
          <p>
            <p>Möchten Sie dieses Training wirklich löschen? </p>
            <p>
              <b>
                Die gespeicherten Notizen der Teams und die Teamaufteilung kann
                zukünftig nicht mehr für Auswertungen verwendet werden.
              </b>
            </p>
            <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
          </p>
        }
        onConfirm={() => {
          setShowConfirmDeleteDialog(false);
          deleteSimulation(selectedSimulationId, user.uid, user, setUser);
        }}
        onCancel={() => setShowConfirmDeleteDialog(false)}
      />

      <CreateSimulationModal
        show={showCreateSimulationModal}
        setShowCreateSimulationModal={setShowCreateSimulationModal}
        selectedSimulation={simulations.find(
          (simulation) => simulation._id === selectedSimulationId
        )}
        isEditmode={isEditmode}
        setIsEditmode={setIsEditmode}
        availableScenarios={scenarios}
      />

      <h1>Meine Trainings</h1>
      <p>
        Hier können Sie Ihre Trainings bearbeiten, löschen und neue erstellen.
      </p>
      <Button
        variant="dark"
        onClick={() => {
          setIsEditmode(false);
          setSelectedSimulationId(null);
          setShowCreateSimulationModal(true);
        }}
        className="my-3"
      >
        Training erstellen
      </Button>
      {simulations.length > 0 ? (
        simulations.map((simulation) => (
          <SimulationBoxWrapper
            key={simulation._id}
            simulation={simulation}
            scenarios={scenarios}
            setShowConfirmDeleteDialog={setShowConfirmDeleteDialog}
            setShowConfirmStartDialog={setShowConfirmStartDialog}
            setSelectedSimulationId={setSelectedSimulationId}
            setShowCreateSimulationModal={setShowCreateSimulationModal}
            setIsEditmode={setIsEditmode}
          />
        ))
      ) : (
        <p className="mb-5 mt-4 text-center">
          Sie haben aktuell keine Trainings. Klicken Sie auf "Training
          erstellen", um ein neues Training zu erstellen.
        </p>
      )}
    </Container>
  );
}

export default TableSimulations;
