import { useContext, useRef } from "react";
import { useSimulation } from "../../hooks/useSimulation";
import { UserContext } from "../../contexts/UserContext";
import {
  updateUser,
  readSimulationsByPlayerRef,
  readTeam,
} from "../../services/firebase/api_old";
import { doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Alert, Container, Row, Col } from "react-bootstrap";
import Loading from "../shared/Loading";
import { db } from "../../services/firebase/init";
import TeamTable from "../shared/TeamTable";
import { WAITING_ROOM_BRIEFING } from "../../constants/Texts";

function WaitingRoom() {
  const simulationId = sessionStorage.getItem("simulationId");
  const simulation = useSimulation(simulationId);
  const { user } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [isAssigned, setIsAssigned] = useState(false);
  // eslint-disable-next-line
  const [registrations, setRegistrations] = useState([]);
  const [assignedTeam, setAssignedTeam] = useState({});

  const playerRef = doc(db, "users", user.uid);

  useEffect(() => {
    updateUser(user.uid, {
      current_simulation: doc(db, "simulations", simulationId),
    });
  }, [user.uid, simulationId]);

  // Store the previous registrations data in a ref
  const prevRegistrations = useRef();

  useEffect(() => {
    const observer = {
      next: async (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => doc.data());
        let assigned = false;
        let assignedTeamData = {};

        if (data && data.length > 0) {
          for (const registration of data) {
            if (registration.teams) {
              for (const team of registration.teams) {
                const teamData = await readTeam(team);
                if (!teamData) {
                  continue;
                }
                for (let i = 0; i < teamData.players.length; i++) {
                  if (teamData.players[i]._id === playerRef.id) {
                    assigned = true;
                    assignedTeamData = {
                      _id: teamData._id,
                      name: teamData.name,
                      players: teamData.players,
                    };
                  }
                }
              }
            }
          }
        }

        // Only set state if registrations has changed
        if (
          JSON.stringify(prevRegistrations.current) !== JSON.stringify(data)
        ) {
          setIsAssigned(assigned);
          setAssignedTeam(assignedTeamData);
          setRegistrations(data);
        }
        // Update prevRegistrations
        prevRegistrations.current = data;
      },
      error: (error) => console.error(error),
    };

    const unsubscribe = readSimulationsByPlayerRef(playerRef, observer);

    // Cleanup function
    return () => unsubscribe();
  }, [playerRef]);

  if (simulation.started) {
    sessionStorage.setItem("simulationId", simulationId);
    sessionStorage.setItem("teamId", assignedTeam._id);

    if (!isLoading) setIsLoading(true);
    setTimeout(() => {
      window.location.href = "/training";
    }, 2000);
  }

  if (isLoading) {
    return (
      <div className="container mt-5">
        <h1>Warteraum</h1>
        <p>Training wird gestartet...</p>
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <Container className="mt-5">
      <Row>
        <Col>
          <h1>Warteraum</h1>
          <p>Warte bis das Training beginnt.</p>
          <p>
            {isAssigned
              ? "Du wurdest folgendem Team zugewiesen: " + assignedTeam.name
              : "Du wurdest noch keinem Team zugewiesen."}
          </p>
          <TeamTable team={assignedTeam} />
        </Col>
        <Col>
          <Alert>
            <Alert.Heading>Wichtige Informationen</Alert.Heading>
            {WAITING_ROOM_BRIEFING(simulation)}
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default WaitingRoom;
