import React, { useState } from "react";
import {
  InputGroup,
  Button,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import {
  IconCheck,
  IconCopy,
  IconEye,
  IconEyeInvisible,
  IconSafe,
} from "../../constants/Icons";

const popover = (
  <Popover id="popover-basic">
    <Popover.Header as="h3">Code</Popover.Header>
    <Popover.Body>
      Mit diesem Code können sich die TeilnehmerInnen später direkt für das Training anmelden.
    </Popover.Body>
  </Popover>
);

const Password = ({
  title,
  placeholder,
  password,
  setPassword,
  name = "",
}) => {
  const [copySuccessful, setCopySuccessful] = useState(false);
  const [generationSuccessful, setGenerationSuccessful] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = React.useState(false);

  const copy = () => {
    navigator.clipboard.writeText(password);
    setCopySuccessful(true);
    setTimeout(() => setCopySuccessful(false), 5000);
  };

  const generatePassword = () => {
    const password = uuidv4().split("-")[0];
    setPassword(password);
    setGenerationSuccessful(true);
    setTimeout(() => setGenerationSuccessful(false), 5000);
    copy();
  };

  return (
    <OverlayTrigger trigger="hover" placement="top" overlay={popover}>
      <InputGroup controlId="formBasicKey" className="mb-3">
        <InputGroup.Text id="basic-addon1" className="w-20">
          {title}
        </InputGroup.Text>

        <Form.Control
          name={name}
          type={passwordVisibility ? "text" : "password"}
          placeholder={placeholder}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <InputGroup.Text variant="dark" onClick={generatePassword} as={Button}>
          {generationSuccessful ? <IconCheck /> : <IconSafe />}
        </InputGroup.Text>
        <Button
          variant="dark"
          onClick={() => setPasswordVisibility(!passwordVisibility)}
        >
          {passwordVisibility ? <IconEyeInvisible /> : <IconEye />}
        </Button>
        <Button variant="dark" onClick={copy}>
          {copySuccessful ? <IconCheck /> : <IconCopy />}
        </Button>
      </InputGroup>
    </OverlayTrigger>
  );
};

export default Password;
